import * as React from "react";
import {BaseComponent, GenericSelect, TS_Checkbox, TS_Input} from "@intuitionrobotics/thunderstorm/frontend";
import {Unit} from "@app/ir-q-app-common/types/units";
import {OnMetadataUpdated, UnitsModule} from "@modules/UnitsModule";
import {
    CONST_CC_OrganizationKey,
    CONST_CC_OrganizationType,
    CONST_ContentVersionMapKey,
    CONST_RegisteredAutomationUnitMapKey,
    CONST_StatusAlertKey,
    DB_UnitMetadata, FirebasePointer,
    RenderMapMetaData
} from "@app/ir-q-app-common/types/unit-config";
import {SimpleLoader} from "@components/SimpleLoader";
import {__stringify, ObjectTS} from "@intuitionrobotics/ts-common";
import {icon__upload} from "./Page_Unit_Monitoring";
import {EnvironmentModule} from "@modules/EnvironmentModule";
import {BaseRuntimeStatus} from "@app/ir-q-app-common/types/runtime-status";

const icon_firebase = require("@res/images/icon__firebase.png");
const icon__dialogFlow = require("@res/images/icon__dialogFlow.png");


export class Page_Unit_Config
    extends React.Component<{
        unit: Unit
        runtimeStatus?: BaseRuntimeStatus
    }> {

    renderInfo = () => {
        const runtimeStatus = this.props.runtimeStatus;
        const unitConfig = runtimeStatus?.som?.unit_config || runtimeStatus?.tablet?.unit_config;
        if (!unitConfig)
            return null;

        const versionDataUrl = this.getVersionDataPath(unitConfig.versionData);
        const versionDataLink = <div className={"ll_h_c"}>
            <img alt={"firebase icon"} src={icon_firebase} width={18}/>
            <a className={`clickable`} href={versionDataUrl} target="_blank">Version data</a>
        </div>;
        const unitDataUrl = `https://${unitConfig.unitData.projectId}.firebaseio.com/${unitConfig.unitData.path}`;
        const unitDataLink = <div className={"ll_h_c"}>
            <img alt={"firebase icon"} src={icon_firebase} width={18}/>
            <a className={`clickable`} href={unitDataUrl} target="_blank">Unit data</a>
        </div>;
        let projectId;
        let runtimeStatusPath;
        if (unitConfig.runtimeStatus) {
            projectId = unitConfig.runtimeStatus.projectId;
            runtimeStatusPath = unitConfig.runtimeStatus.path;
        } else {
            runtimeStatusPath = `elliqs/${this.props.unit.unitId}/som/runtime_status`;
            if (EnvironmentModule.isProd()) {
                projectId = 'elliq-env-prod';
            } else {
                projectId = 'elliq-env-dev';
            }
        }
        const runtimeStatusUrl = `https://${projectId}.firebaseio.com/${runtimeStatusPath}`;
        const runtimeStatusLink = <div className={"ll_h_c"}>
            <img alt={"firebase icon"} src={icon_firebase} width={18}/>
            <a className={`clickable`} href={runtimeStatusUrl} target="_blank">Runtime status</a>
        </div>;
        // const runtimeStatusLink = <div className={`clickable`} onClick={() => window.open(runtimeStatusUrl, "_blank")}>Link to runtime status</div>
        const overrideDataUrl = `https://${unitConfig.overrideData.projectId}.firebaseio.com/${unitConfig.overrideData.path.replace(/\./g, "-")}`;
        const overrideDataLink = <div className={"ll_h_c"}>
            <img alt={"firebase icon"} src={icon_firebase} width={18}/>
            <a className={`clickable`} href={overrideDataUrl} target="_blank">Override data</a>
        </div>;
        const envData: { [key: string]: string } = {};
        const reactiveAgentUrl = `https://dialogflow.cloud.google.com/#/agent/${unitConfig.agents.reactive}/intents`;
        const reactiveAgentLink = <div className={"ll_h_c"}>
            <img alt={"dialogFlow icon"} src={icon__dialogFlow} width={18}/>
            <a className={`clickable`} href={reactiveAgentUrl} target="_blank">Reactive agent</a>
        </div>;
        const nonReactiveAgentUrl = `https://dialogflow.cloud.google.com/#/agent/${unitConfig.agents.nonReactive}/intents`;
        const nonReactiveAgentLink = <div className={"ll_h_c"}>
            <img alt={"dialogFlow icon"} src={icon__dialogFlow} width={18}/>
            <a className={`clickable`} href={nonReactiveAgentUrl} target="_blank">Non-reactive agent</a>
        </div>;
        Object.keys(unitConfig.envData).forEach(key => {
            if (!key.includes("_"))
                envData[key] = (unitConfig.envData[key]);
        });

        return <>
            <div className={"ll_h_c match_width"} style={{justifyContent: "space-between"}}>
                {reactiveAgentLink}
                {nonReactiveAgentLink}
                {overrideDataLink}
                {runtimeStatusLink}
                {unitDataLink}
                {versionDataLink}
            </div>
            <div className={"ll_h_c match_width"}>
                <RenderMetadata unit={{unitId: this.props.unit.unitId, product: this.props.unit.product}}/>
            </div>
            <div>{`Original version: ${unitConfig._originalVersion}`}</div>
            <div>{`Env data name: ${envData.name}`}</div>
            <div>{`Kaspero env: ${unitConfig.kasperoConfiguration?.kasperoEnv}`}</div>
            <pre>{JSON.stringify(envData, null, 2)}</pre>
        </>;

    };

    private getVersionDataPath(versionData: FirebasePointer) {
        const path = versionData.path;
        if (!path.startsWith("gs://"))
            return `https://${versionData.projectId}.firebaseio.com${path}`;

        const paths = path.split(";");
        return `https://console.cloud.google.com/storage/browser/_details/${paths[0].replace("gs://", "")}/${paths[1]}`
    }

    render() {
        return <div className="match_width match_height" style={{overflow: "auto", paddingTop: 10}}>
            {this.renderInfo()}
            {!this.props.runtimeStatus && <SimpleLoader overlay={true}/>}
        </div>;
    }
}

export const optionStyles = {
    container: (provided: any) => ({
        ...provided,
        width: 100,
        fontSize: 13,
        outline: "none",
        padding: 0
    }),
    control: () => ({
        border: "1px solid",
        color: '#000',
        display: "flex",
        height: 20,
        fontSize: 13,
        outline: "none"
    }),
    singleValue: (provided: any) => ({
        ...provided,
        color: "#000",
        fontWeight: 500
    }),
    input: (provided: any) => ({
        ...provided,
        color: "#fff"
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: "unset",
        color: "rgb(0,0,0)",
        ':hover': {
            backgroundColor: "rgb(255,117,0)"
        }
    })
};

type Primitives_Options = {
    String: string
    Number: number
    Boolean: boolean
}
type Primitives_OptionType = keyof Primitives_Options
const options: Primitives_OptionType[] = ['String',
    'Number',
    'Boolean'];
const defaultOptions: { [K in Primitives_OptionType]: Primitives_Options[K] } = {
    String: '',
    Number: 0,
    Boolean: true
}

const lockedMetaKeys: string[] = [
    CONST_CC_OrganizationType,
    CONST_CC_OrganizationKey,
    CONST_StatusAlertKey,
    CONST_RegisteredAutomationUnitMapKey,
    CONST_ContentVersionMapKey
]

type Props = {
    unit: Unit
}

class RenderMetadata
    extends BaseComponent<Props, {
        loading: boolean,
        values: ObjectTS,
        newMetadata?: { type: Primitives_OptionType, key: string }
    }>
    implements OnMetadataUpdated {

    __onMetadataUpdated(): void {
        this.setState({loading: false, values: this.getMeta()});
    }

    private getMeta = (): ObjectTS => {
        const meta = UnitsModule.getUnitMetadata(this.props.unit.unitId);
        return meta?.reduce(<K extends keyof RenderMapMetaData>(carry: ObjectTS, el: DB_UnitMetadata<K>) => {
            carry[el.dataKey] = el.data;
            return carry
        }, {})
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            values: this.getMeta()
        }
    }

    render() {
        const values = this.state.values;
        return <div style={{margin: "18px 0px", flex: 1}}>Meta:
            {values ? Object.keys(values).map(this.renderMeta) : 'No Metadata to render'}
            {this.renderNewMetadata()}
            {this.state.loading && <SimpleLoader overlay={true}/>}
        </div>;
    }

    private renderNewMetadata = () => {
        if (!this.state.newMetadata)
            return <div onClick={() => this.setState({
                newMetadata: {
                    type: 'String',
                    key: ''
                }
            })}>+</div>;

        return <div className={'ll_h_c'} style={{padding: "10px 0"}}>
            <div style={{maxWidth: 200}}>{<GenericSelect
                selectedOption={this.state.newMetadata.type}
                options={options}
                iconClose={''}
                iconOpen={''}
                onChange={<K extends Primitives_OptionType>(selected: K) => {
                    // @ts-ignore
                    this.setState(state => {
                        if (state.newMetadata?.type === selected)
                            return state;

                        const defaultVal: Primitives_Options[K] = defaultOptions[selected]
                        if (defaultVal === undefined) {
                            this.logWarning(`Something very wrong, please check why ${selected} was an option`)
                            return state
                        }

                        return ({
                                ...state,
                                newMetadata: {
                                    ...state.newMetadata,
                                    type: selected,
                                    key: defaultVal
                                }
                            }
                        );
                    })
                }}
                styles={optionStyles}
                presentation={o => o}
            />}</div>
            <div style={{marginLeft: 10, border: '1px solid #000'}}>{<TS_Input
                name={'metaKey'}
                value={this.state.newMetadata.key}
                onChange={(v) => {
                    this.setState(state => ({
                            ...state,
                            newMetadata: {
                                type: 'String',
                                ...state.newMetadata,
                                key: v.replace(/ /, '_')
                            }
                        }
                    ))
                }
                }
                type={"text"}
                id={'metaKey'}
            />}</div>
            <div onClick={() => {
                this.setState(state => {
                    const newMetadata = state.newMetadata;
                    if (!newMetadata?.key || !newMetadata.type)
                        return state;

                    const val = defaultOptions[newMetadata.type];
                    if (val === undefined)
                        return state;

                    return {
                        ...state,
                        values: {
                            ...state.values,
                            [newMetadata.key]: val
                        },
                        newMetadata: undefined
                    };
                });
            }}>+
            </div>
        </div>
    };

    private renderMeta = (metaKey: string, idx: number) => {
        const myMeta = UnitsModule.getUnitMetadata(this.props.unit.unitId)?.find(m => m.dataKey === metaKey);
        const metaValue = this.state.values[metaKey];
        const wasItChanged = metaValue !== myMeta?.data
        const locked = lockedMetaKeys.includes(metaKey);
        return <div className={'ll_h_c'} key={idx}>
            {!locked && <div style={{color: "red", paddingRight: 5}} onClick={() => {
                UnitsModule.deleteUnitMetadata(this.props.unit.unitId, this.props.unit.product, metaKey);
                this.setState({loading: true})
            }} className={`clickable`}>x</div>}
            <div style={wasItChanged ? {color: 'red'} : {}}><code>{metaKey}</code>:</div>
            <div style={{marginLeft: 10}}>{this.getEditor(metaKey, locked, metaValue)}</div>
            {wasItChanged && <img onClick={event => {
                UnitsModule.updateUnitMetadata([this.props.unit.unitId], {[metaKey]: metaValue});
                this.setState({loading: true})
            }} alt="" className={`clickable`} src={icon__upload} width={20} height={20}/>}
        </div>
    };

    private getEditor = (metaKey: string, locked: boolean, data?: any) => {
        if (locked)
            return __stringify(data);

        switch (typeof data) {
            case "number":
                return <TS_Input
                    value={`${data}`}
                    onChange={(v) => {
                        this.setState(state => {
                            state.values[metaKey] = Number(v);
                            return state;
                        })
                    }}
                    type={"text"}
                    id={metaKey}
                />
            case "string":
                return <TS_Input
                    name={metaKey}
                    value={data}
                    onChange={(v) => {
                        this.setState(state => {
                            state.values[metaKey] = v;
                            return state;
                        })
                    }}
                    type={"text"}
                    id={metaKey}
                />
            case "boolean":
                return <TS_Checkbox
                    value={data}
                    checked={data}
                    label={''}
                    onCheck={(v) => {
                        this.setState(state => ({
                            ...state,
                            values: {
                                ...state.values,
                                [metaKey]: !v
                            }
                        }))
                    }}
                />
            case "object":
            default:
                return __stringify(data)
        }
    };
}
