import * as React from "react";
import {useContext, useState} from "react";
import {ToastModule, XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {UnitsModule} from "@modules/UnitsModule";
import {SimpleLoader} from "@components/SimpleLoader";
import {AUTHENTICATION_KEY, AUTHENTICATION_PREFIX} from "@intuitionrobotics/user-account";
import {StorageKey_JWT} from "@intuitionrobotics/user-account/frontend";
import {UnitContext} from "../../App";

export const RenderRSDownloadLink = () => {
    const [loading, setLoading] = useState(false)
    // @ts-ignore
    const origin = XhrHttpModule.config.origin;

    const {activatedUnits, pairedUnits} = useContext(UnitContext);
    const unitList = [...Array.from(pairedUnits), ...Array.from(activatedUnits)];

    return <div className={'ll_h_c'} style={{marginTop: 10}}>
        {loading && <SimpleLoader overlay/>}
        <div className={"btn-nice"} onClick={async () => {
            setLoading(true);
            try {
                await UnitsModule.downloadCsv(unitList);
                ToastModule.toastSuccess("Download Completed")
            } catch (e) {
                console.error(e)
                ToastModule.toastError("Failed to download, check logs")
            } finally {
                setLoading(false);
            }
        }}>
            <span>Download Units csv</span>
        </div>
        <div className={"btn-nice"}
             onClick={async () => {
                 ToastModule.toastInfo("Download Started")
                 setLoading(true);
                 try {
                     const response = await fetch(origin + "/v1/rs/download", {
                         headers: {
                             "Content-Type": "application/json",
                             [AUTHENTICATION_KEY]: `${AUTHENTICATION_PREFIX} ${StorageKey_JWT.get()}`
                         }
                     });
                     await response.text().then((text) => {
                            const a = document.createElement('a');
                            const file = new Blob([text], {type: 'text/csv'});
                            a.href = URL.createObjectURL(file);
                            a.download = 'rs-history.csv';
                            a.click();
                     })
                     ToastModule.toastSuccess("Download Completed")
                 } catch (e) {
                     console.error(e)
                     ToastModule.toastError("Failed to download, check logs")
                 } finally {
                     setLoading(false);
                 }
             }}
        >
            <span>Download rs csv</span>
        </div>
        <div className={"btn-nice"}
             onClick={async () => {
                 ToastModule.toastInfo("Trigger Started")
                 setLoading(true);
                 try {
                     await fetch(origin + "/v1/rs/trigger", {
                         headers: {
                             "Content-Type": "application/json",
                             [AUTHENTICATION_KEY]: `${AUTHENTICATION_PREFIX} ${StorageKey_JWT.get()}`
                         }
                     })
                     ToastModule.toastSuccess("RS snapshot Collected")
                 } catch (e) {
                     console.error(e)
                     ToastModule.toastError("RS snapshot Failed, check logs")
                 } finally {
                     setLoading(false);
                 }
             }}
        >
            <span>Trigger rs snapshot</span>
        </div>
    </div>
}
