import {Module} from "@intuitionrobotics/ts-common";
import {Request_CreateZendeskUser} from "@app/ir-q-app-common/shared/zendesk";
import {ApiCreateZendeskUser} from "@app/ir-q-app-common/shared/api";
import {ThunderDispatcher, ToastModule, XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {HttpMethod, OnRequestListener} from "@intuitionrobotics/thunderstorm";

type Config = {}

export const RequestKey_CreateNewZendeskUser = "createNewZendeskUser";
export interface OnZendeskFinished {
    __onZendeskFinished: (id: string) => void
}

class ZendeskModule_Class
    extends Module<Config> {

    createNewUser = (body: Request_CreateZendeskUser) => {
        const email = body.user.identities.find(item => item.type === "email")?.value;
        const phone = body.user.identities.find(item => item.type === "phone")?.value;

        if (!email || !phone)
            return ToastModule.toastError("email and phone fields are mandatory");

        body.user.email = email;
        body.user.phone = phone;

        XhrHttpModule
            .createRequest<ApiCreateZendeskUser>(HttpMethod.POST, RequestKey_CreateNewZendeskUser)
            .setRelativeUrl('/v1/unit/create-zendesk-user')
            .setJsonBody(body)
            .setOnError((r, e) => {
                //@ts-ignore
                ToastModule.toastError(`Failed to create account in Zendesk: ${e?.debugMessage?.description}`)
                new ThunderDispatcher<OnRequestListener, "__onRequestCompleted">("__onRequestCompleted") .dispatchUI(RequestKey_CreateNewZendeskUser, false)
            })
            .setOnSuccessMessage(`A Zendesk account successfully created for ${body.user.name}`)
            .execute(response => {
                new ThunderDispatcher<OnZendeskFinished, "__onZendeskFinished">("__onZendeskFinished") .dispatchUI( response.user?.id)
            });
    }
}

export const ZendeskModule = new ZendeskModule_Class("ZendeskModule");
