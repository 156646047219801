import * as React from 'react';
import {
    CustomLoader,
    Progress
} from "@components/CustomLoader";

type Node = string | boolean | Progress;


const Renderer_Loader = (node?: Node) => {
	if (node) {
		if (node === true)
			return <CustomLoader/>;
		else if (typeof node === "string")
			return <CustomLoader text={node}/>;
		else
			return <CustomLoader progress={node}/>;
	}
	return;
};

export default Renderer_Loader;