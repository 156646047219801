import {_keys, _setTimeout, currentTimeMillies, filterInstances, Hour, Minute, Module, Second} from "@intuitionrobotics/ts-common";
import {FirebaseModule, FirebaseSession} from "@intuitionrobotics/firebase/frontend";
import {ThunderDispatcher, ToastModule, XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {HttpMethod} from "@intuitionrobotics/thunderstorm/shared/types";
import {collection, DocumentData, getDocs, onSnapshot, Query, query, QuerySnapshot, where} from "firebase/firestore";
import {GetFirebaseToken} from "@app-sp/app-shared/api";
import {UnitsModule, UnitView} from "@modules/UnitsModule";
import {PmStatus} from "@app-sp/app-shared/package-manager";
import {FirebaseConfig} from "@intuitionrobotics/firebase";
import {DB_PairedUnit, DeviceIdentity, FullUnitConfig} from "@app/ir-q-app-common/types/units";
import {BaseRuntimeStatus} from "@app/ir-q-app-common/types/runtime-status";
import {DatabaseWrapper} from "@intuitionrobotics/firebase/app-frontend/database/DatabaseWrapper";

export interface OnVisibilityChange {
    __onVisibilityChange: (visibilityState: DocumentVisibilityState) => void
}

const visibilityDispatcher = new ThunderDispatcher<OnVisibilityChange, "__onVisibilityChange">("__onVisibilityChange");

export interface OnPairsRetrieved {
    __onPairsRetrieved: (unitConfigs: FullUnitConfig[]) => void
}

const onPairsRetrievedDispatcher = new ThunderDispatcher<OnPairsRetrieved, "__onPairsRetrieved">("__onPairsRetrieved");

type Subscriptions = "pm" | "ks" | "ksView";

class FirebaseListenerModule_Class
    extends Module {
    private callbacks: { [projectId: string]: ((app: FirebaseSession) => void)[] | undefined } = {};
    private gettingTokenFor: { [projectId: string]: boolean | undefined } = {};

    // private isRuntimeStatusLoaded: boolean = false;

    constructor() {
        super("FirebaseListenerModule");
        document.onvisibilitychange = () => {
            visibilityDispatcher.dispatchUI(document.visibilityState);
        }
    }


    private subscriptions: { [k in Subscriptions]?: () => void } = {};

    private sessions: { [project: string]: { app: FirebaseSession, exp: number } } = {}
    private sessionExpiration = Hour;
    private grace = 5 * Minute;

    public stopListening() {
        _keys(this.subscriptions).forEach(sub => {
            this.subscriptions[sub]?.()
            delete this.subscriptions[sub];
            this.logInfo(`stop listening for ${sub}`)
        })
        this.callbacks = {};
    }

    public listenImplForPM = (_onCallStatusesUpdated: () => void, unitId?: string) => {
        this.logInfo('starting listening for pm')
        const collectionName = "status";
        // @ts-ignore
        const options = FirebaseModule.config.pm;
        this.onAppRetrieved(options.projectId, async (app) => {
            try {
                const firestore = app.getFirestore();
                const statusCollection = collection(firestore, collectionName);
                let statusQuery: Query;
                if (unitId)
                    statusQuery = query(statusCollection, where("unitId", "==", unitId));
                else
                    statusQuery = query(statusCollection);

                this.subscriptions["pm"]?.();
                const onNext = (snapshot: QuerySnapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        const data = change.doc.data() as PmStatus;
                        switch (change.type) {
                            case "added":
                            case "modified":
                                UnitsModule.upsertPMStatus(data);
                                break;
                            case "removed":
                                UnitsModule.removePMStatus(data);
                                break;
                        }
                    });
                    _onCallStatusesUpdated()
                };
                this.subscriptions["pm"] = onSnapshot(statusQuery, onNext, async (e1) => {
                    this.logError(e1)
                    ToastModule.toastInfo("Something went wrong, real time updates are disabled, Refresh to get them")
                    try {
                        const documentDataQuerySnapshot = await getDocs(statusQuery);
                        onNext(documentDataQuerySnapshot);
                    } catch (e2) {
                        this.logError(e2)
                        ToastModule.toastError("Something went wrong, Please refresh")
                    }
                });

            } catch (e) {
                this.logError("Failed to get token for real time use, refresh to try again", e)
                ToastModule.toastError("Failed to get token for real time use, refresh to try again")
            }
        })

        this.getApp(options);
    };


    public listenImplForRS = (_onCallStatusesUpdated: () => void, unitId: string) => {
        this.logInfo('starting listening for rs ' + unitId)
        // @ts-ignore
        const options = FirebaseModule.config.ks;

        const rsCallback = (val: any) => {
            UnitsModule.setRSForUnit(unitId, val);
            _onCallStatusesUpdated()
        };
        this.onAppRetrieved(options.projectId, async (app) => {
            const db = app.getDatabase();
            this.subscriptions["ks"]?.();
            const handleError = (e1: Error) => {
                this.logError(e1)
                ToastModule.toastInfo("Something went wrong, real time updates are disabled, Refresh to get them")
                db.listenWithError(
                    "__shallow-status" + (unitId ? "/" + unitId : ""),
                    rsCallback,
                    (e2: Error) => {
                        this.logError(e2)
                        ToastModule.toastError("Something went wrong, Please refresh")
                    }, true)
            };
            this.subscriptions["ks"] = db.listenWithError(
                "__shallow-status" + (unitId ? "/" + unitId : ""),
                rsCallback,
                handleError
            );
        })
        this.getApp(options)
    };
    public listenImplForRSView = (_onCallStatusesUpdated: () => void) => {
        this.logInfo('starting listening for ksView')
        // @ts-ignore
        const options = FirebaseModule.config.ks;

        this.onAppRetrieved(options.projectId, async (app) => {
            const firestore = app.getFirestore();
            this.subscriptions["ksView"]?.();

            const collectionName = 'rs-view';
            const col = collection(firestore, collectionName);

            const q: Query<UnitView> = query(col);
            const onNext = (snapshot: QuerySnapshot<UnitView>) => {
                const unitViews = snapshot.docs.reduce((acc: Map<string, UnitView | undefined>, doc) => acc.set(doc.id, doc.data()), new Map());
                UnitsModule.setUnitViews(unitViews);
                _onCallStatusesUpdated()
                // if (!this.isRuntimeStatusLoaded)
                //     this.getRsOnce(app.getDatabase(), _onCallStatusesUpdated);
            };
            this.subscriptions["ksView"] = onSnapshot(q, onNext, async (e1) => {
                this.logError(e1)
                ToastModule.toastInfo("Something went wrong, real time updates are disabled, Refresh to get them")
                try {
                    const documentDataQuerySnapshot = await getDocs(q);
                    onNext(documentDataQuerySnapshot);
                } catch (e2) {
                    this.logError(e2)
                    ToastModule.toastError("Something went wrong, Please refresh")
                }
            });
        })
        this.getApp(options)
    };


    private getRsOnce = (db: DatabaseWrapper, callback: () => void) => {
        // this.isRuntimeStatusLoaded = true;
        db.get<{ [unitId: string]: BaseRuntimeStatus }>("__shallow-status")
            .then((d: { [unitId: string]: BaseRuntimeStatus } | null) => {
                d && UnitsModule.setRS(d);
                callback();
            })
            .catch(e => {
                // this.isRuntimeStatusLoaded = false;
                _setTimeout(() => {
                    this.getRsOnce(db, callback)
                }, 5 * Second)
            });
    };

    public getRsSync = async () => {
        return new Promise<void>((resolve, reject) => {
            // @ts-ignore
            const options = FirebaseModule.config.ks;
            this.onAppRetrieved(options.projectId, async (app) => {
                const db = app.getDatabase();
                this.getRsOnce(db, resolve);
            })
            this.getApp(options)
        })
    }

    public getFromDBPairsAndDevices = (_onCallStatusesUpdated: () => void) => {
        // @ts-ignore
        const options = FirebaseModule.config.ks;
        this.onAppRetrieved(options.projectId, async (app) => {
            const firestore = app.getFirestore();
            const colPairs = collection(firestore, "paired-units");
            const colDevices = collection(firestore, "device-identity");
            // const pair = unitId && product ? UnitsModule.getIdentity(unitId, product) : undefined;
            const qPair: Query<DocumentData> = query(colPairs);
            const qDevices: Query<DocumentData> = query(colDevices);

            let docSnapPair, docSnapDevices;
            try {
                [docSnapPair, docSnapDevices] = await Promise.all([
                    getDocs(qPair),
                    getDocs(qDevices)
                ])
            } catch (e) {
                this.logError("Failed to get pair list, trying again", e)
                ToastModule.toastError("Failed to get pair list, trying again")
                return _setTimeout(() => {
                    this.getFromDBPairsAndDevices(_onCallStatusesUpdated)
                }, 5 * Second)
            }

            const deviceMap: { [sha256: string]: DeviceIdentity } = {}
            docSnapDevices.forEach(device => {
                const documentData = device.data() as DeviceIdentity;
                if (!documentData)
                    return;

                deviceMap[documentData.sha256] = documentData;
            })

            const fullUnitConfigs = docSnapPair.docs.reduce((acc: FullUnitConfig[], pair) => {
                const documentData = pair.data() as DB_PairedUnit;
                if (!documentData)
                    return acc;

                const identities = filterInstances(documentData.sha256.map(s => deviceMap[s]));
                if (identities.length !== documentData.sha256.length) {
                    this.logWarning("Ignoring pair " + documentData.unitId + ' since it\'s missing a device identity')
                    return acc;
                }
                const fullUnitConfig: FullUnitConfig = {
                    ...documentData,
                    identities
                }

                acc.push(fullUnitConfig);
                return acc;
            }, []);
            UnitsModule.clearPairs();
            onPairsRetrievedDispatcher.dispatchModule(fullUnitConfigs);
            onPairsRetrievedDispatcher.dispatchUI(fullUnitConfigs);
            _onCallStatusesUpdated()
        })
        this.getApp(options)
    };

    private onAppRetrieved(projectId: string, callBack: (app: FirebaseSession) => void): void {
        const session = this.sessions[projectId];
        if (session && session.exp > currentTimeMillies() + this.grace)
            return callBack(session.app);

        let callBacks = this.callbacks[projectId];
        if (!callBacks)
            callBacks = this.callbacks[projectId] = [];

        callBacks.push(callBack)
    }

    executeCallbacks(projectId: string, app: FirebaseSession) {
        const callbacks = this.callbacks[projectId];
        if (!callbacks)
            return;

        callbacks.forEach(c => c(app));
        this.callbacks[projectId] = [];
    }

    private getApp(_options: FirebaseConfig): void {
        if (this.gettingTokenFor[_options.projectId])
            return;

        const options = {..._options};
        const session = this.sessions[options.projectId];
        if (session && session.exp > currentTimeMillies() + this.grace)
            return this.executeCallbacks(options.projectId, session.app)

        new Promise<void>(async resolve => {
            this.gettingTokenFor[options.projectId] = true;
            const r = await XhrHttpModule
                .createRequest<GetFirebaseToken>(HttpMethod.GET, "get-firebase-token")
                .setUrlParams({projectId: options.projectId})
                .setRelativeUrl(`/v1/token/get`)
                .setTimeout(60 * Second)
                .setLabel(`Listening to collection`)
                .setOnError(`Failed to listen to collection`)
                .executeSync();

            const app = await FirebaseModule.createSession(options, r.token)
            this.sessions[options.projectId] = {app, exp: currentTimeMillies() + this.sessionExpiration};
            this.gettingTokenFor[options.projectId] = false;
            this.executeCallbacks(options.projectId, app)
            resolve();
        }).catch(e => {
            this.logError(e);
            _setTimeout(() => {
                this.getApp(_options);
            }, 5 * Second)
        })

    }
}

export const FirebaseListenerModule = new FirebaseListenerModule_Class();
