import {BaseComponent, DialogModule, RoutingModule} from "@intuitionrobotics/thunderstorm/frontend";
import * as React from "react";
import {ActivationForm, AuthType, Body_Activation} from "@app/ir-q-app-common/types/activation"
import {ActivationsModule, RequestKey_RegisterActivation} from "@modules/ActivationsModule";
import {OnRequestListener} from "@intuitionrobotics/thunderstorm";
import {Route_ActivationsList} from "../../routes";
import {ActivationFormComponent} from "./ActivationFormComponent";
import {SimpleLoader} from "@components/SimpleLoader";

export type ActivationDefaultParameters = ActivationForm & {
    aliasName?: string,
    pincode?: string;
    activated?: boolean;
    blame?: string;
    id?: number,
    som_pk?: string | null,
    tablet_pk?: string | null
}

export type ActivationDefaultParametersWithAuthType = Omit<ActivationDefaultParameters, "authType"> & {
    authType: AuthType
}

export class Page_Activation
    extends BaseComponent<{}, { loading?: boolean }>
    implements OnRequestListener {

    constructor(props: {}) {
        super(props);

        this.state = {
            loading: false
        }
    }

    __onRequestCompleted = (key: string, success: boolean, requestData?: string | undefined) => {
        switch (key) {
            case RequestKey_RegisterActivation:
                if (success) {
                    DialogModule.close();
                    RoutingModule.goToRoute(Route_ActivationsList);
                }
        }
    };

    render() {
        return <>
            <ActivationFormComponent onSubmit={(activationData: Body_Activation, skipHistoryCheck?: boolean) => {
                return ActivationsModule.activate(activationData, !!skipHistoryCheck);
            }}/>
            {this.renderLoading()}
        </>
    }

    private renderLoading = () => {
        if (!this.state.loading)
            return;
        return <SimpleLoader overlay={true} noText={true}/>;
    };
}
