import * as React from 'react';
import {ChangeEvent} from 'react';
import * as emotion from "emotion";

export const icon_cancel = require('@res/images/cancel.svg')
const icon_plus = require('@res/images/+.svg')
export const icon_check = require('@res/images/v.svg')


export const inputErrorColor = "#941D29";
export const inputBorderColor = "#C0C0C0";
export const labelStyle = emotion.css`
	color: #3B3B3B;
	font-size: 14px;
	font-weight: 400;
  letter-spacing: -0.41px;
  padding-bottom: 4px;
`;

export const errorText = emotion.css`
	font-size: 13px;
	font-weight: 400;
	// line-height: 22px;
	letter-spacing: -0.4099999964237213px;
	text-align: left;
	color: ${inputErrorColor};
`;

const inputStyle = emotion.css`
	&:-internal-autofill-selected {
	    background-color: #2e304f !important;
	}
	background-color: inherit;
  padding-left: 10px;
  width: 87%;
  height: inherit;
  color: #000000e8;
  outline: none;
  ::placeholder {
    color: #979797;
    font-weight: 200;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.41px;
  }
`;

const inputContainerStyle = emotion.css`
	border: 0.9px solid ${inputBorderColor};
	border-radius: 10px;
	height: 40px;
	justify-content: space-evenly;
`;
const heightComponent = 40;

type Props = {
    onChange: (event: ChangeEvent<HTMLSelectElement>) => void
    onAccept?: () => void
    onBlur?: () => void
    disabled: boolean
    chosenOption?: DropdownOption
    error?: string
    label?: string
    placeholder?: string
    id: string,
    options: DropdownOption[]
    showEmptyOption?: boolean
    selectStyle?: React.CSSProperties
    width?: number,
    wrapperStyle?: React.CSSProperties
    addItem?: (item: string) => Promise<void>
    errorMargin?: boolean
}

export type DropdownOption = {
    key: string,
    value: string | number
}

type State = {
    showAdd: boolean
    valueToAdd?: string,
    addDisabled: boolean
}

export class NewStyledDropDown
    extends React.Component<Props, State> {
    static defaultProps = {
        disabled: false,
        showEmptyOption: true
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            showAdd: false,
            addDisabled: false
        };
    }

    render() {
        const {label, id} = this.props;
        const style: React.CSSProperties = {
            height: heightComponent,
            border: this.props.error && `0.9px solid ${inputErrorColor}`,
            width: this.getWidth(),
            color: this.props.disabled ? "grey" : "black",
            ...this.props.wrapperStyle,
            borderColor : this.state.showAdd ? '#1876d2' : ""
        };

        return (
            <div key={id} className="ll_v_l" style={{marginBottom: this.props.errorMargin !== false ? 20 : "unset"}}>
                {label && <div className={labelStyle}>{`${label}`}</div>}
                <div className={"ll_h_c match_width justify-between"}>
                    <div className={`${inputContainerStyle}`} style={style}>
                        {this.renderSelect()}
                    </div>
                    {this.renderButtons()}
                </div>
                {this.props.error && <span className={errorText}>*This is required field</span>}
            </div>
        );
    }

    private getWidth = () => this.props.width ? this.props.width : (!this.props.addItem ? 290 : 250);

    private renderSelect = () => {
        if (this.state.showAdd)
            return <div style={{position: "relative"}}><input
                value={this.state.valueToAdd}
                onChange={(e) => {
                    this.setState({valueToAdd: e.target.value})
                }}
                disabled={this.state.addDisabled}
                className={"input-drop-down-with-add"}
            />
                {this.state.valueToAdd && <img
                    className="removeClick"
                    alt={"cancel"}
                    onClick={() => {
                        this.setState({showAdd: false, valueToAdd: undefined})
                    }}
                    style={{cursor: "pointer"}}
                    width={24}
                    height={24}
                    src={icon_cancel}
                />}
            </div>;

        const width = this.getWidth() - 10;
        const notSelectedVal = this.props.placeholder || "";
        return <select
            style={{
                width,
                height: this.props.wrapperStyle?.height || heightComponent,
                fontSize: 16,
                border: "none",
                ...this.props.selectStyle
            }}
            className={inputStyle}
            key={`key`}
            value={this.props.chosenOption?.key || notSelectedVal}
            name={"select"}
            onChange={this.props.onChange}
            onBlur={this.props.onBlur}
            disabled={this.props.disabled}
        >
            {this.props.showEmptyOption && <option
                key={'not-assigned'}
                value={notSelectedVal}
            >
            </option>}
            {this.props.options.map(option => {
                return <option
                    key={option.key}
                    value={option.key}
                >
                    {option.value}
                </option>
            })}
        </select>;
    };

    private renderButtons = () => {
        if (!this.props.addItem)
            return;

        const size = 24;
        if (this.state.showAdd)
            return <>
                <div className={"btn-activation blue"}><img
                    alt={"check"}
                    onClick={async () => {
                        if (this.state.addDisabled)
                            return;
                        const valueToAdd = this.state.valueToAdd;
                        const addItem = this.props.addItem;
                        if (!valueToAdd || !addItem)
                            return;
                        this.setState({addDisabled: true}, async () => {
                            await addItem(valueToAdd);
                            this.setState({addDisabled: false, showAdd: false, valueToAdd: undefined})
                        });
                    }}
                    style={{cursor: "pointer"}}
                    width={size}
                    height={size}
                    src={icon_check}
                />
                </div>
            </>;

        return <div className={"btn-activation"}><img
            alt={"add"}
            onClick={() => {
                this.setState({showAdd: true, valueToAdd: ""})
            }}
            style={{cursor: "pointer"}}
            width={size} // 20 / sqrt(2)
            height={size}
            src={icon_plus}
        />
        </div>
    }
}
