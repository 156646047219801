import * as React from "react";

export type ModalInfo = {
    title: string,
    body: string,
    onClose: () => void,
    buttons: {
        label: string,
        onClick: () => void
    }[]
};

export function Modal({title, body, buttons, onClose}: ModalInfo) {
    return <div className={'my-modal'}>
        <div className={'content'}>
            <div className={'header'}>
                <h2>{title}</h2>
                <span className={'close'} onClick={onClose}>&times;</span>
            </div>
            <div className={'body'}>
                {body}
            </div>
            <div className={'footer'}>
                {buttons.map((b, idx) => <button key={`button_modal_${idx}_${b.label}`} onClick={b.onClick}>{b.label}</button>)}
            </div>
        </div>
    </div>
}
