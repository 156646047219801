import {addItemToArray, Auditable, Module} from "@intuitionrobotics/ts-common";
import {HttpMethod, OnRequestListener} from "@intuitionrobotics/thunderstorm";

import {ThunderDispatcher, ToastModule, XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {ConfigId, updateProperty} from "@consts/common";
import {
    Alias,
    AppInfo,
    BasePackageConfig,
    DeviceConfig,
    PackageConfigFe,
    ProductAndDevices,
    Request_AssignTo,
    Response_AppInfo,
    Response_UnitInfo,
    UnitInfo
} from "@app-sp/app-shared/package-manager";
import {
    ApiAssignTo,
    ApiGetDeviceConfigFe,
    ApiListAliases,
    ApiListApps,
    ApiListConfigs,
    ApiListProducts,
    ApiPmListUnits
} from "@app-sp/app-shared/api";
import {Unit} from "@app/ir-q-app-common/types/units";
import {Products_Api} from "@app/ir-q-app-common/types/api";
import {Product} from "@app/ir-q-app-common/types/products";
import {AccountModule, LoggedStatus, OnLoginStatusUpdated} from "@intuitionrobotics/user-account/frontend";
import {UnitsManagerModule} from "@modules/UnitsManagerModule";

type Config = {
    websiteUrl: string,
    functionUrl: string,
}

export interface OnUnload {
    __onUnload(load: boolean): void
}
export interface OnConfigsListFailed {
    __onConfigsListError(message: string): void;

    __onConfigsListPermissionsRequired(message: string): void;
}

const dispatcher_onConfigsListError = new ThunderDispatcher<OnConfigsListFailed, "__onConfigsListError">("__onConfigsListError");
const dispatcher_onConfigsListPermissionsRequired = new ThunderDispatcher<OnConfigsListFailed, "__onConfigsListPermissionsRequired">(
    "__onConfigsListPermissionsRequired");

export type UiPackageConfig = BasePackageConfig & Auditable & {
    deviceConfig?: DeviceConfig[];
}
export const RequestKey_FetchProductsCollection = 'FetchProductsCollection';
export const RequestKey_FetchProducts = "FetchProducts";
export const RequestKey_FetchUnits = "FetchUnits";
export const RequestKey_FetchConfigs = "FetchConfigs";
export const RequestKey_FetchDeviceConfigFe = "FetchDeviceConfigFe";
export const RequestKey_FetchApps = "FetchApps";
export const RequestKey_AssignTo = "AssignTo";
export const RequestKey_FetchAliases = "FetchAliases";
export const RequestKey_PM_InstallNow = "InstallNow";

export class PackageManagerModule_Class
    extends Module<Config> implements OnLoginStatusUpdated {
    private products: ProductAndDevices = {};
    private readonly apps: Response_AppInfo[] = [];
    private readonly configs: { [k: string]: UiPackageConfig } = {};
    private configFe: { [product: string]: { [unitId: string]: PackageConfigFe } } = {};
    private readonly aliases: { [k: string]: Alias } = {};
    private readonly units: { [k: string]: UnitInfo } = {};
    private productsCollection?: Product[];
    getProducts = () => this.products;
    getUnits = () => this.units;
    getUnit = (unit: Unit) => this.units[this.getUnitKey(unit)];
    private getUnitKey = ({unitId, product}: Unit) => `${unitId}-${product}`;
    getApps = () => this.apps;
    getApp = (packageName?: string) => this.apps.find(app => app.packageName === packageName);
    getAliases = () => this.aliases;
    getAlias = (id: string): Alias => this.aliases[id];
    getConfigs = () => this.configs;
    getConfig = (id: ConfigId) => this.configs[id];
    getPMWebsite = () => this.config.websiteUrl;
    getPMFunction = () => this.config.functionUrl;
    getUnitToAliasUrl = () => "set-unit-alias"
    getConfigFe = (product: string, unitId: string) => this.configFe[product]?.[unitId];
    getProductsCollection = () => this.productsCollection;
    fetchProducts = () => {
        XhrHttpModule
            .createRequest<ApiListProducts>(HttpMethod.GET, RequestKey_FetchProducts)
            .setRelativeUrl(`/v1/package-manager/list-products`)
            .setLabel(`Fetching products... `)
            .setOnError(`Error fetching products`)
            .execute(async (products: ProductAndDevices) => {
                this.products = products;
            });
    };

    fetchProductsCollection = () => {
        XhrHttpModule
            .createRequest<Products_Api>(HttpMethod.GET, RequestKey_FetchProductsCollection)
            .setRelativeUrl(`/v1/product/list`)
            .setLabel(`Fetching products... `)
            .setOnError(`Error fetching products`)
            .execute(async (products: Product[]) => {
                this.productsCollection = products;
            });
    };

    fetchAppsList = () => {
        XhrHttpModule
            .createRequest<ApiListApps>(HttpMethod.GET, RequestKey_FetchApps)
            .setRelativeUrl(`/v1/package-manager/list-apps`)
            .setLabel(`Fetching apps list... `)
            .setOnError(`Error fetching apps list`)
            .execute(async (appsList: AppInfo[]) => {
                appsList.reduce((apps, app) => {
                    if (!apps.find(_app => _app.packageName === app.packageName))
                        addItemToArray(apps, app);

                    return apps;
                }, this.apps);
            });
    };

    /*
    * Units methods
    * */

    fetchUnits = () => {
        XhrHttpModule
            .createRequest<ApiPmListUnits>(HttpMethod.GET, RequestKey_FetchUnits)
            .setRelativeUrl(`/v1/package-manager/list-units`)
            .setLabel(`Fetching units... `)
            .setOnError(`Error retrieving units`)
            .execute(async (units: Response_UnitInfo) => updateProperty<UnitInfo>(this.units, this.getUnitKey, units));
    };

    /*
    * Aliases methods
    * */

    fetchAliases = () => {
        XhrHttpModule
            .createRequest<ApiListAliases>(HttpMethod.GET, RequestKey_FetchAliases)
            .setRelativeUrl(`/v1/package-manager/list-aliases`)
            .setLabel(`Fetching aliases... `)
            .setOnError(`Error retrieving aliases`)
            .execute(async (aliases: Alias[]) => updateProperty<Alias>(this.aliases, alias => alias.id, aliases));
    };

    fetchConfigs = (product: string) => {
        XhrHttpModule
            .createRequest<ApiListConfigs>(HttpMethod.GET, RequestKey_FetchConfigs)
            .setUrlParams({product})
            .setRelativeUrl(`/v1/package-manager/list-configs`)
            .setLabel(`registering configs... `)
            .setOnError((request): any => {
                if (request.getStatus() === 403)
                    return dispatcher_onConfigsListPermissionsRequired .dispatchUI(`You don't have permissions to get configs for ${product}`);

                dispatcher_onConfigsListError .dispatchUI(`Error retrieving configs for ${product}`);
            })
            .execute(async (configs: UiPackageConfig[]) => updateProperty<BasePackageConfig>(this.configs, config => config.configId, configs));
    };

    fetchDeviceConfigFe = (unitsParam: Unit[]) => {
        const units = unitsParam.map(unit => {
            if (UnitsManagerModule.is3Point0Unit(unit.unitId))
                return {...unit, is3point0Unit: true};

            return unit;
        });
        XhrHttpModule
            .createRequest<ApiGetDeviceConfigFe>(HttpMethod.POST, RequestKey_FetchDeviceConfigFe)
            .setJsonBody({units})
            .setRelativeUrl(`/v1/package-manager/get-device-config-fe`)
            .setLabel(`Fetching device config (fe) for units: ${units.map(u => u.unitId).join(', ')}`)
            .setOnError((_request => {
                this.logError(`Error retrieving device config (fe) for units: ${units.map(u => u.unitId).join(', ')}. Error Message: ` + _request.errorMessage);
                new ThunderDispatcher<OnRequestListener, '__onRequestCompleted'>('__onRequestCompleted')
                    .dispatchUI(RequestKey_FetchDeviceConfigFe, false);
            }))
            .execute(async (configs: PackageConfigFe[]) => {
                configs.forEach(config => {
                    this.configFe[config.product] = this.configFe[config.product] || {};
                    this.configFe[config.product][config.unitId] = config;
                });

            });
    };

    assignTo(body: Request_AssignTo) {
        const unit = body.unit;
        const unitId = unit.unitId;
        XhrHttpModule
            .createRequest<ApiAssignTo>(HttpMethod.POST, RequestKey_AssignTo)
            .setJsonBody(body)
            .setRelativeUrl(`/v1/package-manager/assign-to`)
            .setLabel(`Assigning unit ${unitId} to ${body.aliasId ? 'alias' : "config"} ${body.aliasId || body.configId}`)
            .setOnSuccessMessage(`Assigned unit ${unitId} to ${body.aliasId ? 'alias' : "config"} ${body.aliasId || body.configId} successfully`)
            .setOnError(`Could not assign unit ${unitId} to ${body.aliasId ? 'alias' : "config"} ${body.aliasId || body.configId}`)
            .execute(() => {
                const unitKey = this.getUnitKey(unit);
                const a = this.units[unitKey];
                if (!body.aliasId && !body.configId) {
                    if (!a)
                        return;

                    delete a.configId
                    delete a.aliasId
                    return;
                }

                if (body.aliasId) {
                    const alias = this.getAlias(body.aliasId);
                    if (!alias) {
                        ToastModule.toastError("Please refresh, something might not be up to date");
                        return this.logError("Please refresh, something went wrong");
                    }

                    this.units[unitKey] = {
                        ...unit,
                        aliasId: body.aliasId,
                        configId: alias.configId
                    }
                }

                if (body.configId) {
                    this.units[unitKey] = {
                        ...unit,
                        configId: body.configId
                    }
                }
            });
    }

    onLoginStatusUpdated(): void {
        if (AccountModule.getLoggedStatus() !== LoggedStatus.LOGGED_IN)
            return;

        this.fetchProductsCollection();
    }
}

export const PackageManagerModule = new PackageManagerModule_Class("PackageManagerModule");
