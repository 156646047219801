import * as React from "react";
import {
	BaseComponent,
	Dialog_Builder,
	DialogModule,
	TS_Input
} from "@intuitionrobotics/thunderstorm/frontend";
import {css} from "emotion";
import {
	BarcodeV1,
	DeviceIdentity
} from "@app/ir-q-app-common/types/units";
import {
	KasperModule,
	OnPairUnitFailed,
	OnPairUnitSuccess
} from "@modules/KasperModule";
import {
	PairedDevice,
	Request_Pair
} from "@app-sp/app-shared/units";
 import {Elliq_ProductKey} from "@app/ir-q-app-common/shared/consts";
import {SimpleLoader} from "@components/SimpleLoader";
import {UnitsModule} from "@modules/UnitsModule";
import {SetUserGroup} from "./SetUserGroup";

const contentStyle = css(
	{
		position: "relative",
		padding: "0 40px"
	});

const inputStyle = css(
	{
		background: "white",
		border: "1px solid",
		borderRadius: 10,
		minWidth: 200,
		height: 20
	})

const buttonsStyle = css(
	{
		background: "white",
		border: "1px solid",
		marginLeft: 20,
		borderRadius: 5,
		height: 32,
		width: 38
	})
export type DialogPairProps = {
	somBarcode: DeviceIdentity,
	tabletBarcode: DeviceIdentity
	repair: boolean
	softwareOnly: boolean
	suggested?: {
		unitId: string
		env?: string
	}

}

type State = {
	unitId: string
	devUnit: boolean
	comment?: string
	loading: boolean
	error?: string
}

export class Component_Pair
	extends BaseComponent<DialogPairProps, State>
	implements OnPairUnitSuccess, OnPairUnitFailed {

	private initialState: State = {
		unitId: "",
		devUnit: false,
		loading: false,
		error: undefined
	};

	constructor(props: DialogPairProps) {
		super(props);
		this.state = {...this.initialState, ...this.props.suggested}
	}

	__onPairingSuccess() {
		UnitsModule.fetchDevices();
		DialogModule.close();
	}

	__onDeviceAlreadyPaired(pairedDevice: PairedDevice): void {
		this.setState({loading: false, error: `device already paired, unpair first, ${pairedDevice.deviceType} of ${pairedDevice.unitId}`})
	}
	__onInvalidSerial(message: string): void {
		this.setState({loading: false, error: message})
	}

	__onPairingError(message: string): void {
		this.setState({loading: false, error: message})
	}

	public static show = (props: DialogPairProps) => new Dialog_Builder(<Component_Pair {...props}/>)
		.setTitle(<div style={{background: "rgb(236,252,255)", fontWeight: 600, padding: 15}}>{"Pair Devices"}</div>)
		.setStyle({minWidth: 600})
		.setAllowIndirectClosing(true)
		.show();

	render() {
		return <div className={`${contentStyle} ll_v_c`}>
			{this.renderErrorMessage()}
			<div className={"ll_h_c"} style={{padding: "20px 0"}}>
				{this.renderInput()}
				{this.renderEnv()}
				{this.renderPairButton()}
			</div>
			{this.state.loading && <SimpleLoader absolute={true} noText={true}/>}
		</div>
	};

	private renderPairButton() {
		return <button
			onClick={this.pairDevices}
			className={buttonsStyle}>
			Pair {this.props.softwareOnly ? "SO" : ''}
		</button>;
	}

	private pairDevices = () => {
		if (!this.state.comment)
			return this.setState({error: "Please select an env from the dropdown"});

		const requestObject: Request_Pair = {
			product: Elliq_ProductKey,
			unitId: this.state.unitId,
			somBarcode: this.cleanUpDeviceIdentity(this.props.somBarcode),
			tabletBarcode: this.cleanUpDeviceIdentity(this.props.tabletBarcode),
			devUnit: this.state.devUnit,
			softwareOnly: this.props.softwareOnly,
			comment: this.state.comment
		};

		this.setState({loading: true})
		KasperModule.pairUnit(requestObject, this.props.repair);
	};

	private renderInput() {
		return <TS_Input
			style={{width: '80%', height: 28}}
			value={this.state.unitId}
			onChange={value => this.setState({unitId: value})}
			type={"text"}
			id={"unitId"}
			placeholder={"Type the unitId"}
			className={inputStyle}
		/>;
	}

	private cleanUpDeviceIdentity(
		{
			type,
			serial,
			btMac,
			publicKey,
			sha256,
			wifiMac,
			version
		}: BarcodeV1): BarcodeV1 {
		return {
			type,
			serial,
			btMac,
			publicKey,
			sha256,
			wifiMac,
			version
		}
	}

	private renderErrorMessage() {
		if (!this.state.error)
			return;

		return <div className={"ll_h_c match_width"} style={{justifyContent: "space-between", marginBottom: 10}}>
			<div onClick={() => this.setState(this.initialState)}>Retry</div>
			<div style={{color: "red", fontSize: "120%"}}>{this.state.error}</div>
		</div>
	}

	private renderEnv() {
		return <SetUserGroup
			userGroup={this.state.comment}
			onUserGroupChange={comment => this.setState({comment, error: undefined})}
		/>
	}
}


