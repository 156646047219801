import {Color} from "@intuitionrobotics/thunderstorm/app-frontend/components/types";
import {ToastProps} from "@intuitionrobotics/thunderstorm/app-frontend/modules/toaster/BaseToaster";
import {Toast_Model, ToastType} from "@intuitionrobotics/thunderstorm/app-frontend/modules/toaster/ToasterModule";
import * as React from "react";
import {CSSProperties} from "react";
import * as emotion from "emotion";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {_clearTimeout, _setTimeout, generateHex, TimerHandler} from "@intuitionrobotics/ts-common";

const closeClass = emotion.css(
    {
        position: "absolute",
        top: 5,
        right: 5,
        fontSize: '110%',
        fontWeight: 900,
        transition: "top 3s linear"
    })

type ToastWithId = Toast_Model & {
    _id: string
};

export class MyToaster
    extends BaseComponent<ToastProps, { models: ToastWithId[] }> {

    constructor(props: ToastProps) {
        super(props);
        this.state = {models: []}
    }

    __showToast = (model?: Toast_Model): void => {
        if (!model)
            return this.setState({models: []});

        const realModel = {...model, _id: generateHex(8)};
        this.setState(state => {
            const models = state.models;
            models.push(realModel)
            return {models};
        });

        const duration = model.duration;
        if (duration <= 0)
            return;

        this.debounce(this.hideToast, 'close_' + realModel._id, duration, realModel);
    };

    debounce(handler: TimerHandler, key: string, ms = 0, ...args: any[]) {
        // @ts-ignore
        _clearTimeout(this.timeoutMap[key]);
        // @ts-ignore
        this.timeoutMap[key] = _setTimeout(handler, ms, args);
    }


    private hideToast = (_models: ToastWithId[]) => {
        this.setState(state => {
            const models: ToastWithId[] = state.models;
            const idx = models.findIndex(m => _models[0]?._id === m._id);
            if (idx !== -1)
                models.splice(idx, 1);
            return {models};
        })
    };

    renderToasters(toast: ToastWithId, i: number) {
        const horizontal = toast.positionHorizontal;
        const vertical = toast.positionVertical;

        let color: Color;
        switch (toast.type) {
            case ToastType.success:
                color = "rgb(216,255,175)";
                break;

            case ToastType.error:
                color = "rgb(255,213,210)";
                break;

            case ToastType.info:
                color = "#c8ebff";
                break;

            default:
                color = "#e8e8e8";
                break;
        }

        const style: CSSProperties = {
            justifyContent: "space-between",
            borderRadius: "4px",
            letterSpacing: "4px",
            boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.28), 1px 2px 4px 0 rgba(0, 0, 0, 0.5)",
            position: "fixed",
            margin: "16px",
            background: color,
            bottom: vertical === "top" ? "unset" : 2 + i * 70,
            top: vertical === "top" ? i * 70 : "unset",
            left: horizontal === "left" ? 0 : horizontal === "center" ? "50%" : "unset",
            right: horizontal === "right" ? 0 : horizontal === "center" ? "auto" : "unset",
            transform: horizontal === "center" ? "translateX(-50%)" : "unset",
            zIndex: 9999,
            minWidth: 300,
            maxWidth: "50%",
            minHeight: 50,
            maxHeight: 50,
            display: 'flex',
            alignItems: 'center',
            padding: 5,
            overflow: 'hidden'
        };

        return (
            <div key={i} className={`ll_h_t ${toast.className}`} style={{...style, ...toast.style}}>
                {typeof toast.content === "string" ?
                    <div dangerouslySetInnerHTML={{__html: toast.content}}/> : toast.content}
                {this.renderClose(toast)}
            </div>
        )
    }

    render() {
        const toasts: ToastWithId[] = this.state.models;
        if (!toasts.length)
            return null;

        return <div className={'ll_v_c'}>{toasts.map((toast, i) => this.renderToasters(toast, i))}</div>;
    }

    private renderClose(toast: ToastWithId) {
        return <div onClick={() => this.hideToast([toast])} className={closeClass}>X</div>
    }
}
