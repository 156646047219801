// eslint-disable-next-line import/no-unassigned-import
import './res/styles/styles.scss';
import {BeLogged, LogClient_Browser, Module} from "@intuitionrobotics/ts-common";

import {App} from "./app/App";
import {RoutingModule, StorageModule, Thunder, ToastBuilder, ToastModule, XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {ContactsModule} from "@modules/ContactsModule";
import {PermissionsModule} from '@modules/PermissionsModule';
import {PackageManagerModule} from "@modules/package-manager/PackageManagerModule";
import {LoginModule} from "@modules/LoginModule";
import {AppModule} from '@modules/AppModule';
// eslint-disable-next-line import/no-unassigned-import
import './manifest';
import {EnvironmentModule} from "@modules/EnvironmentModule";
import {HeaderKey_CurrentPage} from "@app/ir-q-app-common/types/auth";
import {ConfirmationModule} from "@modules/confirmation-modal-module";
import {OverridesModule} from "@modules/OverridesModule";
import {AccountModule} from "@intuitionrobotics/user-account/frontend";
import {PermissionsFE} from "@intuitionrobotics/permissions/frontend";
import {PROJECT_IDENTITY} from "@app-sp/app-shared/shared";
import {BugReportModule} from "@intuitionrobotics/bug-report/app-frontend/modules/BugReportModule";
import {BaseHttpRequest} from '@intuitionrobotics/thunderstorm';
import {CareCenterModule} from "@modules/CareCenterModule";
import {ZendeskModule} from '@modules/ZendeskModule';
import {DataEnvsModule} from "@modules/DataEnvsModule";
import {FirebaseModule} from "@intuitionrobotics/firebase/frontend";
import {ActivationsModule} from "@modules/ActivationsModule";
import {UnitsManagerModule} from "@modules/UnitsManagerModule";
import {ContactListModule} from "@modules/ContactListModule";
import {UnitsModule} from "@modules/UnitsModule";

BeLogged.addClient(LogClient_Browser);

XhrHttpModule.addDefaultHeader(HeaderKey_CurrentPage, () => window.location.href);
XhrHttpModule.addDefaultResponseHandler((request: BaseHttpRequest<any>) => {
    if (request.getStatus() >= 400)
        XhrHttpModule.logError(`Request: ${request.key} failed with status ${request.getStatus()} and error: ${request.getErrorMessage() || request.getErrorResponse()}`)

    return false;
});

const modules: Module[] = [
    UnitsModule,
    FirebaseModule,
    EnvironmentModule,
    ConfirmationModule, // TODO: replace with latest Dialog Module
    LoginModule,
    StorageModule,
    ContactsModule,
    PermissionsModule,
    RoutingModule,
    AccountModule,
    AppModule,
    PackageManagerModule,
    OverridesModule,
    BugReportModule,
    CareCenterModule,
    ZendeskModule,
    DataEnvsModule,
    ActivationsModule,
    UnitsManagerModule,
    ContactListModule
];

PermissionsFE.setDefaultConfig({projectId: PROJECT_IDENTITY._id});

ToastModule.setDefaultBuilder(new ToastBuilder().setDuration(10000).setHorizontalPosition("right").setVerticalPosition("bottom"));

new Thunder()
    .setConfig(require("./config").config)
    .addModules(...modules)
    .setMainApp(App)
    .build();
