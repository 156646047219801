import * as React from "react";
import {BaseComponent, DialogModule, TS_TextArea} from "@intuitionrobotics/thunderstorm/frontend";
import {OnRequestListener} from "@intuitionrobotics/thunderstorm/shared/request-types";
import {RequestKey_UpdateUnitMetadata, UnitsModule} from "@modules/UnitsModule";
import Loader from "@components/Loader";

type Props = {
    unitId: string
    comment?: string
}

type State = {
    loading: boolean,
    comment?: string
}


export default class Component_GeneralComment
    extends BaseComponent<Props, State>
    implements OnRequestListener {

    __onRequestCompleted = (key: string, success: boolean) => {
        switch (key) {
            case RequestKey_UpdateUnitMetadata:
                this.setState({loading: false});
                if (success) {
                    this.setState({comment: UnitsModule.getGeneralComment(this.props.unitId)})
                    DialogModule.close();
                }
                return;
        }

    }

    state = {
        loading: false,
        comment: this.props.comment
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (this.props.comment !== prevProps.comment)
            this.setState({comment: this.props.comment})
    }

    render() {
        if (this.state.loading)
            return <Loader/>

        const boxShadow = "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)";
        return <div style={{border: "solid 1px #cddae4", marginTop: "20px", position: "relative", width: "92%"}}>
            <div style={{height: "180px"}}>
                <TS_TextArea
                    type={"text"}
                    style={{
                        padding: 7,
                        boxSizing: "border-box",
                        width: "100%",
                        height: '180px',
                        resize: "none",
                        border: "none"
                    }}
                    placeholder={"General comment"}
                    value={this.state.comment}
                    onChange={(value) => this.setState({comment: value})}
                />
            </div>
            <button
                style={{position: "absolute", bottom: 3, right: 2, boxShadow, border: "none", borderRadius: "2px"}}
                disabled={!this.state.comment || this.state.comment === this.props.comment}
                onClick={() => {
                    UnitsModule.uploadGeneralComment(this.props.unitId, this.state.comment || "");
                    this.setState({loading: true});
                }}
            >Save
            </button>
            <button
                style={{position: "absolute", bottom: 3, right: 50, boxShadow, border: "none", borderRadius: "2px"}}
                disabled={!this.props.comment}
                onClick={() => {
                    UnitsModule.uploadGeneralComment(this.props.unitId, "");
                    this.setState({loading: true});
                }}
            >Delete
            </button>
        </div>
    }
}
