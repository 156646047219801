import * as React from "react";
import {
	BaseComponent,
	Dialog_Builder,
	DialogModule
} from "@intuitionrobotics/thunderstorm/frontend";
import {css} from "emotion";
 import {Elliq_ProductKey} from "@app/ir-q-app-common/shared/consts";
import {SimpleLoader} from "@components/SimpleLoader";
import {
	PairRequestsModule,
	RequestKey_AddPairRequest
} from "@modules/PairRequestsModule";
import {PairRequest} from "@app/ir-q-app-common/types/pair-requests";
import {OnRequestListener} from "@intuitionrobotics/thunderstorm/shared/request-types";

const contentStyle = css(
	{
		position: "relative",
		padding: "0 40px"
	});

export type DialogAddPairRequestProps = {}

type State = {
	unitId: string
	tabletSerial: string
	somSerial: string
	env?: string

	product: string

	error?: string
	loading: boolean
}

export class Dialog_ListPairRequest
	extends BaseComponent<DialogAddPairRequestProps, State>
	implements OnRequestListener {

	private initialState: State = {
		unitId: "",
		tabletSerial: "",
		somSerial: "",
		env: undefined,
		product: Elliq_ProductKey,
		error: undefined,
		loading: false
	};

	constructor(props: DialogAddPairRequestProps) {
		super(props);
		this.state = {...this.initialState}
	}

	__onRequestCompleted(key: string, success: boolean) {
		switch (key) {
			case RequestKey_AddPairRequest: {
				if (success)
					DialogModule.close()
				else
					this.setState({loading: false, error: "Error adding pair requests, check logs"})
			}

				break;
		}
	}


	public static show = (props: DialogAddPairRequestProps) => new Dialog_Builder(<Dialog_ListPairRequest {...props}/>)
		.setTitle(<div style={{background: "rgb(236,252,255)", fontWeight: 600, padding: 15}}>{"Pair Devices"}</div>)
		.setStyle({minWidth: 600})
		.setAllowIndirectClosing(true)
		.show();

	render() {
		const pairRequests = PairRequestsModule.getPairRequests();
		if (!pairRequests)
			return null;

		return <div className={`${contentStyle} ll_v_c`}>
			<div className={"ll_h_c"} style={{padding: "20px 0"}}>
				<table>
					<thead>
					<tr>
						<td>Som</td>
						<td>Tablet</td>
						<td>UnitId</td>
						<td>Fullfilled</td>
						<td>Created</td>
					</tr>
					</thead>
					<tbody>
					{pairRequests.map(this.renderRow)}
					</tbody>
				</table>
			</div>
			{this.state.loading && <SimpleLoader absolute={true} noText={true}/>}
		</div>
	};

	private renderRow(pairRequest: PairRequest, idx: number) {
		return <tr>
			<td>{pairRequest.somSerial}</td>
			<td>{pairRequest.tabletSerial}</td>
			<td>{pairRequest.unitId}</td>
			<td>{pairRequest.fulfilled === 0 ? "N/A" : new Date(pairRequest.fulfilled).toLocaleString()}</td>
			<td>{`by ${pairRequest._created.auditBy} at ${pairRequest._created.auditAt.pretty}`}</td>
		</tr>
	}
}


