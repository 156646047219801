import {
	BaseComponent,
	TooltipBuilder,
	TooltipDefaultStyle
} from "@intuitionrobotics/thunderstorm/frontend";
import * as React from "react";

const icon_copy = require('@res/images/copy.svg');

export class CopyButton extends BaseComponent<{obj: any, size?: number, tooltipContent?: string}> {

	render() {
		const {size,obj, tooltipContent} = this.props;
		return <img
			key="redo"
			style={{width: size || 20, height: size || 20, paddingLeft: 5, cursor: 'pointer'}}
			src={icon_copy}
			onClick={e => {
				e.stopPropagation();
				navigator.clipboard.writeText(`${obj}`).catch(console.error);
				const content = tooltipContent || `Copied ${obj}`;
				new TooltipBuilder(content, e)
					.setLocation(e.clientX + 15, e.clientY - 15)
					.setStyle({...TooltipDefaultStyle, zIndex: 100})
					.setDuration(1000)
					.show();
			}}
		/>;
	}
}
