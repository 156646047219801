import * as React from "react";
import * as emotion from "emotion";

import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {createTheme, Dialog, DialogActions, DialogContent, DialogTitle, ThemeProvider} from "@mui/material";
import {PaperProps} from "@mui/material/Paper";
import {COLOR_darkGray, COLOR_darkSkyBlue, COLOR_disabledGray, COLOR_greenyBlue} from "@styles/colors";
import {Confirmation_Dialog, ConfirmationDialogListener} from "@modules/confirmation-modal-module";
import {InputTextAreaField, simpleLabel} from "@components/InputTextAreaField";
import {DropdownOption, errorText, NewStyledDropDown} from "@components/NewStyledDropDown";
import {cancelledOption, disenrolledOption} from "../../app/pages/pairing/ActivationTable";
import {ReasonsToCancelUnit, ReasonsToDisenrollUnit} from "@consts/common";

export const ConfirmationText_deleteText = (item: string, context?: string) => (
    `You are about to delete<br/>
	 ${context ? `${context} ` : ''}<b>${item}</b>.<br/>
	This process cannot be undone!`
);

export const dialogThemeConfirmation = createTheme({
    zIndex: {
        modal: 99999
    },
    // typography: {
    //     useNextVariants: true,
    // },
    components: {
        MuiDialogTitle: {
            styleOverrides: {

                root: {
                    marginBottom: "1rem",
                    padding: '0px',
                    boxSizing: "border-box",
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    color: COLOR_darkGray,
                    fontSize: 14,
                    lineHeight: 1.3,
                    marginTop: '1rem',
                    overflow: "hidden",
                    textAlign: "center"
                }
            }
        }
    }
});

export const textAreaForDialog = emotion.css`
  width: 90%;
  height: 50px;
  padding: 15px;
  margin: 10px 10px;
  border-radius: 5px !important;
  box-shadow: 0 0 0 1px rgba(41, 41, 41, 0.2) inset;
  font-size: 14px;
  font-family: inherit !important;
  color: #242424;
  border: none;
  resize: none;
`;

const submitStyle = emotion.css`{
    background-color: ${COLOR_darkSkyBlue};
    border: none;
    border-radius: 24.5px;
    color: white;
    cursor: pointer;
    font-size: 14px;
    height: 35px;
    outline: none;
    width: 99px;
    :active {
        background-color: ${COLOR_greenyBlue};
    }
    :disabled {
        background-color: ${COLOR_disabledGray};
        cursor: default
    }
}`;

const cancelStyle = emotion.css`{
    background-color: white;
    border: none;
    color: ${COLOR_darkGray};
    cursor: pointer;
    font-size: 14px;
    height: 35px;
    text-decoration: underline;
    outline: none;
    width: 99px;
}`;

const notSelectedVal = {key: "", value: ""};

export default class Dialog_Confirmation
    extends BaseComponent<{}, { content?: Confirmation_Dialog, showDdlRequireFieldAlert?: boolean, showDetailedDdlRequireFieldAlert?: boolean, inputText: string, focusLost: boolean, cancelHover: boolean, detailedChosenOption?: DropdownOption }>
    implements ConfirmationDialogListener {

    constructor(props: any) {
        super(props);
        this.state = {
            content: undefined,
            inputText: "",
            focusLost: false,
            cancelHover: false,
            detailedChosenOption: notSelectedVal
        };
    }

    showConfirmation = (content: Confirmation_Dialog): void => {
        if (content)
            this.setState(() => ({content}));
    };

    onClose = () => {
        this.setState(() => ({content: undefined, focusLost: false, cancelHover: false, inputText: "", showDdlRequireFieldAlert: false, showDetailedDdlRequireFieldAlert: false, detailedChosenOption: notSelectedVal}))
    };

    submit = () => {
        const content = this.state.content;
        const ddlValue = content?.chosenOption?.value as string;
        const detailedDdlValue = this.state.detailedChosenOption?.value as string;
        const inputText = this.state.inputText;
        let reason = "";
        if (ddlValue)
            reason = ddlValue;

        if (detailedDdlValue) {
            if (!reason)
                reason = detailedDdlValue;
            else
                reason += ` : ${detailedDdlValue}`;
        }

        if (inputText) {
            if (!reason)
                reason = detailedDdlValue;
            else
                reason += ` : ${inputText}`;
        }

        this.state.content && this.state.content.onSubmit(reason);
        this.onClose();
    };

    displayDialogContentArea = () => {
        const {content} = this.state;
        const isThereBodyText = content && content.bodyText;
        const isThereInput = content && content.showInput;
        if (isThereBodyText || !isThereInput)
            return true;

        return false;
    }

    validateReason = (value: string) => {
        if (!value)
            return "reason is mandatory field";

        return null;
    }

    onFocusLost = (value: string) => {
        if (!this.state.cancelHover)
            this.setState({focusLost: true});
    }

    renderMandatoryFieldError = () => {
        const {content} = this.state;
        if (this.state.focusLost && content && content.inputMandatoryText && content.showInput && !this.state.inputText)
            return <div key={"reason-error"} style={{marginLeft: "10px"}} className={errorText}>*{content.inputMandatoryText}</div>;

        return null;
    }

    disableSubmitBtn = () => {
        const {content} = this.state;
        const emptyDdl = content && content.ddlOptions?.length && content.ddlFieldIsMandatory && !content.chosenOption?.value;
        const emptyInput = content && content.showInput && content.inputFieldIsMandatory && !this.state.inputText;
        const emptyDetailedDdl = content && content.showDetailedDdl && !this.state.detailedChosenOption?.value;

        return emptyDdl || emptyInput || emptyDetailedDdl;
    }

    renderDetailedReason = () => {
        const {content, detailedChosenOption} = this.state;
        if (!content?.showDetailedDdl)
            return null;

        const reasonValue = content?.chosenOption?.value;
        if (!reasonValue)
            return null;

        let detailedReasonOptions = [];
        if (reasonValue === disenrolledOption.value)
            detailedReasonOptions = ReasonsToDisenrollUnit.map(item => ({key: `key-${item}`, value: item}));
        else if (reasonValue === cancelledOption.value)
            detailedReasonOptions = ReasonsToCancelUnit.map(item => ({key: `key-${item}`, value: item}))
        else
            return null;

        return <NewStyledDropDown
            id={`detailed-reason`}
            wrapperStyle={{borderRadius: "5px"}}
            chosenOption={detailedChosenOption ? detailedChosenOption : notSelectedVal}
            label={`Why ${reasonValue}? (*)`}
            onChange={(sel) => {
                const chosenOption = sel.target.options[sel.target.selectedIndex];
                this.setState({detailedChosenOption: {key: chosenOption.value, value: chosenOption.text},
                    showDetailedDdlRequireFieldAlert: chosenOption.value ? false : this.state.showDetailedDdlRequireFieldAlert});
            }}
            onBlur={() => {
                if (!this.state.detailedChosenOption?.value)
                    return this.setState({showDetailedDdlRequireFieldAlert: true});

                this.setState({showDetailedDdlRequireFieldAlert: false});
            }}
            options={detailedReasonOptions || []}
            error={this.state.showDetailedDdlRequireFieldAlert ? "*This is required field" : ""}
        />
    }

    render() {
        const {content} = this.state;

        const paperPropsStyles: Partial<PaperProps> = {
            style: {
                width: (content && content.width) || "302px",
                height: (content && content.height) || "unset",
                boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.15)",
                borderRadius: "6px",
                position: "fixed",
                top: "36%",
                margin: "auto",
                padding: "26px 28px 18px 26px",
                boxSizing: "border-box"
            },
        };

        return (
            <ThemeProvider theme={dialogThemeConfirmation}>
                <Dialog
                    open={!!content}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    onClose={this.onClose}
                    PaperProps={paperPropsStyles}
                    maxWidth={'sm'}
                    disableEscapeKeyDown
                >
                    {content && content.title && <DialogTitle>{content.title}</DialogTitle>}
                    {content && content.icon && <img src={content.icon}/>}
                    {this.displayDialogContentArea() && <DialogContent>
                        {content && <div dangerouslySetInnerHTML={{__html: content.bodyText}} style={{overflow: "hidden"}}/>}
                    </DialogContent>}
                    {content && content.showInput && <div>
                        {content.ddlOptions?.length && <div style={{marginLeft: "5px"}}>
                        <NewStyledDropDown
                            id={`reason`}
                            wrapperStyle={{borderRadius: "5px"}}
                            chosenOption={content.chosenOption ? content.chosenOption : notSelectedVal}
                            label={content.ddlLabel}
                            onChange={(sel) => {
                                const chosenOption = sel.target.options[sel.target.selectedIndex];
                                this.setState({content: {...content, chosenOption: {key: chosenOption.value, value: chosenOption.text}},
                                    detailedChosenOption: notSelectedVal, showDdlRequireFieldAlert: chosenOption.value ? false : this.state.showDdlRequireFieldAlert });
                            }}
                            onBlur={() => {
                                if (!content?.chosenOption?.value)
                                    return this.setState({showDdlRequireFieldAlert: true});

                                this.setState({showDdlRequireFieldAlert: false});
                            }}
                            options={content.ddlOptions || []}
                            error={this.state.showDdlRequireFieldAlert ? content.ddlMandatoryText : ""}
                        />
                        {this.renderDetailedReason()}
                    </div>}
                        <InputTextAreaField label={content.inputLabel} labelStyle={simpleLabel} onFocusLost={(value) => this.onFocusLost(value)} validateValue={value => this.validateReason(value)} style={textAreaForDialog} maxLength={255} id="newUnitId" onValueChange={(value) => {
                            this.setState({inputText: value});
                        }}/>
                        {this.renderMandatoryFieldError()}
                    </div>}
                    <DialogActions>
                        <button className={cancelStyle} onMouseEnter={() => this.setState({cancelHover: true})} onMouseLeave={() => this.setState({cancelHover: false})} onClick={this.onClose}>{content && content.cancelButtonText || 'Cancel'}</button>
                        <button disabled={this.disableSubmitBtn()} className={submitStyle} onClick={this.submit}>{content && content.submitButtonText || 'Delete'}</button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        )
    }
}
