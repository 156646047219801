import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import * as emotion from "emotion";
import * as React from "react";

const demoContainer = emotion.css ({
	                           width: "300px",
	                           margin: "auto"
                           });

const progressBar = emotion.css ({
	                         height: "4px",
	                         backgroundColor: "rgba(5, 114, 206, 0.2)",
	                         width: "100%",
	                         overflow: "hidden"
                         });

export const indeterminateAnimation = emotion.keyframes`
  0% { transform:  translateX(0) scaleX(0); }
  40% { transform:  translateX(0) scaleX(0.4); }
  100% { transform:  translateX(100%) scaleX(0.5); }
`;
const progressBarValue = emotion.css ({
	                              width: "100%",
	                              height: "100%",
	                              backgroundColor: "rgb(5, 114, 206)",
	                              animation: `${indeterminateAnimation} 1s infinite linear`,
	                              transformOrigin: "0% 50%"
                         });

export class ProgressBar extends BaseComponent {
	render() {
		return <div className={demoContainer}>
			<div className={progressBar}>
				<div className={progressBarValue}></div>
			</div>
		</div>
	}
}