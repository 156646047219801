import * as React from "react";
import {
	BaseComponent,
	Dialog_Builder,
	DialogModule,
	TS_Input
} from "@intuitionrobotics/thunderstorm/frontend";
import {css} from "emotion";
 import {Elliq_ProductKey} from "@app/ir-q-app-common/shared/consts";
import {SimpleLoader} from "@components/SimpleLoader";
import {SetUserGroup} from "./SetUserGroup";
import {
	PairRequestsModule,
	RequestKey_AddPairRequest
} from "@modules/PairRequestsModule";
import {PairRequestBody} from "@app/ir-q-app-common/types/pair-requests";
import {OnRequestListener} from "@intuitionrobotics/thunderstorm/shared/request-types";
import {StorageKey_UserEmail} from "@intuitionrobotics/user-account/frontend";

const contentStyle = css(
	{
		position: "relative",
		padding: "0 40px"
	});

const inputStyle = css(
	{
		background: "white",
		border: "1px solid",
		borderRadius: 10,
		minWidth: 200,
		height: 20
	})

const buttonsStyle = css(
	{
		background: "white",
		border: "1px solid",
		marginLeft: 20,
		borderRadius: 5,
		height: 32,
		width: 38
	})
export type DialogAddPairRequestProps = {}

type State = {
	unitId: string
	tabletSerial: string
	somSerial: string
	env?: string

	product: string

	error?: string
	loading: boolean
}

export class Dialog_AddPairRequest
	extends BaseComponent<DialogAddPairRequestProps, State>
	implements OnRequestListener {

	private initialState: State = {
		unitId: "",
		tabletSerial: "",
		somSerial: "",
		env: undefined,
		product: Elliq_ProductKey,
		error: undefined,
		loading: false
	};

	constructor(props: DialogAddPairRequestProps) {
		super(props);
		this.state = {...this.initialState}
	}

	__onRequestCompleted(key: string, success: boolean) {
		switch (key) {
			case RequestKey_AddPairRequest: {
				if (success)
					DialogModule.close()
				else
					this.setState({loading: false, error: "Error adding pair requests, check logs"})
			}

				break;
		}
	}


	public static show = (props: DialogAddPairRequestProps) => new Dialog_Builder(<Dialog_AddPairRequest {...props}/>)
		.setTitle(<div style={{background: "rgb(236,252,255)", fontWeight: 600, padding: 15}}>{"Pair Devices"}</div>)
		.setStyle({minWidth: 600})
		.setAllowIndirectClosing(true)
		.show();

	render() {
		return <div className={`${contentStyle} ll_v_c`}>
			{this.renderErrorMessage()}
			<div className={"ll_h_c"} style={{padding: "20px 0"}}>
				{this.renderInput("somSerial")}
				{this.renderInput("tabletSerial")}
				{this.renderInput("unitId")}
				{this.renderEnv()}
				{this.renderSubmit()}
			</div>
			{this.state.loading && <SimpleLoader absolute={true} noText={true}/>}
		</div>
	};

	private renderSubmit() {
		return <button
			onClick={this.addPairRequest}
			className={buttonsStyle}>
			Add
		</button>;
	}

	private addPairRequest = () => {
		if (!this.state.unitId || this.state.unitId.split("-").length !== 3)
			return this.setState({error: `Illegal UnitId ${this.state.unitId}, follow pattern: xxx-xxx-xxx`});

		if (!this.state.env)
			return this.setState({error: "Please select an env from the dropdown"});

		if (!this.state.somSerial)
			return this.setState({error: "Please type a serial number for the som"});

		if (!this.state.tabletSerial)
			return this.setState({error: "Please type a serial number for the tablet"});

		const requestObject: PairRequestBody = {
			product: Elliq_ProductKey,
			unitId: this.state.unitId,
			somSerial: this.state.somSerial,
			tabletSerial: this.state.tabletSerial,
			env: this.state.env,
			createdBy: StorageKey_UserEmail.get()
		};

		this.setState({loading: true})
		PairRequestsModule.addPairRequests(requestObject);
	};

	private renderInput(key: "somSerial" | "tabletSerial" | "unitId") {
		return <TS_Input
			style={{width: '80%', height: 28}}
			value={this.state[key]}
			onChange={(value) => this.setState(state => {
				return {...state, [key]: value}
			})}
			type={"text"}
			id={key}
			placeholder={`Type the ${key}`}
			className={inputStyle}
		/>;
	}

	private renderErrorMessage() {
		if (!this.state.error)
			return;

		return <div className={"ll_h_c match_width"} style={{justifyContent: "space-between", marginBottom: 10}}>
			<div onClick={() => this.setState(this.initialState)}>Retry</div>
			<div style={{color: "red", fontSize: "120%"}}>{this.state.error}</div>
		</div>
	}

	private renderEnv() {
		return <SetUserGroup
			userGroup={this.state.env}
			onUserGroupChange={env => this.setState({env, error: undefined})}
		/>
	}
}


