/*
 * Permissions management system, define access level for each of
 * your server apis, and restrict users by giving them access levels
 *
 * Copyright (C) 2020 Intuition Robotics
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import {
    Dialog_Builder,
    DialogModule,
    ToastModule,
    TS_Input,
    TS_TextArea,
    BaseComponent
} from "@intuitionrobotics/thunderstorm/frontend";
import {generateHex} from "@intuitionrobotics/ts-common";
import {BugReportModule, RequestKey_BugReportApi} from "@intuitionrobotics/bug-report/app-frontend/modules/BugReportModule";
import {Platform_Jira, Platform_Slack} from "@intuitionrobotics/bug-report/shared/api";
import {DialogButton_Cancel, DialogButton_Submit} from "./Dialog";
import * as emotion from "emotion";
import {OnRequestListener} from "@intuitionrobotics/thunderstorm";
import {SimpleLoader} from "@components/SimpleLoader";
type Props = {
    component?: React.ReactNode
    children?: React.ReactNode
}
const style: React.CSSProperties = {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    width: "50px",
    height: "50px",
    bottom: "30px",
    right: "10px",
    backgroundColor: "#5b7bd6",
    color: "white",
    borderRadius: "50%",
    borderColor: 'transparent'
};
const bugClass = emotion.css(`
    cursor: pointer;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 40px;
    height: 40px;
    bottom: 30px;
    right: 10px;
    padding: 5px;
    background-color: white;
    border-radius: 50%;
    border-color: transparent;
`)

type State = {
    error?: Error,
    errorInfo?: React.ErrorInfo
    description?: string
    subject?: string

    loading: boolean
}

export class BugReport
    extends BaseComponent<Props, State> implements OnRequestListener{
    __onRequestCompleted(key: string, success: boolean, requestData: string | undefined): void {
        switch (key){
            case RequestKey_BugReportApi:
                this.setState({loading:false})
        }
    }
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    componentDidMount() {

    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        BugReportModule.sendBugReport("Automatic submission", "these logs were triggered by a UI failure", [Platform_Slack]);
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
    }

    showAppConfirmationDialogExample = () => {
        const onSubmit = () => {
            if (!this.state.subject)
                return ToastModule.toastError('Please add a title to the bug');
            BugReportModule.sendBugReport(this.state.subject, this.state.description || 'Description not provided', [Platform_Jira]);
            this.setState({subject: undefined, description: undefined, loading: true});
            DialogModule.close();
        };

        const content =
            <div className={'ll_v_c'}>
                <TS_Input
                    focus={true}
                    id={"bug-report-subject"}
                    type={"text"}
                    value={this.state.subject || ''}
                    placeholder={"Give a title for context"}
                    name={generateHex(8)}
                    onChange={(subject: string) => this.setState({subject})}
                    style={{
                        width: "100%",
                        border: "1px solid grey",
                        borderRadius: '5px',
                        padding: 5
                    }}
                />
                <TS_TextArea
                    id={"bug-report-description"}
                    type="text"
                    style={{
                        height: "130px",
                        margin: "8px",
                        width: "100%",
                        outline: "none",
                        border: "1px solid grey"
                    }}
                    value={this.state.description || ''}
                    placeholder={"(Optional) Please give as much info as possible"}
                    onChange={(description: string) => this.setState({description})}/>
            </div>;


        new Dialog_Builder(content)
            .setTitle(<div className={'ll_v_c'} style={{padding: 5}}>Bug Report</div>)
            .addButton(DialogButton_Cancel(() => {
                this.setState({description: undefined, subject: undefined})
                DialogModule.close();
            }))
            .addButton(DialogButton_Submit(() => onSubmit(), 'Submit'))
            .setOverlayColor("rgba(102, 255, 255, 0.4)")
            .setStyle({
                minWidth: "400px",
                padding: "10px"
            })
            .show();
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div>
                    <h2>Something went wrong.</h2>
                    <details style={{whiteSpace: 'pre-wrap'}}>
                        {this.state.error && this.state.error.toString()}
                        <br/>
                        {this.state.errorInfo.componentStack}
                    </details>
                    <button style={style} onClick={() => window.location.reload()}>reload!</button>
                </div>
            );
        }

        return (
            <>
                {this.props.children}
                <div
                    onClick={this.showAppConfirmationDialogExample}
                >
                    <img
                        className={bugClass}
                        src={require('@res/images/icon__bug.png')}/>
                    {this.renderLoader()}
                </div>
            </>
        );
    }

    private renderLoader = () => {
        if(!this.state.loading)
            return;

        return <SimpleLoader overlay={true} message={"Sending..."}/>
    };
}
