import * as React from "react";
import {css} from "emotion";
import {TooltipBuilder, TooltipDefaultStyle, TooltipModule} from "@intuitionrobotics/thunderstorm/frontend";

const btnClass = css({
    margin: "0 10px",
    cursor: 'pointer'
});

type Props = {
    chattinessLevel: number
    onChange: (c: number) => void
    infoText?: string
};

export class ComponentChattinessLevel
    extends React.Component<Props> {

    render() {
        const data: number = this.props.chattinessLevel

        const options = [
            {value: 0, label: 'Not chatty at all'},
            {value: 0.33, label: 'Up to 3 times a day'},
            {value: 0.66, label: 'Up to 6 times a day'},
            {value: 1, label: 'Up to 9 times a day'}
        ]

        return <div>
            <div className={"chattinessWrapper"}>
                <div style={{marginRight: 5}}>Chattiness Level:</div>
                {options.map(o => {
                    const value = o.value;
                    const checked = data === value;
                    return <div className={btnClass}>
                        <input
                            id={`chattiness-${value}`}
                            type="radio"
                            checked={checked}
                            style={{
                                cursor: 'pointer'
                            }}
                            onMouseOver={(e) => {
                                if (checked)
                                    this.showTooltip(e, this.props.infoText);
                            }}
                            onMouseLeave={(e) => {
                                TooltipModule.hide();
                            }}
                            onChange={(e) => {
                                e.stopPropagation()
                                if (checked)
                                    return;
                                this.props.onChange(value)
                            }}
                        />
                        <label
                            style={{
                                cursor: 'pointer'
                            }}
                            htmlFor={`chattiness-${value}`}
                        >{o.label}</label>
                    </div>
                })}
            </div>
        </div>
    }

    showTooltip(e: React.MouseEvent<HTMLDivElement>, content: React.ReactNode) {
        new TooltipBuilder(content, e)
            .setLocation(e.clientX + 10, e.clientY + 20)
            .setStyle({...TooltipDefaultStyle, zIndex: 1400})
            .setDuration(5000)
            .show();
    }
}