/*
 * A typescript & react boilerplate with api call example
 *
 * Copyright (C) 2018  Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Module} from "@intuitionrobotics/ts-common";
import {ThunderDispatcher} from "@intuitionrobotics/thunderstorm/frontend";
import {DropdownOption} from "@components/NewStyledDropDown";

export type Confirmation_Dialog = {
	title?: string,
	ddlOptions?: DropdownOption[],
	chosenOption?: DropdownOption,
	ddlLabel?: string,
	inputLabel?: string,
	showInput?: boolean,
	inputFieldIsMandatory?: boolean,
	ddlFieldIsMandatory?: boolean,
	showDetailedDdl?: boolean,
	inputMandatoryText?: string,
	ddlMandatoryText?: string,
	bodyText: string,
	icon?: string,
	submitButtonText?: string,
	cancelButtonText?: string,
	width?: number,
	height?: number,
	onSubmit: (reason?: string) => void,
}

export interface ConfirmationDialogListener {
	showConfirmation(confirmation: Confirmation_Dialog): void;
}

export const ConfirmationText_deleteText = (item: string, context?: string) => (
	`You are about to delete<br/>
	 ${context ? `${context} ` : ''}<b>${item}</b>.<br/>
	This process cannot be undone!`
);
export const ConfirmationText_GeneralText = (text: string) => (
	`You are about to <br/>
	 <b>${text}</b>.<br/>
	This process cannot be undone!`
);

export class ConfirmationModule_Class
	extends Module<{}> {
	private showConfirmation = new ThunderDispatcher<ConfirmationDialogListener, "showConfirmation">("showConfirmation");

	public confirmationDialog(params: Confirmation_Dialog) {
		this.showConfirmation.dispatchUI(params)
	}
}

export const ConfirmationModule = new ConfirmationModule_Class("ConfirmationModule");
