import * as React from "react";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {AndroidArtifactData, PmStatus} from "@app-sp/app-shared/package-manager";
import {createReadableTimestampObject} from "@intuitionrobotics/ts-common";
import {
    COLOR_greenyBlue,
    COLOR_lightGreen,
    COLOR_lightRed,
    COLOR_lightYellow,
    COLOR_orange,
    COLOR_red
} from "@styles/colors";
import {css} from "emotion";

interface Props {
    deviceStatus: PmStatus
}

const classHeaders = css({color: "black", fontSize: '0.7rem'});

class Panel_ArtifactData
    extends BaseComponent<Props> {

    constructor(props: Props) {
        super(props);
    }

    render() {
        return <>
            {this.renderTable()}
        </>
    }

    renderTable = () => {
        const androidArtifactData = this.props.deviceStatus.status;
        if (!androidArtifactData || !androidArtifactData.artifact_data || !Array.isArray(androidArtifactData.artifact_data))
            return;

        const showVia = androidArtifactData.artifact_data.some(artifact => artifact.installedVia !== undefined);
        const showError = androidArtifactData.artifact_data.some(artifact => artifact.errorsAsString !== "N/A");
        return <table className={'table-artifact-data'}>
            <thead>{Panel_ArtifactData.renderHead(showVia, showError)}</thead>
            <tbody>{androidArtifactData.artifact_data.map((a,i) => this.renderRow(a,i,showVia,showError))}</tbody>
        </table>
    };

    static renderHead(showVia: boolean, showError: boolean) {
        return <tr className={"table-header-color"}>
            <th align="center" className={classHeaders}>Install Time</th>
            <th align="center" className={classHeaders}>App Name</th>
            <th align="center" className={classHeaders}>Install State</th>
            {showVia && <th align="center" className={classHeaders}>Via</th>}
            <th align="center" className={classHeaders}>Old</th>
            <th align="center" className={classHeaders}>New</th>
            {showError && <th align="center" className={classHeaders}>Error</th>}
        </tr>
    }

    renderRow = (artifact: AndroidArtifactData, idx: number, showVia: boolean, showError: boolean) => {
        const cellFontSize = '0.7rem';
        const timestamp = createReadableTimestampObject("DD/MM-hh:mm", artifact.installationTime, undefined);
        const bgColor = Panel_ArtifactData.getStateColor(artifact.installationState);
        return <tr key={idx} style={{backgroundColor: bgColor, fontSize: cellFontSize}}>
            <th>{timestamp.pretty}</th>
            <th>{artifact.appName}</th>
            <th>{artifact.installationState}</th>
            {showVia && <th>{artifact.installedVia}</th>}
            <th>{artifact.versionName}</th>
            <th>{artifact.newVersionName}</th>
            {showError && <th>{artifact.errorsAsString === "n/a" ? "" : artifact.errorsAsString}</th>}
        </tr>
    };

    static getStateColor(state: string): string {
        switch (state) {
            case "NotDownloaded":
            case "ERROR":
                return COLOR_red;
            case "InstalledDifferentVersion":
                return COLOR_lightRed;
            case "Downloading":
                return COLOR_orange;
            case "Uninstalling":
            case "Installing":
                return COLOR_lightGreen;
            case "Downloaded":
                return COLOR_lightYellow;
            case "InstalledUpToDate":
                return COLOR_greenyBlue;
            default:
                return COLOR_lightYellow;
        }
    }

}

export default Panel_ArtifactData;
