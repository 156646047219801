import * as React from 'react';
import * as emotion from 'emotion';
import {
	COLOR_darkSkyBlue,
	COLOR_DashedBorderGray
} from "@styles/colors";

const icon__upload = require('@res/images/icon__upload.svg')

const DND_State = {
	Idle: "idle",
	Dragging: "dragging",
	Negative: "negative",
};

// const container = emotion.css`
// 	padding: 10px;
// `;

interface IProps {
	id?: string,
	error?: boolean,
	validate: ((files: File[]) => _FilesType) | string[] | string;
	onChange: (files: _FilesType) => void
	height: string
	children: React.ReactNode
}

export type _FilesType = {
	accepted: File[],
	rejected: File[],
	uploaded: File[]
}

interface IState {
	dndState: string
}

class DragnDrop
	extends React.Component<IProps, IState> {

	// private inputRef = React.createRef<HTMLInputElement>();
	// private timers: (ReturnType<typeof setTimeout>)[] = [];

	state = {dndState: DND_State.Idle};
	private dragCounter: number = 0;
	static defaultProps = {
		height: "89vh"
	};

	// componentWillUnmount(): void {
	// 	this.timers.forEach(k => clearTimeout(k));
	// }

	onSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			this.updateFileArray(Object.values(e.target.files));
		}
	};

	static extractContent = (ev: React.DragEvent<HTMLDivElement>): File[] => Object.values(ev.dataTransfer.files);

	extractPayload = (ev: React.DragEvent<HTMLDivElement>) => {
		const files = DragnDrop.extractContent(ev);

		if (files.length === 0) {
			this.setState({dndState: DND_State.Idle});
			// this.timers.push(setTimeout(() => this.setState({dndState: DND_State.Idle}), timeoutSeconds));
			return;
		}

		return files;
	};

	validateArray = (files: File[], fileExt: string | string[]): _FilesType => {
		const _res: _FilesType = {
			accepted: [],
			rejected: [],
			uploaded: [],
		};

		const extensions = Array.isArray(fileExt) ? fileExt : [fileExt];

		return files.reduce((res, f) => {
			if (extensions.some(ext => RegExp(`.${ext}$`, 'i').test(f.name))) {
				res.accepted.push(f);
			} else {
				res.rejected.push(f);
			}
			return res;
		}, _res);
	};

	private onDragEnter = (ev: React.DragEvent<HTMLDivElement>) => {
		ev.preventDefault()
		ev.stopPropagation()
		this.dragCounter++
		if (ev.dataTransfer.items && ev.dataTransfer.items.length > 0) {
			this.setState({dndState: DND_State.Dragging})
		}
	};

	handleDrop = (ev: React.DragEvent<HTMLDivElement>) => {
		const files = this.extractPayload(ev);
		files && this.updateFileArray(files);

	};

	onDrop = (ev: React.DragEvent<HTMLDivElement>): void => {
		ev.preventDefault();
		ev.stopPropagation();

		this.setState({dndState: DND_State.Idle});

		if (ev.dataTransfer.files && ev.dataTransfer.files.length > 0) {
			this.handleDrop(ev);
			ev.dataTransfer.clearData();
			this.dragCounter = 0
		}
	};

	updateFileArray = (_files: File[]) => {

		let files: _FilesType;
		if (this.props.validate instanceof Function) {
			files = this.props.validate(_files);
		} else {
			files = this.validateArray(_files, this.props.validate);
		}

		this.props.onChange(files);

		this.setState({dndState: DND_State.Idle});

	};


	onDragOver = (ev: React.DragEvent<HTMLDivElement>): void => {


		ev.preventDefault();
		ev.stopPropagation();
		//
		// if (this.state.dndState !== DND_State.Idle)
		// 	return;
		// console.log('idle');
		// const dndState = (ev.dataTransfer.items && ev.dataTransfer.items.length > 0) ? DND_State.Dragging : DND_State.Negative;
		// console.log('next state',dndState);
		// this.setState({dndState});
	};

	onDragLeave = (ev: React.DragEvent<HTMLDivElement>) => {

		ev.preventDefault();
		ev.stopPropagation();
		this.dragCounter--;
		if (this.dragCounter === 0) {
			this.setState({dndState: DND_State.Idle})
		}
		// this.setState({dndState: DND_State.Idle});
	};

	static removeDragData = (ev: React.DragEvent<HTMLDivElement>) => {

		if (ev.dataTransfer.items) {
			// Use DataTransferItemList interface to remove the drag data
			ev.dataTransfer.items.clear();
		} else {
			// Use DataTransfer interface to remove the drag data
			ev.dataTransfer.clearData();
		}
	};

	render() {
		const style = emotion.css({
			                          backgroundColor: this.state.dndState !== DND_State.Dragging ? 'transparent' : 'rgba(255,255,255,.7)',
			                          position: 'absolute',
			                          top: 45,
			                          bottom: 0,
			                          left: 0,
			                          right: 0,
			                          zIndex: 9999
		                          });
		return (
			<div
				style={{display: 'inline-block', position: 'relative', height: this.props.height}}
				className={'match_height match_width'}
				onDragEnter={this.onDragEnter}
				onDragOver={this.onDragOver}
				onDragLeave={this.onDragLeave}
				onDrop={this.onDrop}
			>
				{this.state.dndState === DND_State.Dragging &&
				<div className={style}
					// onClick={() => (this.inputRef.current && this.inputRef.current.click())}
				>
					{/*<input id="fileInput" type="file" ref={this.inputRef} hidden={true} multiple onChange={this.onSelect}/>*/}
					<div
						style={{
							border: `dashed ${COLOR_DashedBorderGray} 5px`,
							borderRadius: "15px",
							position: 'absolute',
							top: 99,
							bottom: 80,
							left: 80,
							right: 80,

						}}
					>
						<div
							style={{
								position: 'absolute',
								right: 0,
								left: 0,
								bottom: 28,
								textAlign: 'center',
								color: COLOR_darkSkyBlue,
								fontSize: 18,
								fontWeight: 500
							}}
						>
							<img src={icon__upload}/>
							<div>Drop files here to upload them</div>
						</div>
					</div>
				</div>
				}
				{this.props.children}
			</div>
		)
	}
}

export default DragnDrop
