import {SortOrder} from "@components/table/consts";
import {Minute} from "@intuitionrobotics/ts-common";
import {PackageManagerModule} from "@modules/package-manager/PackageManagerModule";
import {UnitInfo} from "@app-sp/app-shared/package-manager";
import {UserGroupTypes} from "@app/ir-q-app-common/types/units";

export enum Status {
    live = "live",
    error = "error",
    tabletOff = "tabletOff",
    somOff = "somOff",
    down = "down",
    manuallyOff = "manuallyOff",
    preInstall = "preInstall"
}

export type StatusMap = {
    [k in Status]: number
} & {
    total: number,
    category: string
}

export enum VersionStatus {
    sameVersions = "Same versions",
    noVersions = "No versions",
    noSomVersion = "No Som version",
    noTabletVersion = "No Tablet version",
    differentVersions = "Different versions",
}

export const dateOptions = [
    "All date",
    "Last 36 hours",
    "Last 48 hours",
    "Last 72 hours",
    "Last 7 days",
    "Last 14 days",
    "Last 30 days"
];

export const errorNoRuntimeStatus = "PI";
export const errorDiffVersion = "DF";
export const errorOptions = [
    "Error B",
    "Error C",
    "Error N",
    "Error A",
    "Error M",
    "Error V",
    "Error D",
    "Error U",
    "Error R",
    "Error " + errorNoRuntimeStatus,
    "Error " + errorDiffVersion
]

export const diskMemoryInUseOptions = [
    "More than 85% in use"
]

export const stricklandOptions = [
    "UP_AND_RUNNING",
    "WAITING_FOR_INTERNET",
    "UNINITIALIZED",
    "WAIT_FOR_WIFI_CREDENTIALS",
    "CONNECTING_TO_WIFI",
    "CONNECTING_TO_WIFI_FAILED",
    "CONNECTING_TO_WIFI_FAILED_AUTH",
    "WAITING_FOR_PEER_CONNECTION",
    "FORGET_ALL_SSIDS",
    "RESET_STRICKLAND_SETTINGS"
];

export const icon__unpair_white = require("@res/images/icon__unpair_white.svg");
export const icon__chat = require("@res/images/icon_chat.svg");
export const icon__unpair = require("@res/images/icon__unpair.svg");
export const icn_v_purple = require("@res/images/icn_v_purple.svg");
export const icon__eyeS = require("@res/images/icon__eyeS.svg");
export const icon__pencil = require("@res/images/icon__pencil.svg");
export const icon__redPencil = require("@res/images/icon__redPencil.svg");
export const icon__rightArrow = require("@res/images/icon-arrowhead-dark-xl-right.svg");
export const icon__leftArrow = require("@res/images/icon-arrowhead-dark-xl-left.svg");


// export const devEnvs: string[] = [
//     UnitEnv.QA,
//     UnitEnv.Support,
//     UnitEnv.Refurbished,
//     UnitEnv.Dev,
//     UnitEnv.Disenrolled
// ];

export interface PageUnitsState {
    loading: boolean;
    page: number;
    statusFilters: { [key: string]: boolean };
    userGroupFilters: { [key: string]: boolean };
    filters: { [index: string]: string };
    selectedDateRange: { [index: string]: (string | { from: number, to: number }) }
    sortOrder: SortOrder;
    sortBy: string;
    batch: boolean;
    batchUnits: string[];
    checkAll: boolean;
    selectedFrozen?: string[]
    tableHeaderConfig: string[]
    dragItem?: number
    dragOverItem?: number,
    unitsType: UserGroupTypes
}

export const Timeout_RS = 2.5 * Minute;

export type MyTableRow = {
    category: string,
    live: number,
    error: number,
    tabletOff: number,
    somOff: number,
    down: number,
    manuallyOff: number,
    preInstall: number,
    total: number
}
export const installationOptions = ["partial",
    "tablet partial",
    "som partial",
    "complete"];

export const rcOptions = [
    "rc",
    "rc & manual",
    "no rc",
    "rc 10+ min",
    "s - rc not finished",
    "t - rc not finished"
];

export const getPMUnitConfig = (unitId: string, product: string): UnitInfo | undefined => PackageManagerModule.getUnit({unitId, product});

export const getAliasAndConfig = (unitId: string, product: string = "elliq") => {
    const pmUnit = getPMUnitConfig(unitId, product);
    let alias = "", config = "";
    if (pmUnit) {
        alias = pmUnit.aliasId ? PackageManagerModule.getAlias(pmUnit.aliasId)?.name.toLowerCase() : "";
        config = pmUnit.configId ? PackageManagerModule.getConfig(pmUnit.configId)?.name.toLowerCase() : "";
    }
    return {alias, aliasId: pmUnit?.aliasId, config, configId: pmUnit?.configId}
}

export const UnitIdentitiesType = ["activation", "pairing", "both"];

export const tableHeaderConfigInit = [
    "unitId",
    "version",
    "buildNumber",
    "versionStatus",
    "systemErrors",
    "isRc",
    "installation",
    "aliasConfig",
    "userGroup",
    "somTime",
    "tabletTime",
    "strickland",
    "alive",
    "deviceVersion",
    "unitIdentity",
    "serial",
    "whoUpdated",
    "diskMemoryInUse"
];
