import * as React from "react";
import {useContext} from "react";
import {Grid} from "@mui/material";
import {auditBy} from "@intuitionrobotics/ts-common";
import {StorageKey_UserEmail} from "@intuitionrobotics/user-account/frontend";
import {UserGroupAction, UserGroupsContext} from "../../../App";
import {DropDownWithAddOption} from "../../pairing/DropDownWithAddOption";
import {UnitsModule} from "@modules/UnitsModule";
import {CONST_KS_SubUserGroup, CONST_KS_UserGroup} from "@app/ir-q-app-common/types/unit-config";
import {initUnitsType} from "../Page_Units";

type Props = {
    userGroup?: string
    onUserGroupChange: (userGroup: string) => void
    subGroup?: string
    onSubGroupChange?: (subGroup: string) => void
}


export const SetUserGroup = (props: Props) => {
    const {userGroups, dispatch} = useContext(UserGroupsContext);
    const userGroup = props.userGroup;
    const val = userGroup ? {
        key: userGroup.trim(),
        value: userGroup
    } : {key: "", value: ""};

    const subUserGroup = props.subGroup;
    const subUserGroupVal = subUserGroup ? {
        key: subUserGroup,
        value: subUserGroup
    } : {key: "", value: ""};
    const metadatas = UnitsModule.getMetadatas();
    const subUserGroups: Set<string> = new Set<string>();
    if (userGroup) {
        Object.values(metadatas).forEach(metadata => {
            if (metadata.some(m => m.data === userGroup && m.dataKey === CONST_KS_UserGroup)) {
                const subMeta = metadata.find(m => m.dataKey === CONST_KS_SubUserGroup);
                subMeta && subUserGroups.add(subMeta?.data);
            }
        })
    }
    if (subUserGroup)
        subUserGroups.add(subUserGroup);

    return <Grid container direction={"column"}>
        <Grid item style={{width: "100%", padding: "10px 0"}}>
            <Grid container direction={"row"} alignItems={"center"}>
                <Grid item>
                    <DropDownWithAddOption
                        id={"userGroup"}
                        label={"User Group (*)"}
                        selectedOption={val}
                        options={userGroups.sort((a, b) => a
                            .label
                            .localeCompare(b.label)).map(dataEnv => ({key: dataEnv.label.trim(), value: dataEnv.label}))}
                        addItem={async ug => {
                            await dispatch(UserGroupAction.CREATE, {
                                _id: ug,
                                audit: auditBy(StorageKey_UserEmail.get()),
                                type: initUnitsType(),
                                label: ug
                            });
                            props.onUserGroupChange(ug);
                        }}
                        onChange={ug => props.onUserGroupChange(ug)}
                    />
                </Grid>
            </Grid>
            {props.onSubGroupChange && <Grid container direction={"row"} alignItems={"center"}>
                <Grid item>
                    <DropDownWithAddOption
                        id={"subUserGroup"}
                        label={"Sub User Group"}
                        selectedOption={subUserGroupVal}
                        disabled={!userGroup}
                        options={[...subUserGroups].sort((a, b) => a
                            .localeCompare(b)).map(v => ({key: v, value: v}))}
                        onChange={_v => props.onSubGroupChange?.(_v)}
                    />
                </Grid>
            </Grid>}
        </Grid>
    </Grid>

}
