import {Module} from "@intuitionrobotics/ts-common";
import {ToastModule, XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {HttpMethod} from "@intuitionrobotics/thunderstorm";

import {
	ApiGetOverride,
	ApiSetOverride
} from "@app-sp/app-shared/api";
import {
	OverrideData,
	Response_GetOverride
} from "@app-sp/app-shared/units";
import {Unit} from "@app/ir-q-app-common/types/units";
import {UnitsModule} from "@modules/UnitsModule";
import {UnitsManagerModule} from "@modules/UnitsManagerModule";

type Config = {}

export const RequestKey_FetchOverride = "FetchOverride";
export const RequestKey_SetOverride = "SetOverride";


class OverridesModule_Class
	extends Module<Config> {

	private overridesData: { [unitId: string]: Response_GetOverride } = {};

	fetchOverrides(unitId: string, product: string, env: string) {
		XhrHttpModule
			.createRequest<ApiGetOverride>(HttpMethod.GET, RequestKey_FetchOverride)
			.setRelativeUrl("/v1/elliq/override/get")
			.setUrlParams({unitId: unitId, product: product, env: env})
			.setLabel(`Fetching Override for '${product}' '${unitId}'`)
			.setOnError(`Error listing override for '${product}' '${unitId}'`)
			.execute(async response => {
				this.overridesData[unitId] = response;
			});
	}

	fetchOverride = (unitId?: string, product?: string) => {
		if (!unitId || !product)
			return;

		// const unit = UnitsModule.getIdentity(unitId, product);
		const unit = UnitsManagerModule.getUnitBasic(unitId);
		if (!unit)
			return ToastModule.toastError(`unit not found`);

		OverridesModule.fetchOverrides(unitId, product, UnitsModule.getEnv());
	};

	saveOverrides(units: Unit[], overrideData: object, _path?: string) {
		const override: OverrideData = {
			units: units,
			overrides: overrideData
		};
		if (_path)
			override.path = _path;

		const _logs = override.units.map(u => u.unitId).join();
		XhrHttpModule
			.createRequest<ApiSetOverride>(HttpMethod.POST, RequestKey_SetOverride)
			.setRelativeUrl("/v1/elliq/override/set")
			.setJsonBody(override)
			.setLabel(`Setting override for '${_logs}'`)
			.setOnError(`Error listing override for '${_logs}'`)
			.execute();
	}

	getOverride(unitId: string) {
		return this.overridesData[unitId];
	}

}

export const OverridesModule = new OverridesModule_Class("OverridesModule");
