import * as React from "react";
import {PackageManagerModule} from "@modules/package-manager/PackageManagerModule";
import {AppModule} from "@modules/AppModule";

export class Page_PM_iframe
	extends React.Component {
	render() {
		const pmWebsite = PackageManagerModule.getPMWebsite();
		const src = AppModule.composeUrlWithAuth(pmWebsite);
		return <div className={"match_width match_height"}>
			<iframe
				style={{borderStyle: "none"}}
				width={"100%"}
				height={"100%"}
				src={src}/>
		</div>;
	}
}