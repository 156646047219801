import * as React from "react";
import {ReactNode} from "react";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {CustomLoader} from "@components/CustomLoader";

export type LoadableState = {
	loading?: boolean
	loadingText?: string
}

export type LoadableProps = {}

export abstract class LoadableComponent<Props, State extends LoadableState>
	extends BaseComponent<Props, State> {

	private ComponentLoader: React.ComponentType = CustomLoader;

	protected constructor(props: Props) {
		super(props);
	}

	setLoader(loader: React.ComponentType) {
		this.ComponentLoader = loader;
	}

	setLoading = (loading = true, loadingText?: string) => {
		this.setState({loading, loadingText})
	};

	render() {
		if (this.ComponentLoader === CustomLoader)
			this.ComponentLoader.defaultProps = {text: this.state.loadingText};

		if (!this.state.loading)
			return this.renderComponent();

		return <>
			{this.renderShadow()}
			<this.ComponentLoader/>
		</>;
	}

	protected abstract renderComponent(): ReactNode ;

	// noinspection JSMethodCanBeStatic
	protected renderShadow(): any {
		return "";
	}
}
