import * as React from "react";
import {
	BaseComponent,
	GenericSelect
} from "@intuitionrobotics/thunderstorm/frontend";
import {OnRequestListener} from "@intuitionrobotics/thunderstorm";
import {
	RequestKey_GetUnitStatusTable,
	UnitsModule
} from "@modules/UnitsModule";
import {paddingTop} from "@styles/dynamic-styles";
import {_keys} from "@intuitionrobotics/ts-common";
import {
	BQData,
	BQDataPoint
} from "@app-sp/app-shared/api";
import {UnitBasic} from "@app/ir-q-app-common/types/units";
import {UnitsManagerModule} from "@modules/UnitsManagerModule";

interface State {
	loginError: boolean
	table?: string
}
const envKey = 'environment';

export class Page_EnvCompare
	extends BaseComponent<{}, State>
	implements OnRequestListener {
	constructor(props: {}) {
		super(props);
		this.state = {
			loginError: false
		};
		UnitsModule.fetchUnitStatusTable()
	}

	__onRequestCompleted = (key: string) => {
		switch (key) {
			default:
				return;

			case RequestKey_GetUnitStatusTable: {
				const data = UnitsModule.getBQData()
				const dataKeys = this.getDataKeys(data);
				if (dataKeys.length)
					return this.setState({table: dataKeys[0]})
				this.forceUpdate();
			}
		}
	};

	private getDataKeys = (data: BQData) => _keys<BQData, string>(data);

	render() {
		const data = UnitsModule.getBQData()
		return <div className={`ll_v_c match_width ${paddingTop(10)}`}>
			<span style={{width: 200}}><GenericSelect<{ value: string, label: string }>
				selectedOption={this.state.table ? {value: this.state.table, label: this.state.table} : undefined}
				options={this.getDataKeys(data).map(d => ({value: d, label: d}))}
				placeholder={'select dataset'}
				onChange={(s) => {
					this.setState({table: s.value})
				}}
				iconClose={<img alt={'close'} src={require("@res/images/icon__arrowClose.svg")} width={10}/>}
				iconOpen={<img alt={'open'} src={require("@res/images/icon__arrowOpen.svg")} width={10}/>}
				styles={{width: "100%"}}
				presentation={option => option.label || ''}
			/></span>
			{this.renderTable(data)}
		</div>;
	}

	private renderTable = (data: BQData) => {
		let dataSet: BQDataPoint[] | undefined
		if (this.state.table)
			dataSet = data[this.state.table];

		if (!dataSet || !dataSet.length)
			return;

		const firstDataPoint = dataSet[0];
		const headers = _keys(firstDataPoint);
		const idx = headers.findIndex(h => h === envKey);
		headers.splice(idx,1)
		headers[headers.length] = envKey
		return <table style={{border: '1px solid'}}>
			<thead>
			<tr>
				{headers.map((header, i) => <th key={`rowKey_${i}`}>{header}</th>)}
				<th style={{color:"red"}} key={`rowKey_envCompare`}>env_in_pairing</th>
			</tr>
			</thead>
			<tbody>
			{dataSet.map((v, i) => this.rednderRow(i, v, headers))}
			</tbody>
		</table>;
		// dataSet.map(d => d.)
	};

	private rednderRow = (i: number, v: BQDataPoint, headers: (keyof BQDataPoint)[]) => {
		const unitId = v.creator1;
		const unitBasic = UnitsManagerModule.getUnitBasic(unitId);
		if(!unitBasic)
			return;

		if(UnitsModule.getUnitUserGroup(unitId) === v.environment)
			return;

		return <tr key={`row_${i}`}>{this.renderCell(v, headers, unitBasic)}</tr>;
	};

	private renderCell = (dataPoint: BQDataPoint, headers: (keyof BQDataPoint)[], identity: UnitBasic) => {
		return <>{headers.map((header, i) => {
			const dataSetElement = dataPoint[header];
			const typeofVal = typeof dataSetElement;
			// @ts-ignore
			return <td key={`rowCellKey_${i}`}>{dataSetElement && typeofVal === 'object' ? dataSetElement.value : dataSetElement}</td>;
		})}
			<td key={`rowCellKey_envToCompare`}>{UnitsModule.getUnitUserGroup(identity.unitId)}</td>
		</>
	};
}

