import {__stringify} from "@intuitionrobotics/ts-common";

export const enum SortOrder {
	ASC  = "asc",
	DESC = "desc",
}

export function createFilterPattern(rawFilter?: string) {
	const filterArr = (rawFilter || "")
		.split(',')
		.map(filter => {
			let _filter = filter.trim();
			if (_filter[0] === "!")
				_filter = _filter.substring(1);
			_filter = _filter.replace(/\s+/g, " ");
			_filter = _filter.replace(/ /g, ".*?");
			return `(?=.*?${_filter})`;
		})
		.filter(filter => filter !== "(?=.*?)");
	try {
		return new RegExp(filterArr.join('|'), 'ig');
	} catch (e) {
		console.error(__stringify(e));
		return new RegExp(".*", 'ig');
	}
}

export const createUnitErrorRegexFilter = (unitErrorsString: string): RegExp => {
	// Extract the error codes from the input string using a regular expression
	const errorCodes = unitErrorsString.match(/[a-z]\d?/g) || []

	// Create a regex pattern that matches any of the error codes, case-insensitive
	const regexPattern = `(${errorCodes.join("|")})`

	// Return a RegExp object created from the pattern, case-insensitive
	return new RegExp(regexPattern, "i")
}

