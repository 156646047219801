import * as React from "react";
import * as emotion from "emotion";
import {BaseComponent, GenericSelect, ToastModule, TS_CheckboxField, TS_Input} from "@intuitionrobotics/thunderstorm/frontend";
import {OnRequestListener} from "@intuitionrobotics/thunderstorm";
import {PackageManagerModule, RequestKey_FetchProducts} from "@modules/package-manager/PackageManagerModule";
import {RequestKey_FetchUnitsList} from "@modules/UnitsModule";
import {__stringify, _keys, toggleElementInArray} from "@intuitionrobotics/ts-common";
import {itemHover, itemPicked, NodeProps, Tree} from "../../../playground/Tree";
import Renderer_Loader from "@renderers/Renderer_Loader";
import {Button, Icon} from "@mui/material";
import DragnDrop, {_FilesType} from "../package-manager/components/Drag&Drop";
import {OverridesModule, RequestKey_FetchOverride, RequestKey_SetOverride} from "@modules/OverridesModule";
import {Response_GetOverride} from "@app-sp/app-shared/units";
import {PermissionsComponent} from "@intuitionrobotics/permissions/frontend";
import {Unit} from "@app/ir-q-app-common/types/units";
import {Url_SupportAdmin} from "../../routes";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import {createFilterPattern} from "@components/table/consts";
import {RenderSelectUnit} from "../../renderers/Renderer_renderSelectUnit";
import {Elliq_ProductKey} from "@app/ir-q-app-common/shared/consts";
import {UnitContext} from "../../App";

// const icon__add = require('@res/images/icon__add.png');

const breakingWord = emotion.css`
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    max-width: 35vw;
`;

const plusSign = emotion.css`
      border-radius: 20px;
      border: 2px dashed #eee;
      width: 200px;
      height: 200px;
      position: relative;
      &::after {
          content: " ";
          position: absolute;
          display: block;
          background-color: #eee;
          height: 2px;
          margin-top: -5px;
          top: 50%;
          left: 50px;
          right: 50px;
          z-index: 9;
       }
      &::before {
          content: " ";
          position: absolute;
          display: block;
          background-color: #eee;
          width: 2px;
          margin-left: -5px;
          left: 50%;
          top: 50px;
          bottom: 50px;
          z-index: 9;
      }
`;

type State = {
    product?: string
    unitId?: string
    newOverrides?: object
    path?: string
    loading: boolean,
    error?: string
    filter: string
    filterPattern: RegExp
    batch: boolean
    unitsSelected: string[]
}

export class Page_Overrides
    extends BaseComponent<{}, State>
    implements OnRequestListener {

    constructor(props: {}) {
        super(props);
        this.state = {
            loading: false,
            batch: false,
            unitsSelected: [],
            filter: "",
            filterPattern: /.*?/
        };
    }

    private inputRef = React.createRef<HTMLInputElement>();

    __onRequestCompleted = (requestKey: string) => {
        switch (requestKey) {
            case RequestKey_FetchProducts:
            case RequestKey_FetchUnitsList:
            case RequestKey_FetchOverride:
            case RequestKey_SetOverride:
                this.setState({loading: false});
        }
    };

    render() {
        return <UnitContext.Consumer>
            {units => <div className={`ll_v_c`} style={{padding: 20}}>
                <div className={`ll_h_c`}>
                    {this.renderBatch()}
                </div>
                {this.state.batch ? this.renderBatchContent([...Array.from(units.pairedUnits), ...Array.from(units.activatedUnits)]) : this.renderSingleOverride()}
                {Renderer_Loader(this.state.loading)}
            </div>}
        </UnitContext.Consumer>;
    }

    private renderSingleOverride = () => {
        return <>
            <div className={`ll_h_c`}>
                {this.renderSelectProduct()}
                <RenderSelectUnit
                    product={this.state.product}
                    unitId={this.state.unitId}
                    onUnitSelected={this.onUnitSelected}
                    style={{margin: "20px"}}/>
            </div>
            {this.renderBody()}
        </>;
    };

    private renderBody = () => {
        let overrides: Response_GetOverride | undefined = undefined;
        if (this.state.unitId)
            overrides = OverridesModule.getOverride(this.state.unitId);

        return this.renderOverrides(overrides);
    };

    private renderOverrides = (overrides?: Response_GetOverride) => {
        if (!overrides)
            return;

        return <div className={`ll_h_c match_width`}>
            <div className={`ll_v_c match_width`}>
                {Page_Overrides.renderOverride(overrides.overrides)}
                {this.renderDownload(overrides)}
            </div>
            <div className={`ll_v_c match_width`}>
                {this.renderDragAndDrop()}
                {this.renderSave()}
            </div>
        </div>;
    };

    private renderSelectProduct = () => {
        const products = PackageManagerModule.getProducts();
        const product = this.state.product;
        const options = Object.keys(products).map(_product => ({value: _product, label: _product}));
        return <span style={{width: 200}}><GenericSelect<{ value?: string, label?: string }>
            selectedOption={{value: product, label: product}}
            options={options}
            placeholder="Select Product"
            onChange={this.onProductSelected}
            iconClose={"D"}
            iconOpen={"U"}
            styles={{}}
            presentation={option => option.label || ''}
        /></span>
    };


    private renderBatch = () => {
        const options = [
            {value: false, label: "single"},
            {value: true, label: "batch"}
        ];
        const selected = options.find(o => o.value === this.state.batch);
        return <PermissionsComponent
            url={Url_SupportAdmin}
            loadingComponent={() => <></>}
        >
			<span style={{width: 200}}><GenericSelect<{ value: boolean, label: string }>
                selectedOption={selected}
                options={options}
                placeholder="Select batch or single"
                onChange={this.onBatchSelected}
                iconClose={"D"}
                iconOpen={"U"}
                styles={{}}
                presentation={option => option.label}
            /></span>
        </PermissionsComponent>;
    };

    // private renderSelectUnit = () => {
    // 	const units = UnitsModule.getIdentities();
    // 	if (!units)
    // 		return;
    // 	const product = this.state.product;
    // 	const unitId = this.state.unitId;
    // 	const filteredByProduct = units.filter(unit => unit.product === product);
    // 	const options = filteredByProduct.map(unit => ({value: unit.unitId, label: unit.unitId}));
    // 	return <div style={{margin: "20px"}}>
    // 		<CustomSelect
    // 			value={{value: unitId, label: unitId}}
    // 			options={options}
    // 			isSearchable={true}
    // 			placeholder="Select a unit"
    // 			onChange={this.onUnitSelected}
    // 		/>
    // 	</div>;
    // };

    private validateFiles = (files: File[]): _FilesType => {
        const failReply = {
            accepted: [] as File[],
            rejected: files,
            uploaded: [] as File[]
        };
        if (files.length === 0) {
            ToastModule.toastError("no files");
            return failReply;
        }
        if (files.length > 1) {
            ToastModule.toastError("more than one file");
            return failReply;
        }
        const file = files[0];
        if (!file.name.endsWith(".json")) {
            ToastModule.toastError("file is not a json");
            return failReply;
        }

        return {
            accepted: files,
            rejected: [] as File[],
            uploaded: [] as File[]
        };
    };

    private validateFile(fileContent: string) {
        try {
            const obj = JSON.parse(fileContent);
            this.setState({
                newOverrides: obj,
                error: undefined
            });
        } catch (e) {
            ToastModule.toastError("could not parse json");
            this.setState({
                newOverrides: undefined,
                error: "could not parse json"
            });
        }
    }

    static renderOverride(overrides?: object) {
        if (!overrides)
            return;

        return <div style={{margin: "20px", width: "100%"}}>
            <Tree
                id="tree"
                renderer={MyTreeRenderer}
                root={overrides}
                hideRootElement={true}
                nodesState={Tree.recursivelyExpand(overrides, (key: string, value: any, level: number) => {
                    return level < 2;
                })}
            />
        </div>;
    }

    private renderDownload(overrideData: Response_GetOverride) {
        return <Button onClick={() => Page_Overrides.downloadPressed(overrideData.overrides)}>
            Download
        </Button>;
    }

    private renderSave = () => {
        return <Button onClick={() => this.savePressed()}>
            Save
        </Button>;
    };

    private renderDragAndDrop = () => <DragnDrop
        validate={this.validateFiles}
        error={undefined}
        onChange={this.onDragAndDrop}
        height={"unset"}
    >
        <div
            onClick={() => this.inputRef.current?.click()}
            className={"ll_h_c match_height"}
            style={{justifyContent: "center"}}
        >
            {!this.state.newOverrides ? <div className={plusSign}>
                <input id="fileInput" type="file" ref={this.inputRef} hidden={true} onChange={this.onSelect}/>
            </div> : Page_Overrides.renderOverride(this.state.newOverrides)}
        </div>
    </DragnDrop>;

    private onSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const files: _FilesType = this.validateFiles(Object.values(e.target.files));
            this.onDragAndDrop(files);
        }
    };

    private onProductSelected = (value: { value?: string, label?: string }) => {
        this.setState({product: value.value});
    };

    private onBatchSelected = (value: { value: boolean, label: string }) => {
        this.setState({batch: value.value});
    };

    private onUnitSelected = (unitId: string) => {
        this.setState({
            unitId: unitId,
            loading: true
        });
        if (!OverridesModule.getOverride(unitId))
            OverridesModule.fetchOverride(unitId, this.state.product);
    };

    private static downloadPressed(overrides?: object) {
        if (!overrides)
            return;

        const pom = document.createElement("a");
        pom.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(__stringify(overrides, true)));
        pom.setAttribute("download", "overrides.json");

        if (document.createEvent) {
            const event = document.createEvent("MouseEvents");
            event.initEvent("click", true, true);
            pom.dispatchEvent(event);
        } else {
            pom.click();
        }
    }

    private savePressed = () => {
        if (!this.state.newOverrides)
            return;

        const units: Unit[] = [];
        let _path: undefined | string = undefined;
        if (this.state.batch) {
            if (this.state.unitsSelected.length === 0)
                return ToastModule.toastInfo("Select some units");

            if (!this.state.path)
                return ToastModule.toastInfo("Please select a path under which to save the data");

            _path = this.state.path;
            this.state.unitsSelected.forEach(u => {
                units.push({unitId: u, product: Elliq_ProductKey});
            });
        } else {
            const unitId = this.state.unitId;
            const product = this.state.product;
            if (!unitId || !product)
                return;

            units.push({unitId, product});
        }

        this.setState({loading: true});
        OverridesModule.saveOverrides(units, this.state.newOverrides, _path);
    };

    private onDragAndDrop = (files: _FilesType) => {
        const reader = new FileReader();

        reader.onload = (e) => {
            if (e.target && e.target.result) {
                const result: string | ArrayBuffer = e.target.result;
                this.validateFile(result as string);
            }
        };
        reader.onerror = () => {
        };
        reader.readAsText(files.accepted[0]);
    };

    private renderBatchContent = (units: string[]) => {
        return <div style={{width: "100%"}}>
            {this.renderSearchFilter()}
            {this.renderUnitsSelect(units)}
            {this.renderPathInput()}
            {this.renderDragAndDrop()}
            {this.renderSave()}
        </div>;
    };

    private renderSearchFilter = () => <Paper className="ll_h_c" style={{height: "2rem", marginBottom: 10}}
                                              elevation={1}>
        <InputBase
            onChange={event => {
                const rawFilter = event.target.value.toLowerCase();
                this.setState({filter: rawFilter, filterPattern: createFilterPattern(rawFilter)});
            }}
            value={this.state.filter}
            id={"search_unit"}
            style={{marginLeft: 8, width: "100%", fontSize: "100%"}}
            placeholder="Filter"/>
        <Icon aria-label="Search" style={{paddingRight: "0.5rem"}}>
            <SearchIcon/>
        </Icon>
    </Paper>;

    private renderUnitsSelect = (_units: string[]) => {
        const units = _units?.filter(unit => this.state.filterPattern.test(unit));

        return <TS_CheckboxField<string>
            value={this.state.unitsSelected}
            horizontal={true}
            gridColumns={5}
            onCheck={(value, checked) => {
                this.setState(state => {
                    const unitsSelected = state.unitsSelected;
                    toggleElementInArray(unitsSelected, value);
                    return state;
                });
            }}
            options={units?.map(u => ({value: u})) || []}
            label={option => option.value}
        />;
    };

    private renderPathInput() {
        return <TS_Input
            style={{border: "1px solid"}}
            onChange={(value, id) => {
                this.setState({path: value});
            }}
            placeholder={"Enter a path"}
            type={"text"}
            id={"path"}
        />;
    }
}

export const MyTreeRenderer = (props: NodeProps) => {

    function renderCollapse() {
        let toDisplay: string;
        if (typeof props.node !== "object")
            toDisplay = "";
        else if (Object.keys(props.node).length === 0)
            toDisplay = "";
        else if (_keys(props.node).filter((key) => props.filter((props.node as object), key)).length === 0)
            toDisplay = "";
        else if (props.expanded)
            toDisplay = "-";
        else
            toDisplay = "+";

        return <div className={`${itemHover} clickable`} id={props.path} onClick={props.expandToggler}
                    style={{width: "15px"}}>{toDisplay}</div>;
    }

    let label;
    if (typeof props.node !== "object")
        label = ` : ${props.node}`;
    else if (Object.keys(props.node).length === 0)
        label = " : {}";
    else
        label = "";
    return (<div className="ll_h_c">
        {renderCollapse()}
        <div
            className={`${itemHover} ${itemPicked} ${breakingWord} clickable`}
            tabIndex={1} id={props.path}
            onClick={props.onClick} onDoubleClick={props.onDoubleClick}>{props.name || "root"} {label}
        </div>
    </div>);
};
