import {
	DialogButton_Cancel,
	DialogButton_Close,
	DialogButton_Submit,
	DialogModule
} from "@intuitionrobotics/thunderstorm/frontend";

// const icon__warning = require('@res/images/icon__warning.svg');

// const variables = require('@styles/variables.scss');
// const overlayBG = variables.darkOverlay;
// const titleStyle: CSS.Properties = {
// 	color: "rgba(0, 0, 0, 0.87)",
// 	fontSize: "1.25rem",
// 	fontWeight: 500,
// 	lineHeight: 1.6,
// 	letterSpacing: "0.0075em",
// 	padding: "5px 14px 0px"
// };
// const contentStyle: CSS.Properties = {
// 	color: variables.COLOR_textGrayer,
// 	padding: "24px 18px 0"
// };
// const actionsStyle: CSS.Properties = {
// 	backgroundColor: variables.COLOR_veryLightBackgroundGray,
// 	boxSizing: "border-box",
// 	width: "100%",
// 	height: "42px",
// 	padding: "0px 12px"
// };
// const buttonStyle = emotion.css`{
//     border-radius: 4px;
//     color: ${variables.COLOR_textWhite};
//     font-size: 11px;
//   letter-spacing: -0.18px;
//   margin: 0 6px;
//     height: 23px;
//     width: 68px;
// }`;
// const submitStyle = emotion.css`{
//     background-color: ${variables.COLOR_lightBlue};
//     :active {
//         background-color: ${variables.COLOR_blue};
//     }
//
// }`;
// const cancelStyle = emotion.css`{
//     background-color: ${variables.COLOR_gray};
// }`;
// export const createActionDialog = (content: React.ReactNode, onSubmit: () => void, title?: string, width?: number | string, height?: number | string) => {
// };

export function closeButton(label?: string, id?: string) {
	return DialogButton_Close(() => DialogModule.close(id), label)
		.setClassName("clickable")
		.addStyle({padding: "5px 0px", margin: "10px 6px", textAlign: "center", height: "unset"});
}

//
// export function iconAndText(text: string, configToInstall: PackageConfigFe) {
// 	// DEBUG PRINT
// 	console.log(configToInstall);
//
// 	if (configToInstall.deviceConfig.length < 2) {
// 		return <div className={`ll_v_c match_width`} style={{justifyContent: "space-evenly", paddingRight: 10}}>
// 			<img src={icon__warning} alt="warning" style={{padding: 10}}/>
// 			<div>No config assigned</div>
// 		</div>;
// 	}
//
// 	return <div className={`ll_v_c match_width`} style={{justifyContent: "space-evenly", paddingRight: 10}}>
// 		<img src={icon__warning} alt="warning" style={{padding: 10}}/>
// 		<div><b>About to install Config:</b></div>
// 		<div>{configToInstall.configName}</div>
// 		<br/>
// 		<div><b>{configToInstall.deviceConfig[0].deviceType}</b></div>
// 		{configToInstall.deviceConfig[0]?.artifactList?.map((artifact) =>
// 			                                                    <div key={artifact.packageName}>
// 				                                                    {splitPackageName(artifact.packageName)} {artifact.versionName}
// 			                                                    </div>
// 		)}
// 		<br/>
// 		<div><b>{configToInstall.deviceConfig[1].deviceType}</b></div>
// 		{configToInstall.deviceConfig[1]?.artifactList?.map((artifact) =>
// 			                                                    <div key={artifact.packageName}>
// 				                                                    {splitPackageName(artifact.packageName)} {artifact.versionName}
// 			                                                    </div>
// 		)}
// 		<br/>
// 		<div><b>{text}</b></div>
// 	</div>;
// }
//
// function splitPackageName(packageName: string) {
// 	const name = packageName.split(".");
// 	return name[name.length - 1];
// }

export function confirmationButtons(onSubmit: () => void, label?: string, onCancel?: () => void, id?: string) {
	const cancel = DialogButton_Cancel(() => {
		DialogModule.close(id);
		if (onCancel)
			onCancel();
	})
		.addStyle({border: "none", cursor: "pointer"});
	const submit = DialogButton_Submit(onSubmit, label)
		.addStyle({border: "none", cursor: "pointer"});
	return [cancel,
	        submit];
}

export function confirmationButtonsNoConfig() {
	const cancel = DialogButton_Cancel(DialogModule.close);
	return [cancel];
}
