import * as React from "react";
import {css} from "emotion";
import {_keys} from "@intuitionrobotics/ts-common";
import {TS_CheckboxField, TS_Input} from "@intuitionrobotics/thunderstorm/frontend";
import {fieldSetStyle} from "../preinstalls/Page_Preinstalls";

export enum TimeUnits {
    Milliseconds = 1,
    Seconds = 1000,
    Minutes = 60000,
    Hours = 3600000
}

const labelClass = css({
    fontSize: "85%",
    marginRight: 8,
    marginLeft: -8
});

const inputClass = css`{
  border: 1px solid;
  border-radius: 5px;
  width: 39px;
  margin-right: 14px;
  padding: 2px 3px;
}`;

type TimeUnitsOption = {value: TimeUnits}

type Props = {
    ttl?: number,
    onTTLChange: (ttl: number) => void
}

type State = {
    timeUnit: TimeUnits,
}



export class Component_SetTTL
    extends React.Component<Props, State> {

   state = {
        timeUnit: TimeUnits.Milliseconds
    }

    renderRadio = () => {
        const values = _keys(TimeUnits).reduce((carry: TimeUnitsOption[], key) => {
            if (!(parseInt(key) >= 0))
                carry.push({value: TimeUnits[key]})
            return carry;
        }, []);
        return <TS_CheckboxField
            options={values}
            value={this.state.timeUnit}
            label={value => <div className={labelClass}>{TimeUnits[value.value]}</div>}
            onCheck={timeUnit => {
                if (this.props.ttl)
                    this.props.onTTLChange(timeUnit * this.props.ttl / this.state.timeUnit)
                this.setState({timeUnit})
            }}
            circle
            horizontal
        />
    }

    render() {
       return <fieldset className={fieldSetStyle}>
           <legend>Set <strong>T</strong>ime <strong>T</strong>o <strong>L</strong>eave (optional)</legend>
           <div className="ll_h_c">
               <TS_Input
                   id={"amountOfTime"}
                   type={"number"}
                   className={inputClass}
                   value={this.props.ttl ?  `${this.props.ttl / this.state.timeUnit}` : ""}
                   onChange={value => this.props.onTTLChange(+value * this.state.timeUnit)}
               />
               {this.renderRadio()}
           </div>
       </fieldset>;
    }
}
