import * as React from "react";
import * as emotion from "emotion";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {OnRequestListener} from "@intuitionrobotics/thunderstorm";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Renderer_Loader from "@renderers/Renderer_Loader";
import {FormControl, IconButton} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import {CustomSnackBarProps} from "@components/CustomSnackBar";
import {marginLeft, marginTop} from "@styles/dynamic-styles";
import {PermissionsModule, RequestKey_ChangedPermissions} from "@modules/PermissionsModule";
import {addItemToArray, createFilterPattern, removeItemFromArray, sortArray} from "@intuitionrobotics/ts-common";
import {COLOR_lightGray} from "@styles/colors";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import Renderer_Toaster from "@renderers/Renderer_Toaster";
import {SortOrder} from "@components/table/consts";
import {RequestKey_FetchUnitsList} from "@modules/UnitsModule";
import {Client_PermissionsGroup, PermissionAccessLevel_Permissions, PermissionKey_Permissions, UserToGroup} from "@app/ir-q-app-common/types/permissions";
import {checkPermissions} from "../../routes";

export const permissionIcons = {
    trash: require("@res/images/icon__trash.svg"),
    help: require("@res/images/icon__help.png"),
    x: require("@res/images/icon__x.svg")
};

const headerStyle = emotion.css({
    backgroundColor: "gainsboro",
    color: "000",
    position: "sticky",
    top: 0
})

type State_Permissions = {
    sortOrder: SortOrder
    sortBy?: string,
    groupName?: string,
    filter: string,
    filterPattern: string,
    error?: CustomSnackBarProps,
    loading: boolean,
}

export default class Tab_UsersToGroup
    extends BaseComponent<{}, State_Permissions>
    implements OnRequestListener {

    constructor(props: any) {
        super(props);
        this.state = {
            filter: "",
            filterPattern: createFilterPattern(""),
            error: undefined,
            loading: true,
            sortOrder: SortOrder.DESC
        };

        this.onFilterValueChange = this.onFilterValueChange.bind(this);
        this.onSortClick = this.onSortClick.bind(this);
        this.renderRow = this.renderRow.bind(this);
    }

    componentDidMount(): void {
        PermissionsModule.fetchPermissions();
    }

    __onRequestCompleted = (key: string) => {
        switch (key) {
            default:
                return;

            case RequestKey_FetchUnitsList:
            case RequestKey_ChangedPermissions:
                this.setState({loading: false});
        }
    };

    onFilterValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState(
            {
                filter: event.target.value,
                filterPattern: createFilterPattern(event.target.value)
            });
    };

    onSortClick(e: React.MouseEvent) {
        const newOrder = this.state.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
        this.setState(
            {
                sortBy: BaseComponent.getElementId(e),
                sortOrder: newOrder
            }
        );
    }

    render() {
        this.logWarning(`loading: ${this.state.loading}`);
        return (
            <div style={{marginLeft: 30, marginRight: 30}}>
                <div className="match_height match_width">
                    <div className={`ll_v_c ${marginTop(20)}`}>
                        <Paper elevation={1}>
                            <InputBase
                                onChange={this.onFilterValueChange}
                                value={this.state.filter || ""}
                                id="unit-filter"
                                style={{marginLeft: 8}}
                                placeholder="Group name"/>

                            <IconButton aria-label="Search"> <SearchIcon/> </IconButton>
                        </Paper>
                    </div>
                    {Renderer_Loader(this.state.loading)}
                    {!this.state.loading && this.renderTable()}
                    {Renderer_Toaster(this.state.error)}
                </div>
            </div>
        );
    }

    renderUserGroupIds = (user: UserToGroup) => {
        const groupIds = user.groupIds;
        if (!groupIds)
            return "";

        return groupIds.map(groupId => {
            const perms = PermissionsModule.getPermissionGroup(groupId);
            return (<div className="ll_h_c" key={groupId}>
                {perms.name}
                <img
                    className={`clickable`}
                    style={{width: "14px", height: "14px", marginRight: "8px"}}
                    src={permissionIcons.help}
                />
                {
                    checkPermissions(PermissionAccessLevel_Permissions,
                        PermissionAccessLevel_Permissions.WriteUser,
                        PermissionKey_Permissions)() &&
                    <img
                        className={`clickable`}
                        style={{width: "14px", height: "14px", marginRight: "8px"}}
                        src={permissionIcons.trash} onClick={() => {
                        if (!user.groupIds)
                            user.groupIds = [];

                        const _user = {...user};
                        _user.groupIds = [...user.groupIds];
                        removeItemFromArray(_user.groupIds, groupId);
                        PermissionsModule.updateUserGroup(_user);
                    }}
                    />
                }
            </div>);
        });
    };

    renderRow = (user: UserToGroup) => {
        const email = user.email;
        const groups = PermissionsModule.getPermissionGroups();

        const hasWriteAccess = checkPermissions(PermissionAccessLevel_Permissions,
            PermissionAccessLevel_Permissions.WriteUser,
            PermissionKey_Permissions)();

        return (
            <TableRow key={user.email} hover={true} style={{background: COLOR_lightGray}}>
                <TableCell key="email" component="th" scope="row">{email}</TableCell>

                <TableCell key="user-groups" component="th" scope="row">
                    {this.renderUserGroupIds(user)}
                </TableCell>

                <TableCell key="group" component="th" scope="row">
                    <FormControl style={{minWidth: 130, margin: "5px"}}>
                        <Select
                            value={""}
                            onChange={(event) => {
                                const groupId = event.target.value;
                                if (!user.groupIds)
                                    user.groupIds = [];

                                const _user = {...user};
                                _user.groupIds = [...user.groupIds];
                                if (_user.groupIds.indexOf(groupId) === -1) {
                                    addItemToArray(_user.groupIds, groupId);
                                }

                                PermissionsModule.updateUserGroup(_user);
                            }}
                            input={<Input name="group-name"/>}
                            disabled={!hasWriteAccess}
                        >
                            {Object.values(groups).map((_group: Client_PermissionsGroup) => <MenuItem key={_group.id} value={_group.id}>{_group.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </TableCell>
            </TableRow>
        );
    };

    private renderTable() {
        const filterRegexp = new RegExp(this.state.filterPattern || ".*");
        const usersToGroup = PermissionsModule.getUserGroups();
        const users = Object.keys(usersToGroup).filter((email) => filterRegexp.test(email)).map(email => usersToGroup[email]);
        let sortedUsers = sortArray(users, (item: UserToGroup) => item.email);
        if (this.state.sortOrder === SortOrder.DESC)
            sortedUsers = sortedUsers.reverse();

        sortedUsers = sortedUsers.slice(0, 20);
        return <>
            <div className="ll_h_c">
                <div style={{margin: 20}}>{sortedUsers.length}Groups</div>
            </div>

            <div style={{maxHeight: "calc(100vh - 196px)", overflow: "auto"}}><Table>
                <TableHead>
                    <TableRow>
                        <TableCell key="email" align="left" className={headerStyle}> User </TableCell>
                        <TableCell key="user-groups" align="left" className={headerStyle}>
                            <div className="ll_h_c">
                                <div className={marginLeft(4)}>User Groups</div>
                            </div>
                        </TableCell>
                        <TableCell key="group" align="left" className={headerStyle}>
                            <div className="ll_h_c">
                                <div className={marginLeft(4)}>Group</div>
                            </div>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{sortedUsers.map(this.renderRow)}</TableBody>
            </Table></div>
        </>;
    }
}
