import {Module} from "@intuitionrobotics/ts-common";
import {AccountModule, LoggedStatus, OnLoginStatusUpdated, StorageKey_JWT, StorageKey_UserEmail} from "@intuitionrobotics/user-account/frontend";
import {UnitsModule} from "@modules/UnitsModule";
import {QueryParam_Email, QueryParam_JWT} from "@intuitionrobotics/user-account/shared/api";

class AppModule_Class
    extends Module
    implements OnLoginStatusUpdated {

    onLoginStatusUpdated() {
        if (AccountModule.getLoggedStatus() !== LoggedStatus.LOGGED_IN)
            return;

        UnitsModule.fetchPairedUnitsImpl();
    }

    composeUrlWithAuth(url: string) {
        const userEmail = StorageKey_UserEmail.get();
        const jwt = StorageKey_JWT.get();
        if (!userEmail || !jwt)
            return;

        const jwtParam = `${QueryParam_JWT}=${jwt}`;
        const userEmailParam = `${QueryParam_Email}=${userEmail}`;
        const s = url.includes("?") ? "&" : `?`;
        return `${url}${s}${userEmailParam}&${jwtParam}`;
    }

}

export const AppModule = new AppModule_Class("AppModule");
