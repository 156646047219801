import * as React from 'react';
import {useState} from 'react';
import Conversation from "./Conversation";
import {RenderSelectUnit} from "../../renderers/Renderer_renderSelectUnit";

export const Page_Convo = (props: any) => {
    const u = new URLSearchParams(props.location.search).get("unitId")
    const [unitId, setUnitId] = useState(u || "")
    const [submitted, setSubmitted] = useState(!!u)
    if (!submitted || !unitId)
        return (
            <div style={{display: "flex", justifyContent: 'center'}}>
                <RenderSelectUnit
                    product={'elliq'}
                    unitId={"Select a unit to start"}
                    onUnitSelected={(value: string) => {
                        setUnitId(value)
                        setSubmitted(true)
                    }}
                    style={{paddingLeft: 10}}/>
            </div>
        )
    return <Conversation unitId={unitId}/>
}