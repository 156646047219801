import {AppPage, BrowserHistoryModule, Dialog_Builder, DialogModule, RoutingModule, ToastModule, TS_Checkbox} from "@intuitionrobotics/thunderstorm/frontend";
import {OnRequestListener} from "@intuitionrobotics/thunderstorm";
import * as React from "react";
import {css} from "emotion";
import Panel_DeviceStatus from "./Panel_DeviceStatus";
import {Product, QueryParam_Product, QueryParam_UnitId} from "@consts/common";
import {OnUnitInfoReturned, RequestKey_FetchUnitsFullList, RequestKey_FetchUnitsOnboardingTimes, RequestKey_QueryUnitMetadata, UnitsModule} from "@modules/UnitsModule";
import {CustomSnackBarProps} from "@components/CustomSnackBar";
import {Button, Tooltip} from "@mui/material";
import {InfoRenderer, Renderers_UnitInfo} from "@renderers/unit-info";
import {backgroundColor, margin, padding} from "@styles/dynamic-styles";
import {checkPermissions, Route_UnitRepairing, Url_UpdateEnv} from "../../routes";
import {NavigateButton} from "./components/NavigateButton";
import {DeviceType, Unit, UnitId} from "@app/ir-q-app-common/types/units";
import {RequestKey_UnpairUnit, RequestKey_UpdateEnv} from "@modules/KasperModule";
import {PermissionAccessLevel_Pairing, PermissionAccessLevel_PmDeploy, PermissionKey_Pairing, PermissionKey_PmDeploy} from "@app/ir-q-app-common/types/legacy/sp-permissions";
import {
    OnConfigsListFailed,
    OnUnload,
    PackageManagerModule,
    RequestKey_AssignTo,
    RequestKey_FetchAliases,
    RequestKey_FetchConfigs,
    RequestKey_FetchDeviceConfigFe,
    RequestKey_FetchProductsCollection,
    RequestKey_FetchUnits,
    RequestKey_PM_InstallNow,
    UiPackageConfig
} from "@modules/package-manager/PackageManagerModule";
import {_keys, _values, filterInstances, sortArray, StringMap} from "@intuitionrobotics/ts-common";
import {getPMUnitConfig, icon__chat, icon__unpair} from "./utils";
import {PermissionsComponent} from "@intuitionrobotics/permissions/frontend";
import {DB_PushMessageAndEnv} from "@app/ir-q-app-common/types/fb-push-messages";
import {Alias, PmStatus, Request_AssignTo} from "@app-sp/app-shared/package-manager";
import {PushCommandsTable} from "./components/PushCommandsTable";
import {BaseRuntimeStatus} from "@app/ir-q-app-common/types/runtime-status";
import {Dialog_ShowOverrides} from "./Dialog_ShowOverrides";
import {closeButton} from "@components/AppDialog";
import {BlinkingButton} from "../../components/BlinkingButton";
import {Page_Unit_Config} from "./Page_Unit_Config";
import {Page_Unit_Gpt_Conversation} from "./Page_Unit_Gpt_Conversatiion";
import {Page_Unit_Facts} from "./Page_Unit_Facts";
import {SimpleLoader} from "@components/SimpleLoader";
import {actionDialogGenerator, OnPushListReturned, PushMessagesModule, RequestKey_PushMessage} from "@modules/PushMessagesModule";
import {Component_PushMessagesDialog} from "./components/Dialog_PushMessages";
import {Page_Contacts_iframe} from "../contacts/Page_Contacts_iframe";
import {FirebaseListenerModule, OnPairsRetrieved} from "@modules/FirebaseListenerModule";
import {CopyButton} from "../../renderers/CopyButton";
import {Elliq_ProductKey} from "@app/ir-q-app-common/shared/consts";
import {PushCommand} from "@app/ir-q-app-common/types/push-commands";
import {UnitsManagerModule} from "@modules/UnitsManagerModule";
import Component_GeneralComment from "./components/Component_GeneralComment";
import {ActivationsModule, RequestKey_DeleteActivations, RequestKey_ListActivations} from "@modules/ActivationsModule";
import {ConfirmationModule} from "@modules/confirmation-modal-module";
import {getCurrentUserEmail} from "../../App";
import {ContactListModule} from "@modules/ContactListModule";
import {Dialog_SetUserGroup} from "./components/Dialog_SetUserGroup";
import {CareCenterModule} from "@modules/CareCenterModule";
import Conversation from "../convo/Conversation";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import {StorageKey_JWT} from "@intuitionrobotics/user-account/frontend";

const icon_firebase = require("@res/images/icon__firebase.png");
const gcp = require("@res/images/gcp.svg");
const icon__jira = require("@res/images/icon__jira.png");
const icon__logzio = require("@res/images/icon__logzio.png");
const icon__joystick = require("@res/images/joystick.svg");
export const icon__upload = require("@res/images/icon__upload.svg");

const mainInfoStyle = css({
    padding: 5,
    color: "black"
});

export enum UnitTab {
    Status = 'Status',
    UnitConfig = 'Unit Config',
    PushTable = 'Push Table',
    Contacts = "Contacts",
    GptChat = "IR LLM Chat",
    UnitFacts = "Facts"
}

interface Page_Unit_Monitoring_State {
    loading: boolean;
    error?: CustomSnackBarProps;
    success?: string;
    timeStamp?: string;
    userGroup: string;
    comment?: string;
    isPage: boolean;
    selectedTab: UnitTab,
    treatAsPairUnit: boolean,
    treatAsActivateUnit: boolean
    drawerOpen: boolean
    initStateRecording: boolean
}

const {
    AndroidVersion,
    Battery,
    SpeechServerConnection,
    CradleState,
    HWStatus,
    HWStatusOfOtherData,
    Kasper,
    Boot,
    Restart,
    TimesTitle,
    OtherTitle,
    Onboarding,
    ListeningStatusOrScreenBrightness,
    MacAddresses,
    SpeedTestResult,
    MemoryAndThreads,
    SystemErrors,
    UnitErrorCodes,
    Timestamp,
    Version,
    OriginalVersion,
    Wifi,
    VersionsTitle,
    ConnectivityTitle,
    ElliqAppTitle,
    StricklandState,
    Serial
} = Renderers_UnitInfo;
export const infoRenderers = (deviceType: DeviceType, unitId: string): InfoRenderer[] => filterInstances([
    // (deviceType === devices.som) ? SomStatus : TabletStatus,

    Timestamp,

    OriginalVersion,

    UnitErrorCodes,
    StricklandState,
    Wifi,
    (!UnitsManagerModule.is3Point0Unit(unitId) && deviceType === devices.tablet) ? Battery : undefined,
    CradleState,
    Serial
]);

export const secondaryInfoRenderers = (deviceType: DeviceType, unitId: string): InfoRenderer[] => filterInstances([
    TimesTitle,
    Boot,
    Restart,
    Onboarding,
    VersionsTitle,
    AndroidVersion,
    UnitsManagerModule.isUnitActivation(unitId) ? undefined : Kasper,
    Version,
    HWStatus,
    ConnectivityTitle,
    MacAddresses,
    SpeedTestResult,
    SpeechServerConnection,
    UnitsManagerModule.is3Point0Unit(unitId) ? ElliqAppTitle : undefined,
    UnitsManagerModule.is3Point0Unit(unitId) ? HWStatusOfOtherData : undefined,
    OtherTitle,
    MemoryAndThreads,
    // UnitsManagerModule.isUnitActivation(unitId) ? undefined : MemoryAndThreads,
    // UnitsManagerModule.isUnitActivation(unitId) ? MemoryAndThreadsFor3point0units : undefined,
    // UnitsManagerModule.isUnitActivation(unitId) ? MemoryAndThreadsOfSom : undefined,
    ListeningStatusOrScreenBrightness,
    (deviceType === devices.som) ? SystemErrors : undefined
]);

export const devices: { [k in DeviceType]: DeviceType } = {
    som: "som",
    tablet: "tablet"
};

const PushMessageDialogTitle = "Push to All Devices";

export const renderPushMessageButton = (title?: string, label?: string, callback?: () => void) => {
    if (!checkPermissions(PermissionAccessLevel_PmDeploy, PermissionAccessLevel_PmDeploy.Installer, PermissionKey_PmDeploy)())
        return "";

    return <Button
        className={"unit_monitoring_btn"}
        style={{
            marginTop: label ? "-10px" : 0,
            textTransform: "none"
        }}
        size="small"
        variant="outlined"
        onClick={() => callback?.()}
    >
        {`${label || "Push Message"}`}
    </Button>;
};

export const renderResetPublicKeysButton = (unit: Unit, title: string, label: string) => {
    return <Button
        className={"unit_monitoring_btn"}
        style={{
            marginTop: "-10px",
            textTransform: "none"
        }}
        size="small"
        variant="outlined"
        onClick={() => {
            const params = {
                title: `Are you sure you want to Reset Public key of unit ${unit.unitId}?`,
                bodyText: "",
                onSubmit: () => {
                    const activation = ActivationsModule.getActivation(unit.unitId);
                    const agentUsers = ContactListModule.getItems();
                    const agentUser = agentUsers.find(item => item.contactType === "agentUser" && item.contactData.unitId === unit.unitId);
                    if (!activation)
                        return ToastModule.toastError(`Didn't find an activation for agent_id ${unit.unitId}`);

                    if (!agentUser)
                        return ToastModule.toastError(`Didn't find an agent user for agent_id ${unit.unitId}`);

                    const body = {
                        id: activation.id,
                        agent_id: activation.agent_id,
                        pincode: activation.pincode,
                        som_device_id: activation.som_device_id,
                        tablet_device_id: activation.tablet_device_id,
                        replacement_tablet_device_id: activation.replacement_tablet_device_id,
                        activated: activation.activated,
                        auth_type: activation.auth_type,
                        blame: getCurrentUserEmail(),
                        som_pk: null,
                        tablet_pk: null
                    };
                    ActivationsModule.update(body, agentUser.birthday, () => ToastModule.toastSuccess("Public keys were reset successfully"));
                },
                submitButtonText: "Yes",
                width: 600
            };

            return ConfirmationModule.confirmationDialog(params)
        }}
    >
        {`${label}`}
    </Button>;
};


const tabClass = (isActive: boolean) => css(
    {
        marginLeft: 5,
        marginRight: 5,
        padding: 4,
        fontSize: 17,
        backgroundColor: isActive ? '8080807d' : "white",
        color: "black",
        border: '1px solid',
        borderBottom: 'none',
        cursor: 'pointer'
    });

export type OpenDialog = "push message" | "install now";

export const ID_PUSH_DIALOG = "push-dialog";

export class Page_Unit_Monitoring
    extends AppPage<{}, Page_Unit_Monitoring_State>
    implements OnRequestListener, OnUnload, OnConfigsListFailed, OnUnitInfoReturned, OnPushListReturned, OnPairsRetrieved {
    private unitId: UnitId = "";
    private product: Product = "";
    private showLeftSide: boolean = localStorage.getItem("showLeftSide") === "1";
    private openDialog?: OpenDialog;

    constructor(props: {}) {
        super(props);
        this.state = {
            loading: true,
            isPage: false,
            timeStamp: "",
            userGroup: "",
            selectedTab: UnitTab.Status,
            treatAsPairUnit: false,
            treatAsActivateUnit: false,
            drawerOpen: false,
            initStateRecording: false
        };

        PackageManagerModule.fetchUnits();
        PackageManagerModule.fetchAliases();
        PackageManagerModule.fetchAppsList();
    }

    __onConfigsListError(message: string): void {
        ToastModule.toastError(message);
    }

    __onPushListReturned() {
        this.forceUpdate();
    }

    __onUnitInfoReturned() {
        this.forceUpdate();
    }

    __onConfigsListPermissionsRequired(message: string): void {
    }

    __onUnload(loading: boolean): void {
        this.setState({loading});
    }

    __onPairsRetrieved(): void {
        this.updatePairingOrActivationState();
    }

    updatePairingOrActivationState = (redo?: boolean) => {
        if (!redo && (this.state.treatAsPairUnit || this.state.treatAsActivateUnit))
            return this.forceUpdate();

        let pair = false, activation = false;
        const basic = UnitsManagerModule.getUnitBasic(this.unitId);
        if (basic?.activation) {
            activation = true;
            pair = false
        } else if (basic?.pair) {
            pair = true;
            activation = false;
        }

        if (pair || activation)
            this.setState({treatAsPairUnit: pair, treatAsActivateUnit: activation});
    }

    __onRequestCompleted = (key: string, success: boolean) => {
        const unit = {unitId: this.unitId, product: this.product};
        switch (key) {
            case RequestKey_FetchUnitsFullList:
                PushMessagesModule.fetchPushList(unit);
                this.forceUpdate();
                return;

            case RequestKey_PM_InstallNow:
            case RequestKey_PushMessage:
                DialogModule.close(ID_PUSH_DIALOG);
                return;
            case RequestKey_FetchDeviceConfigFe:
                this.requestFetchConfigFeCompleted();
                return;

            case RequestKey_FetchUnits:
            case RequestKey_FetchUnitsOnboardingTimes:
            case RequestKey_FetchAliases:
            case RequestKey_FetchConfigs:
                return this.forceUpdate()

            case RequestKey_AssignTo:
                return this.setState({loading: false})

            case RequestKey_UpdateEnv:
                return UnitsModule.fetchRuntimeStatusImpl(unit);

            case RequestKey_FetchProductsCollection:
            case RequestKey_QueryUnitMetadata:
                return this.forceUpdate();
            case RequestKey_DeleteActivations:
            case RequestKey_ListActivations:
                return this.updatePairingOrActivationState();
            case RequestKey_UnpairUnit:
                this.updatePairingOrActivationState();
                DialogModule.close();
                return;
        }

        this.setState({loading: false, userGroup: UnitsModule.getUnitUserGroup(this.unitId)});
    };

    componentDidMount(): void {
        super.componentDidMount();
        const urlSearchParams = new URLSearchParams(window.location.search);
        const elliqId = urlSearchParams.get(QueryParam_UnitId);
        if (!elliqId)
            return BrowserHistoryModule.setUrl(window.location.origin);
        this.unitId = elliqId;

        this.setPageTitle(this.unitId);

        this.product = urlSearchParams.get(QueryParam_Product) || Elliq_ProductKey;
        PackageManagerModule.fetchConfigs(this.product);
        this.getData();
        this.updatePairingOrActivationState();
        ContactListModule.query({contactType: "agentUser"});
        ActivationsModule.startAutomaticRefresh(this.unitId)
    }

    componentDidUpdate() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const elliqId = urlSearchParams.get(QueryParam_UnitId);
        const product = urlSearchParams.get(QueryParam_Product) || Elliq_ProductKey;
        if (!elliqId)
            return BrowserHistoryModule.setUrl(window.location.origin);

        this.product = product;

        if (this.unitId !== elliqId) {
            this.unitId = elliqId;
            this.setPageTitle(this.unitId);
            this.updatePairingOrActivationState(true);
            this.getData();
        }
    }

    private getData = () => {
        this.logDebug(`Component mounted for unit: ${this.unitId}, product: ${this.product}`);
        this.setState({loading: true});
        UnitsModule.cancelFetching();
        const unit = {unitId: this.unitId, product: this.product};
        this.startListen();
        UnitsModule.fetchOnboardingTimesImpl();
        PushMessagesModule.fetchPushList(unit);
        UnitsModule.queryUnitMetadata(unit);
    };

    __onVisibilityChange(visibilityState: DocumentVisibilityState) {
        switch (visibilityState) {
            case "hidden":
                this.stopListening();
                break;
            case "visible":
                this.startListen();
                ActivationsModule.list();
                ActivationsModule.startAutomaticRefresh(this.unitId)
                break;
            default:
                console.warn(`Something is wrong, visibility state should be hidden or visible but is ${visibilityState}`)
        }
    }

    startListen() {
        FirebaseListenerModule.listenImplForPM(() => this.forceUpdate(), this.unitId)
        FirebaseListenerModule.listenImplForRS(() => this.forceUpdate(), this.unitId)
    }

    stopListening() {
        FirebaseListenerModule.stopListening()
        ActivationsModule.stopAutomaticRefresh()
    }

    componentWillUnmount(): void {
        super.componentWillUnmount()
        this.stopListening();
        UnitsModule.cancelFetching();
    }

    render() {
        if (!this.unitId)
            return null;

        const runtimeStatus = UnitsModule.getRuntimeStatus(this.unitId);

        const repairUrlParams = {
            [QueryParam_Product]: this.product,
            [QueryParam_UnitId]: this.unitId
        };

        const env = UnitsModule.getEnv();
        const unitUrl = runtimeStatus ? `https://console.firebase.google.com/u/0/project/${env}/database/${env}/data/elliqs/${this.unitId}` : '';
        const jiraUrl = `https://introb.atlassian.net/issues/?jql=project%20%3D%20EAT%20and%20%22ElliQ%20Unit%20Name%22%20~%20${this.unitId}`;
        const accountId = (runtimeStatus?.som?.environment === 'Development' || runtimeStatus?.som?.environment === 'Staging') ? '157498' : `82755`;
        const logzioUrl = `https://app.logz.io/#/dashboard/osd/discover/?_a=(columns:!(message),filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'logzioCustomerIndex*',key:unitId,negate:!f,params:(query:${this.unitId}),type:phrase),query:(match_phrase:(unitId:${this.unitId})))),index:'logzioCustomerIndex*',interval:auto,query:(language:lucene,query:''),sort:!())&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-15m,to:now))&accountIds=${accountId}&switchToAccountId=${accountId}`;
        const pmStatus = UnitsModule.getPmStatus(this.unitId);
        return (
            <div id="unit-info" className="overflow-y ll_h_t match_width match_height"
                 style={runtimeStatus?.som?.text_service_enabled ? {background: "repeating-linear-gradient(45deg, transparent, transparent 10px, #ccc 10px, #ccc 20px),linear-gradient(to bottom,#eee,#999)"} : {}}>
                {this.renderUnitInfo(runtimeStatus, logzioUrl, jiraUrl, unitUrl, pmStatus, repairUrlParams)}
                {this.state.loading && <SimpleLoader overlay={true}/>}
                {this.renderDrawer(runtimeStatus)}
            </div>
        );
    }

    private renderDrawer(runtimeStatus?: BaseRuntimeStatus) {
        if (!this.state.initStateRecording)
            return;

        return <Drawer
            anchor={"left"}
            variant="temporary"
            open={this.state.drawerOpen}
            onClose={() => this.setState({drawerOpen: false})}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                display: 'block',
                '& .MuiDrawer-paper': {boxSizing: 'border-box'},
            }}
        >
            <Box sx={{textAlign: 'center'}}>
                <Conversation unitId={this.unitId} textEnabled={runtimeStatus?.som?.text_service_enabled}/>
            </Box>
        </Drawer>
    }

    private getBucketUrl({bucket, projectId}: { bucket: string, projectId: string }) {
        return `https://console.cloud.google.com/storage/browser/${bucket}/elliqs/${this.unitId}?project=${projectId}`;
    }

    private getStorageBucket(runtimeStatus: BaseRuntimeStatus | undefined): { bucket: string, projectId: string }[] {
        const env = runtimeStatus?.som?.environment?.toLowerCase();
        switch (env) {
            case 'development':
            case 'staging':
                return [{bucket: 'elliq-env-dev.appspot.com', projectId: 'elliq-env-dev'}];
            default:
                return [{bucket: 'elliq-logs', projectId: 'elliqlogs'}, {bucket: 'elliqprivatedata-2023', projectId: 'elliqPrivateData'}];
        }
    }

    private renderUnitInfo = (runtimeStatus: BaseRuntimeStatus | undefined, logzioUrl: string, jiraUrl: string, unitUrl: string, pmStatus: PmStatus[], repairUrlParams: StringMap) => {
        const userGroup = runtimeStatus?.som?.unit_config?.metadata?.label;
        const version = runtimeStatus?.som?.unit_config?.version;

        return <div
            id="unit-info-details"
            className={`ll_v_c match_width match_height flex-column`}
            style={{flex: 70}}
        >
            <div className={`ll_h_r match_width`} style={{justifyContent: "space-between"}}>
                {this.renderTabs(runtimeStatus)}
                {this.renderBlinkingText(runtimeStatus)}
                {this.renderPairingOrActivation()}
            </div>
            {this.renderInfoBar(logzioUrl, jiraUrl, unitUrl, runtimeStatus)}
            {/*START - SOM and Tablet info*/}
            <div className={'match_width match_height'} style={{overflow: "hidden"}}>
                {this.renderTab(pmStatus, runtimeStatus, repairUrlParams)}
                <Page_Contacts_iframe hidden={this.state.selectedTab !== UnitTab.Contacts} userGroup={userGroup} version={version}/>
            </div>

            {/*END - SOM and Tablet info*/}
        </div>
    };

    /*

     */

    private renderTab = (pmStatus: PmStatus[], runtimeStatus: BaseRuntimeStatus | undefined, repairUrlParams: StringMap) => {
        switch (this.state.selectedTab) {
            case UnitTab.PushTable:
                return this.renderPushTablePage()
            case UnitTab.UnitConfig:
                return <Page_Unit_Config runtimeStatus={runtimeStatus} unit={{unitId: this.unitId, product: this.product}}/>
            case UnitTab.Status:
                return this.renderStatus(pmStatus, runtimeStatus, repairUrlParams)
            case UnitTab.GptChat:
                return <Page_Unit_Gpt_Conversation hidden={this.state.selectedTab !== UnitTab.GptChat}/>
            case UnitTab.UnitFacts:
                return <Page_Unit_Facts hidden={this.state.selectedTab !== UnitTab.UnitFacts}/>
        }
    };

    private renderStatus = (pmStatus: PmStatus[], runtimeStatus: BaseRuntimeStatus | undefined, repairUrlParams: StringMap) => {
        const is3Point0Unit = UnitsManagerModule.is3Point0Unit(this.unitId);
        if (is3Point0Unit === null)
            return null;

        return <div id="mappings"
                    className={`ll_h_t match_width match_height ${margin(2)}`}
                    style={{justifyContent: "space-between", overflow: "auto"}}>
            {this.shouldShowLeftSide(is3Point0Unit) && this.renderDevicePanel(pmStatus, "som")}
            {/*temporary location - open device paring screen with unit's details*/}
            <div className={"ll_v_c"} style={{flex: 1, padding: "0 5px"}}>
                {this.renderOverrideButtons(runtimeStatus?.som?.version_name)}
                {this.renderRepairButton(repairUrlParams)}
                {UnitsManagerModule.getUnitBasic(this.unitId)?.activation && <div style={{marginTop: 20}}>
                    {renderResetPublicKeysButton({
                        unitId: this.unitId,
                        product: this.product
                    }, "Reset Public Key Encryption", "Reset Public Key Encryption")}
                </div>}
                {this.unitId && !UnitsManagerModule.is3Point0Unit(this.unitId) && <div style={{marginTop: 20}}>
                    {renderPushMessageButton(PushMessageDialogTitle, PushMessageDialogTitle, () => {
                        this.openDialog = "push message";
                        this.requestConfigToBeInstalled();
                    })}
                </div>}
                <Component_GeneralComment
                    unitId={this.unitId}
                    comment={UnitsModule.getGeneralComment(this.unitId)}
                />
            </div>
            {/*end of open device paring screen	*/}
            {this.renderDevicePanel(pmStatus, "tablet", is3Point0Unit)}
        </div>;
    };

    private shouldShowLeftSide(is3Point0Unit: boolean) {
        if (this.showLeftSide)
            return true;

        return !is3Point0Unit;
    }

    private renderBlinkingText = (runtimeStatus: BaseRuntimeStatus | undefined) => {
        const somVersion = runtimeStatus?.som?.unit_config?._originalVersion?.split("R")[0];
        const tabletVersion = runtimeStatus?.tablet?.unit_config?._originalVersion?.split("R")[0];

        if (somVersion && tabletVersion && somVersion !== tabletVersion)
            return <BlinkingButton
                text={`ElliQ and remote screen apps versions are different, please check`}
                className={css({marginTop: 10, maxWidth: '42vw'})}
            />
    }

    private renderInfoBar = (logzioUrl: string, jiraUrl: string, unitUrl: string, runtimeStatus?: BaseRuntimeStatus) => {
        const commandsAppUrl = `${CareCenterModule.getCommandsUrl()}?unitId=${this.unitId}&jwt=${StorageKey_JWT.get()}`;
        const pointers = this.getStorageBucket(runtimeStatus);
        const buckets = pointers.map(({bucket, projectId}) => ({url: this.getBucketUrl({bucket, projectId}), label: bucket}));
        return <div
            className={`match_width ${backgroundColor("white")} ll_h_c`}
            style={{
                flex: 70,
                justifyContent: "space-between",
                color: "black",
                border: "1px solid",
                borderRadius: "2px"
            }}>
            <div style={{display: "flex", alignItems: "center"}}>
                {this.getStyleSpan(`Unit ID: ${this.unitId}`)}
                <CopyButton obj={this.unitId}/>
            </div>
            {this.getStyleSpan(`User Group: ${UnitsModule.getUnitUserGroup(this.unitId) || "No User Group set"}`)}
            {this.getStyleSpan(`Sub Group: ${UnitsModule.getUnitSubUserGroup(this.unitId) || "No Sub Group set"}`)}
            {this.getAliasDropdown()}
            {this.getConfigDropdown()}
            <div style={{display: "flex", alignItems: "center"}}>
                <Tooltip placement={"bottom-end"} title={'Converse'}>
                    <img
                        alt="converse"
                        className={`${padding(5)} clickable`}
                        style={{width: 30, height: 30}}
                        src={icon__chat}
                        onClick={() => this.setState({initStateRecording: true, drawerOpen: true})}
                    />
                </Tooltip>
                <NavigateButton tooltip={'Commands App'} options={[{url: commandsAppUrl}]} icon={icon__joystick}/>
                <NavigateButton tooltip={'LOGZ.IO'} options={[{url: logzioUrl}]} icon={icon__logzio}/>
                <NavigateButton tooltip={'JIRA'} options={[{url: jiraUrl}]} icon={icon__jira}/>
                <NavigateButton tooltip={'UNIT FB'} options={[{url: unitUrl}]} icon={icon_firebase}/>
                <NavigateButton tooltip={'BUCKET'} options={buckets} icon={gcp}/>
                {UnitsManagerModule.getUnitBasic(this.unitId)?.pair && <Tooltip placement={"bottom-end"} title={'UNPAIR'}>
                    <img
                        alt="unpair click"
                        className={`${padding(5)} clickable`}
                        style={{width: 25, height: 25}}
                        src={icon__unpair}
                        onClick={() => UnitsModule.onUnpairClick(this.unitId, this.product)}
                    />
                </Tooltip>}
            </div>
        </div>;
    };

    private getConfigDropdown = () => {
        const configs = PackageManagerModule.getConfigs();
        const pmConfig = getPMUnitConfig(this.unitId, this.product);
        return this.renderPMDD(configs, "configId", val => val.configId, pmConfig?.configId);
    };

    private getAliasDropdown = () => {
        const aliases = PackageManagerModule.getAliases()
        const pmConfig = getPMUnitConfig(this.unitId, this.product);
        return this.renderPMDD(aliases, "aliasId", val => val._id, pmConfig?.aliasId);
    };

    private renderPMDD<T extends UiPackageConfig | Alias>(configs: {
        [p: string]: T
    }, key: "configId" | "aliasId", getId: (val: T) => string, selectedVal?: string) {
        const values = sortArray(_values(configs), e => e.name, true);
        if (!values.length)
            return;
        const notAssignedVal = "";
        const name = `select-${key}`;
        return <select
            style={{
                border: 'none',
                maxWidth: 170,
                fontSize: 17
            }}
            key={`${name}-${this.unitId}`}
            value={selectedVal || notAssignedVal}
            name={name} onChange={(sel) => {
            const value = sel.target.value;
            if (value && !configs[value])
                return this.logError(`Something weird is happening, selected value ${value}. Not a configId`)

            this.setState({loading: true})
            // @ts-ignore
            const body: Request_AssignTo = {
                unit: {unitId: this.unitId, product: this.product},
                [key]: value
            }
            PackageManagerModule.assignTo(body)
        }}>
            <option
                key={'not-assigned'}
                value={notAssignedVal}
            >
                Not Assigned
            </option>
            {values.map((n) => {
                const k = getId(n);
                return <option
                    key={k}
                    value={k}
                >
                    {`${key[0].toUpperCase() + key.slice(1, key.length - 2)}: ${n.name}`}
                </option>
            })}
        </select>
    }

    private getStyleSpan = (text: string) => <span className={mainInfoStyle}>{text}</span>;

    private renderSetEnv = () => {
        return <div className="ll_v_c">
            {this.renderInstallNowButton()}
            <PermissionsComponent
                url={Url_UpdateEnv}
                loadingComponent={() => <></>}
            >
                <Button
                    className={"unit_monitoring_btn"}

                    style={{
                        marginTop: "10px",
                        textTransform: "none"
                    }}
                    size="small"
                    variant="outlined"
                    onClick={() => {
                        Dialog_SetUserGroup.show(this.unitId, this.state.userGroup)
                    }}
                >
                    Set User Group
                </Button>
            </PermissionsComponent>
        </div>;
    };

    private renderOverrideButtons = (versionName?: string) => {
        return <>
            {this.renderSetEnv()}
            <Button className={"unit_monitoring_btn"}
                    size="small"
                    fullWidth
                    variant="outlined"
                    style={{
                        marginTop: "10px",
                        textTransform: "none"
                    }}
                    onClick={() => {
                        new Dialog_Builder(<Dialog_ShowOverrides product={this.product} unitId={this.unitId}/>)
                            .setButtons(closeButton("OK"))
                            .setAllowIndirectClosing(true)
                            .show();
                    }}
            >Show overrides</Button>
            {versionName && <Button
                className={"unit_monitoring_btn"}
                size="small"
                fullWidth
                variant="outlined"
                style={{
                    marginTop: "10px",
                    textTransform: "none"
                }}
                onClick={() => {
                    new Dialog_Builder(<Dialog_ShowOverrides product={this.product} unitId={this.unitId}
                                                             version={versionName}/>)
                        .setButtons(closeButton("OK"))
                        .setAllowIndirectClosing(true)
                        .show();
                }}>Version override</Button>}</>;
    };

    private renderRepairButton = (repairUrlParams: StringMap) => {
        if (UnitsManagerModule.is3Point0Unit(this.unitId))
            return null;

        const unitBasic = UnitsManagerModule.getUnitBasic(this.unitId);
        if (!unitBasic?.pair)
            return null;

        if (!checkPermissions(PermissionAccessLevel_Pairing, PermissionAccessLevel_Pairing.Pair, PermissionKey_Pairing)())
            return;

        return <Button
            className={"unit_monitoring_btn"}
            size="small"
            fullWidth
            variant="outlined"
            style={{
                marginTop: "10px",
                textTransform: "none"
            }}
            onClick={() => RoutingModule.goToRoute(Route_UnitRepairing, repairUrlParams)}
        >Repair device</Button>;
    };

    private renderDevicePanel(pmStatus: PmStatus[], deviceType: DeviceType, is3Point0Unit?: boolean) {
        const unit = {
            unitId: this.unitId,
            product: this.product
        };

        const pushMessagesForDevice = UnitsManagerModule.is3Point0Unit(this.unitId) ? PushMessagesModule.getPushMessages([]) : PushMessagesModule.getPushMessagesForDevice(deviceType, this.product);
        if (UnitsManagerModule.is3Point0Unit(this.unitId))
            pushMessagesForDevice.forEach(item => {
                if (item.label && item.label.toLowerCase().includes("tablet"))
                    item.label = item.label.replace(/tablet/i, "RS App");

                if (item.label && item.label.toLowerCase().includes("som"))
                    item.label = item.label.replace("SOM", "ElliQ App");
            });

        const pushCommands = pushMessagesForDevice.filter(m => !!m.isDisplayed);
        const primaryPushCommandKeys = ['do_nothing', 'restart-app', 'device_reboot', 'upload_logs', "speed-test"]

        const primaryPushCommands: PushCommand[] = [];
        const secondaryPushCommands: PushCommand[] = [];

        pushCommands.forEach((elem) => {
            if (primaryPushCommandKeys.includes(elem.key)) {
                primaryPushCommands.push(elem);
            } else {
                secondaryPushCommands.push(elem);
            }
        })
        // const primaryPushCommands = pushCommands.filter(m => primaryPushCommandKeys.includes(m.key))
        // const secondaryPushCommands = pushCommands.filter(m => !primaryPushCommandKeys.includes(m.key))
        const unitBasic = UnitsManagerModule.getUnitBasic(this.unitId);
        return <div className={'match_width match_height'} style={!is3Point0Unit ? {flex: "0 1 46%", maxWidth: "46vw"} : {flex: "0 1 85%"}}>
            <Panel_DeviceStatus
                runtimeStatus={UnitsModule.getRuntimeStatus(this.unitId)}
                serial={deviceType === "som" ? unitBasic?.somSerial : unitBasic?.tabletSerial}
                deviceType={deviceType}
                label={`${deviceType.toUpperCase()} Mapping`}
                unitId={this.unitId}
                product={this.product}
                primaryRenderers={infoRenderers(deviceType, this.unitId)}
                secondaryRenderers={secondaryInfoRenderers(deviceType, this.unitId)}
                primaryPushCommands={primaryPushCommands}
                secondaryPushCommands={secondaryPushCommands}
                pmStatus={pmStatus ? pmStatus.find(status => status.deviceType === deviceType) : undefined}
            />
            {this.renderPushTable(unit, deviceType)}
        </div>;
    }

    private renderPushTable(unit: Unit, deviceType: DeviceType) {
        const pushList = PushMessagesModule.getPushList(unit);
        const basic = UnitsManagerModule.getUnitBasic(this.unitId);
        if (!basic || !pushList)
            return;

        const pair = UnitsModule.getIdentity(this.unitId);
        const sha256 = pair?.identities.find(i => i.type === deviceType)?.sha256;

        const serial = deviceType === "som" ? basic.somSerial : basic.tabletSerial;
        const deviceIds: string[] = []
        if (this.state.treatAsPairUnit && sha256)
            deviceIds.push(sha256);

        if (this.state.treatAsActivateUnit)
            deviceIds.push(serial);

        const content = deviceIds.reduce((acc: DB_PushMessageAndEnv[], deviceId) => {
            const pushListElement = pushList[deviceId];
            if (!pushListElement)
                return acc;
            return acc.concat(pushListElement);
        }, [])

        return <PushCommandsTable
            content={sortArray(content, message => message.timestamp)}
            count={5}
            isPage={this.state.selectedTab === UnitTab.PushTable}
            setIsPage={() => {
                this.setState({selectedTab: UnitTab.PushTable})
            }}
            unit={unit}
        />;
    }

    private renderInstallNowButton() {
        if (!checkPermissions(PermissionAccessLevel_PmDeploy, PermissionAccessLevel_PmDeploy.Installer, PermissionKey_PmDeploy)())
            return "";

        return <Button
            style={{textTransform: "none", marginTop: "10px"}}
            className={"unit_monitoring_btn"}
            size="small"
            variant="outlined"
            onClick={() => {
                this.openDialog = "install now";
                this.requestConfigToBeInstalled();
            }}>
            Install now
            <img style={{marginLeft: "5px"}} alt="" className={`clickable`} src={icon__upload} width={20} height={20}/>
        </Button>;
    }

    requestConfigToBeInstalled = () => {
        if (!this.openDialog)
            return;

        this.setState({loading: true});
        PackageManagerModule.fetchDeviceConfigFe([{product: this.product, unitId: this.unitId}]);
    };

    requestFetchConfigFeCompleted = () => {
        this.setState({loading: false});
        if (!this.openDialog)
            return;

        const action = this.openDialog === "install now" ? actionDialogGenerator.installNow() : actionDialogGenerator.generalPush(_keys(devices), PushMessageDialogTitle);
        this.openDialog = undefined;
        Component_PushMessagesDialog.show([{unitId: this.unitId, product: this.product}], action);
    };

    private renderPushTablePage() {
        const unit = {
            unitId: this.unitId,
            product: this.product
        };
        const pushList = PushMessagesModule.getPushList(unit);

        let content: DB_PushMessageAndEnv[] = [];

        if (pushList) {
            const messages: DB_PushMessageAndEnv[] = Object.values(pushList).reduce(
                (carry: DB_PushMessageAndEnv[], item: DB_PushMessageAndEnv[]) => carry.concat(item), []);
            content = sortArray(messages, item => item.timestamp);
        }

        return <div className="match_height match_width" style={{overflow: "auto"}}><PushCommandsTable
            content={content}
            isPage={this.state.selectedTab === UnitTab.PushTable}
            setIsPage={() => this.setState({selectedTab: UnitTab.Status})}
            unit={unit}
        /></div>;
    }

    private renderTabs(rs?: BaseRuntimeStatus) {
        return <div style={{width: 560.48}}>
            {_keys(UnitTab).map(k => {
                const isActive = this.state.selectedTab === UnitTab[k];
                return <button
                    key={k}
                    className={tabClass(isActive)}
                    disabled={UnitTab[k] === UnitTab.UnitConfig && !rs}
                    onClick={() => this.setState({selectedTab: UnitTab[k]})}
                >
                    {UnitTab[k]}
                </button>
            })}
        </div>
    }

    private renderPairingOrActivation() {
        return (
            <div style={{paddingBottom: "5px", cursor: "pointer"}} className={"ll_h_c"}>
                {UnitsManagerModule.getUnitBasic(this.unitId)?.pair && <TS_Checkbox
                    id={"treat-as-pairing-checkbox"}
                    label={"Pairing"}
                    onCheck={(checked) => {
                        if (!this.state.treatAsActivateUnit)
                            return;
                        const newVal = !checked;
                        UnitsManagerModule.setTreatItAs(this.unitId, newVal, this.state.treatAsActivateUnit);
                        this.setState(() => ({treatAsPairUnit: newVal}))
                    }}
                    value={this.state.treatAsPairUnit}
                    checked={!!this.state.treatAsPairUnit}
                    circle
                />}
                <div style={{width: "20px"}}/>
                <TS_Checkbox
                    id={"treat-as-activation-checkbox"}
                    label={"Activation"}
                    onCheck={(checked) => {
                        if (!this.state.treatAsPairUnit)
                            return;
                        const newVal = !checked;
                        UnitsManagerModule.setTreatItAs(this.unitId, this.state.treatAsPairUnit, newVal);
                        this.setState(() => ({treatAsActivateUnit: newVal}))
                    }}
                    value={this.state.treatAsActivateUnit}
                    checked={!!this.state.treatAsActivateUnit}
                    circle
                />
            </div>
        );
    }
}
