import * as React from 'react';
import {ReactNode} from 'react';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {CircularProgress} from "@mui/material";

export type Progress = () => ReactNode;

type Props = {
	progress?: Progress,
	text?: string
	disableBackdropClick?: boolean
}

export class CustomLoader
	extends React.Component<Props, {}> {
	constructor(props: Props) {
		super(props);
	}

	render() {
		return <Dialog
			open={true}
			aria-labelledby="loading-dialog"
			aria-describedby="loading-dialog"
			hideBackdrop
			disableEnforceFocus
			disableRestoreFocus
			disableEscapeKeyDown
			style={{ position: 'initial' }}
		>
			<DialogContent className="ll_v_c">
				{this.props.progress
					?
					this.props.progress()
					:
					<>
						<div style={{marginBottom: "20px"}}>{this.props.text ? this.props.text : 'Loading'}</div>
						<CircularProgress/>
					</>}
			</DialogContent>
		</Dialog>

	}
}
