import * as React from 'react';
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {Response_GetOverride} from "@app-sp/app-shared/units";
import {OverridesModule, RequestKey_FetchOverride} from "@modules/OverridesModule";
import Loader from "@components/Loader";
import {OnRequestListener} from "@intuitionrobotics/thunderstorm";
import {Page_Overrides} from "../overrides/Page_Overrides";

type Props = {
    product: string,
    unitId: string
    version?: string
}

type State = {
    override?: Response_GetOverride;
}

export class Dialog_ShowOverrides
    extends BaseComponent<Props, State>
    implements OnRequestListener {

    state: State = {
        override: OverridesModule.getOverride(this.props.unitId)
    }

    __onRequestCompleted = (requestKey: string) => {
        switch (requestKey) {
            case RequestKey_FetchOverride:
                this.setState({override: OverridesModule.getOverride(this.props.unitId)});
        }
    };

    private renderBody = (overrides: any) => {
        if (!overrides)
            return <h3>No overrides received</h3>

        if (!this.props.version)
            return Page_Overrides.renderOverride(overrides)

        const override = overrides[this.props.version];
        if (override)
            return <>
                <div style={{padding: 13}}>{this.props.version}:</div>
                {Page_Overrides.renderOverride(override)}
        </>

        return <h3>No overrides received for version {this.props.version}</h3>
    }

    render() {
        if (!this.state.override) {
            OverridesModule.fetchOverride(this.props.unitId, this.props.product)
            return <Loader/>
        }

        return <div style={{maxHeight: "90vh", overflow: "auto"}}>
            {this.renderBody(this.state.override.overrides)}
        </div>;
    }
}
