import * as React from "react";
import * as emotion from "emotion";
import {Dialog, DialogActions, DialogTitle, ThemeProvider} from "@mui/material";
import {PaperProps} from "@mui/material/Paper";
import {COLOR_darkGray, COLOR_darkSkyBlue, COLOR_disabledGray, COLOR_greenyBlue} from "@styles/colors";
import {dialogThemeConfirmation, textAreaForDialog} from "@components/Dialog_Confirmation";
import {InputTextAreaField} from "@components/InputTextAreaField";
import {errorText} from "@components/NewStyledDropDown";
import {EnvironmentModule} from "@modules/EnvironmentModule";

const submitStyle = emotion.css`{
    background-color: ${COLOR_darkSkyBlue};
    border: none;
    border-radius: 24.5px;
    color: white;
    cursor: pointer;
    font-size: 14px;
    height: 35px;
    outline: none;
    width: 99px;
    :active {
        background-color: ${COLOR_greenyBlue};
    }
    :disabled {
        background-color: ${COLOR_disabledGray};
        cursor: default
    }
}`;

const cancelStyle = emotion.css`{
    background-color: white;
    border: none;
    color: ${COLOR_darkGray};
    cursor: pointer;
    font-size: 14px;
    height: 35px;
    text-decoration: underline;
    outline: none;
    width: 99px;
}`;

type Props = {
    showInput?: boolean,
    inputFieldIsMandatory?: boolean,
    inputMandatoryText?: string,
    agent_id: string,
    onAccept: (shouldKeepPrivateData: boolean, reason?: string) => void,
    onCancel: () => void
}

type State = {
    shouldKeepPrivateData: boolean,
    inputText: string,
    focusLost: boolean,
    cancelHover: boolean
}

export class Dialog_ConfirmationRename
    extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            shouldKeepPrivateData: ["DEV","LOCAL"].includes(EnvironmentModule.getEnvName()),
            inputText: "",
            focusLost: false,
            cancelHover: false
        };
    }

    onClose = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.props.onCancel();
        event.stopPropagation();
    };

    submit = () => {
        this.props.onAccept(this.state.shouldKeepPrivateData, this.state.inputText);
    };

    renderMandatoryFieldError = () => {
        const { inputMandatoryText, showInput} = this.props;
        const {focusLost, inputText} = this.state;
        if (focusLost && inputMandatoryText && showInput && !inputText)
            return <div key={"reason-error"} style={{marginLeft: "10px"}} className={errorText}>*{inputMandatoryText}</div>;

        return null;
    }

    validateReason = (value: string) => {
        if (!value)
            return "reason is mandatory field";

        return null;
    }

    onFocusLost = (value: string) => {
        if (!this.state.cancelHover)
            this.setState({focusLost: true});
    }

    render() {
        const paperPropsStyles: Partial<PaperProps> = {
            style: {
                width: 600,
                height: "unset",
                boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.15)",
                borderRadius: "6px",
                position: "fixed",
                top: "36%",
                margin: "auto",
                padding: "26px 28px 18px 26px",
                boxSizing: "border-box"
            },
        };
        return (
            <ThemeProvider theme={dialogThemeConfirmation}>
                <Dialog
                    open={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    onClose={this.onClose}
                    PaperProps={paperPropsStyles}
                    maxWidth={'sm'}
                    disableEscapeKeyDown
                >
                    <DialogTitle>What's the reason for renaming {this.props.agent_id}?</DialogTitle>
                    <div>
                        <InputTextAreaField autoFocus={true} onFocusLost={(value) => this.onFocusLost(value)} validateValue={value => this.validateReason(value)} style={textAreaForDialog} maxLength={255} id="newUnitId" onValueChange={(value) => {
                            this.setState({inputText: value});
                        }}/>
                        {this.renderMandatoryFieldError()}
                    </div>
                    <DialogActions>
                        {this.shouldShowCheckbox() && <label>
                            <input
                                type="checkbox"
                                checked={this.state.shouldKeepPrivateData}
                                onChange={() => this.setState(s => ({shouldKeepPrivateData: !s.shouldKeepPrivateData}))}
                            />
                            Keep private data
                        </label>}
                        <button className={cancelStyle} onMouseEnter={() => this.setState({cancelHover: true})} onMouseLeave={() => this.setState({cancelHover: false})} onClick={event => this.onClose(event)}>{'Cancel'}</button>
                        <button disabled={this.props.showInput && this.props.inputFieldIsMandatory && !this.state.inputText} className={submitStyle} onClick={this.submit}>{'Rename'}</button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        )
    }

    private shouldShowCheckbox() {
        return true;
        // return !EnvironmentModule.isProd();
    }
}
