import {DropdownOption, NewStyledDropDown} from "@components/NewStyledDropDown";
import * as React from "react";
import {rowClass} from "./ActivationFormComponent";

type UserGroupProps = {
    selectedOption?: DropdownOption
    options: DropdownOption[]
    onChange: (userGroup: string) => void
    onBlur?: () => void
    error?: string
    addItem?: (item: string) => Promise<void>
    id: string
    label: string
    disabled?: boolean
};

export const DropDownWithAddOption = ({id, label, onBlur, onChange, error, selectedOption, options, addItem, disabled}: UserGroupProps) => {
    return <div className={rowClass}>
        <NewStyledDropDown
            id={id}
            chosenOption={selectedOption}
            label={label}
            onChange={(sel) => onChange(sel.target.value)}
            onBlur={onBlur}
            options={options}
            error={error}
            addItem={async (ug) => {
                if (!options.some(o => o.value === ug))
                    addItem?.(ug);

                onChange(ug);
            }}
            disabled={disabled}
        />
    </div>;
}
