import * as React from "react";
import {BaseComponent, Dialog_Builder, DialogModule} from "@intuitionrobotics/thunderstorm/frontend";
import {SimpleLoader} from "@components/SimpleLoader";
import {defaultButtonStyle, defaultCancelStyle, defaultSubmitStyle} from "../../Dialog";
import {Body_Activation} from "@app/ir-q-app-common/types/activation"

type State = {
    loading: boolean
}

type Props = {
    params: Body_Activation,
    hasFMContacts: boolean,
    onSubmit?: (activationData: Body_Activation, skipHistoryCheck?: boolean) => void,
    cancelCallback: () => void
}
export class DialogWasInUseUnit extends BaseComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {loading: false};
    }
    public static show = (params: Body_Activation, hasFMContacts: boolean, cancelCallback: () => void, onSubmit?: (activationData: Body_Activation, skipHistoryCheck?: boolean) => void) => {
        return new Dialog_Builder(<DialogWasInUseUnit params={params} hasFMContacts={hasFMContacts} onSubmit={onSubmit} cancelCallback={cancelCallback}/>)
            .setTitle(<div className={'ll_v_c'} style={{padding: 5}}>Restore Unit</div>)
            .setOverlayColor("rgba(102, 255, 255, 0.4)")
            .setStyle({
                minWidth: "400px",
                padding: "15px",
                width: "60%"
            })
            .setZIndex(99999)
            .show();
    }

    private renderLoading = () => {
        if (!this.state.loading)
            return;
        return <SimpleLoader overlay={true} noText={true}/>;
    };

    private renderSubmit() {
        return <div style={{marginTop: "30px"}} className={'ll_h_t'}>
            <button
                onClick={() => {
                    this.props.cancelCallback();
                    DialogModule.close();
                }}
                style={{...defaultButtonStyle, ...defaultCancelStyle}}>
                Cancel
            </button>
            <button
                onClick={() => {
                    this.setState({loading: true});
                    this.props.onSubmit?.(this.props.params, true)
                    DialogModule.close();
                }}
                style={{...defaultButtonStyle, ...defaultSubmitStyle}}>
                Submit
            </button>
        </div>;
    }

    render() {
        let text = `${this.props.params.unitId} was deleted in the past. Keep in mind that the filled-in data will override the user’s data`
        if (this.props.hasFMContacts)
            text += ` and that contacts will be restored! Would you still like to restore it?`;
        else
            text += `. Would you still like to restore it?`


        return <div style={{marginBottom: "5px"}} className={'ll_v_c'}>
            <span>{text}</span>
            {this.renderSubmit()}
            {this.renderLoading()}
        </div>;
    }
}
