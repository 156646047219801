import {XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {Minute, Module} from "@intuitionrobotics/ts-common";
import {Api_AddPairRequest, Api_DeletePairRequest, Api_ListPairRequests} from "@app/ir-q-app-common/types/api";
import {HttpMethod} from "@intuitionrobotics/thunderstorm";
import {PairRequest, PairRequestBody} from "@app/ir-q-app-common/types/pair-requests";

export const RequestKey_ListPairRequests = 'pair-requests--list'
export const RequestKey_AddPairRequest = 'pair-requests--add'
export const RequestKey_DeletePairRequest = 'pair-requests--delete'

export class PairRequestsModule_Class
    extends Module {

    private pairRequests?: PairRequest[]

    getPairRequests() {
        return this.pairRequests;
    }

    fetchPairRequests = () => {
        XhrHttpModule
            .createRequest<Api_ListPairRequests>(HttpMethod.POST, RequestKey_ListPairRequests)
            .setRelativeUrl("/v1/pair-request/list")
            .setLabel(`Listing pair requests`)
            .setTimeout(Minute)
            .setOnError(`Error listing pair requests`)
            .execute(async response => {
                this.pairRequests = response;
            });
    };

    addPairRequests = (body: PairRequestBody) => {
        XhrHttpModule
            .createRequest<Api_AddPairRequest>(HttpMethod.POST, RequestKey_AddPairRequest)
            .setRelativeUrl("/v1/pair-request/create")
            .setJsonBody(body)
            .setLabel(`Creating pair requests`)
            .setTimeout(Minute)
            .setOnError(`Error creating pair requests`)
            .execute(async response => {
                if (!this.pairRequests)
                    this.pairRequests = [];

                this.pairRequests?.push(response);
            });
    };

    deletePairRequest = (_id: string) => {
        XhrHttpModule
            .createRequest<Api_DeletePairRequest>(HttpMethod.POST, RequestKey_DeletePairRequest)
            .setRelativeUrl("/v1/pair-request/delete")
            .setJsonBody({_id})
            .setLabel(`Deleting pair request with id: ${_id}`)
            .setTimeout(Minute)
            .setOnError(`Error deleting pair request with id: ${_id}`)
            .execute(async response => {
                this.pairRequests = this.pairRequests?.filter(p => p._id !== _id) || [];
            });
    };
}

export const PairRequestsModule = new PairRequestsModule_Class("PairRequestsModule");
