import * as React from "react";
import {TooltipBuilder, TooltipDefaultStyle, TooltipModule, TS_Input} from "@intuitionrobotics/thunderstorm/frontend";
import * as emotion from "emotion";
import {generateHex} from "@intuitionrobotics/ts-common";

const aquaMarine = "#48bece";
const white = "#fff";
const grays_3 = `rgba(149, 156, 165, 1)`;

export const autocompleteInputCSS: emotion.Interpolation = {
    width: '100%',
    border: 'solid 1px gray',
    outline: 'none',
    background: 'transparent',
};

export const inputStyle = (width?: number | string) => emotion.css(
    {
        ...autocompleteInputCSS,
        position: "relative",
        width: width,
        zIndex: 9,
        '::placeholder': {
            fontStyle: "italic",
            color: grays_3,
        }
    },
);

const suggestionStyle = (width?: number | string) => emotion.css(
    {
        ...autocompleteInputCSS,
        position: "absolute",
        top: 0,
        left: 0,
        color: grays_3,
        width: width,
        zIndex: 0,
    },
);

type Props<T> = {
    id: string
    placeholder: string
    labelResolver: (item: T) => string
    keyResolver?: (item: T) => string
    tooltipResolver?: (item: T) => React.ReactNode
    options: T[]
    onSelected: (item: T) => void
    width: string | number
    onAccept?: (itemName: string) => void
    onType?: (text: string) => void
    showResult?: boolean
    value?: T
    style?: {}
}

type State<T> = {
    text: string,
    suggestedText: string,
    hoveredItem?: T,
    showOptions: boolean,
    value?: T,
    itemIndex: number
}


export class AutoComplete<T>
    extends React.Component<Props<T>, State<T>> {

    static getDerivedStateFromProps(newProps: Props<any>, _prevState: State<any>) {
        const prevState = {..._prevState};
        if (newProps.value && prevState.value !== newProps.value) {
            prevState.value = newProps.value;
            prevState.text = newProps.labelResolver(newProps.value)
        }
        return prevState;
    }

    constructor(props: Props<T>) {
        super(props);
        this.state = {
            text: this.props.value ? this.props.labelResolver(this.props.value) : "",
            suggestedText: "",
            showOptions: false,
            itemIndex: -1
        };
    }

    private matchedOptions() {
        const prefix = this.state.text.toLowerCase();
        if (prefix === "") {
            return [];
        }
        return this.props.options.filter(item => this.props.labelResolver(item).toLowerCase().startsWith(prefix));
    }

    componentDidUpdate(prevProps: Readonly<Props<T>>, prevState: Readonly<State<T>>) {
        if (prevState.text === this.state.text && this.props.value === prevProps.value)
            return;

        if (this.props.value !== prevProps.value) {
            this.setState({text: this.props.value ? this.props.labelResolver(this.props.value) : ""});
        }

        const matched = this.matchedOptions();
        let suggestedText = "";
        if (matched.length !== 0) {
            suggestedText = this.props.labelResolver(matched[0]).toLowerCase();
        }
        this.setState({suggestedText});
    }

    private autocompleteEventHandler = (e: KeyboardEvent) => {
        const filteredOptions = this.getFilteredOptions();
        switch (e.key) {
            case 'ArrowDown':
                const newIndex = (this.state.itemIndex + 1) % filteredOptions.length;
                this.setState({
                    itemIndex: newIndex,
                    hoveredItem: filteredOptions[newIndex],
                });
                return;
            case 'ArrowUp':
                const minusOne = this.state.itemIndex === -1 ? filteredOptions.length - 1 : this.state.itemIndex - 1;
                const newIndexForUp = minusOne > -1 ? minusOne : filteredOptions.length + minusOne;
                this.setState({
                    itemIndex: newIndexForUp,
                    hoveredItem: filteredOptions[newIndexForUp]
                });
                return;
            case 'Tab':
            case 'ArrowRight':
                if (this.state.suggestedText.length)
                    this.setState({text: this.state.suggestedText});
                return;
            case 'Enter':
                e.preventDefault();
                this.onAccept();
                return;
            default:
                return;
        }
    };

    render() {
        return <div id={`autocomplete-${this.props.id}`} className="match_width stacking" onClick={() => this.setState({showOptions: true})}>
            <TS_Input<string>
                id={`${this.props.id}-input`}
                name={generateHex(8)}
                className={`${inputStyle(this.props.width)}`}
                style={this.props.style}
                value={this.state.text}
                placeholder={this.props.placeholder}
                onChange={text => this.onChange(text)}
                onBlur={() => this.stopEdit(this.state.text)}
                type={"text"}
                handleKeyEvent={this.autocompleteEventHandler}
            />
            {this.state.showOptions && <TS_Input<string>
                id={`${this.props.id}-suggestion`}
                focus={false}
                className={`${suggestionStyle(this.props.width)}`}
                value={this.state.suggestedText}
                placeholder={''}
                onChange={() => {
                }}
                type={"text"}
            />}
            {this.state.showOptions && this.renderSelection()}
        </div>;
    }

    private onChange(text: string) {
        this.props.onType && this.props.onType(text);
        this.setState({text, showOptions: true});
    }

    getFilteredOptions = () => {
        const text = this.state.text;
        const labelResolver = this.props.labelResolver;
        return !text ? this.props.options : this.props.options.filter(item => labelResolver(item).toLowerCase().includes(text.toLowerCase()));
    }

    renderSelection() {
        const filtered = this.getFilteredOptions();
        if (filtered.length === 0)
            return "";
        return <div style={{
            position: 'absolute', left: 8, backgroundColor: white, width: this.props.width,
            borderStyle: "solid", borderWidth: 1, borderColor: aquaMarine, borderRadius: 2,
            paddingTop: 5, paddingBottom: 5, paddingLeft: 4,
            maxHeight: 390, overflowY: "auto",
            boxShadow: "rgba(0, 0, 0, 0.19) 0px 2px 4px 0px", zIndex: 10
        }}>
            {filtered.map(this.renderItem)}
        </div>;
    }

    onAccept = () => {
        let text = this.state.text;
        if (this.state.hoveredItem)
            text = this.props.labelResolver(this.state.hoveredItem);

        this.props.onAccept && this.props.onAccept(text);
        this.setState({
            text: this.props.showResult ? text : "",
            showOptions: false,
            hoveredItem: undefined
        });
        this.hideTooltip();
    };

    renderItem = (item: T, index: number) => {
        const label = this.props.labelResolver(item);
        const key = this.props.keyResolver ? this.props.keyResolver(item) : label;
        const hovered = this.state.hoveredItem === item;
        return <div id={`${this.props.id}-${index}`} key={key} className={'ll_h_c match_width clickable'} style={{
            height: 26, justifyContent: "space-between",
            fontSize: 13, fontWeight: hovered ? "bolder" : "normal", width: this.props.width,
            backgroundColor: this.state.itemIndex === index ? "lightblue" : "white"
        }}
                    onMouseEnter={() => this.setState({hoveredItem: item, itemIndex: index})}
                    onMouseMove={(e) => {
                        if (this.props.tooltipResolver)
                            this.showTooltip(e, this.props.tooltipResolver(item));
                    }}
                    onMouseLeave={() => {
                        this.hideTooltip();
                    }}
                    onClick={() => this.stopEdit(label)}>
            <span style={{whiteSpace: "nowrap"}}>{label}</span>
        </div>;
    };

    showTooltip = (e: React.MouseEvent, content: React.ReactNode, y: number = e.clientY + 15) => {
        new TooltipBuilder(content, e)
            .setLocation(e.clientX + 10, y)
            .setStyle({...TooltipDefaultStyle, zIndex: 100})
            .show();
    };

    hideTooltip = () => TooltipModule.hide();

    stopEdit = (label: string = "") => {
        const hoveredItem = this.state.hoveredItem
        hoveredItem ? this.props.onSelected(hoveredItem) : (this.props.onAccept && this.props.onAccept(label))
        const text = () => {
            if (!this.props.showResult)
                return "";
            if (hoveredItem)
                return this.props.labelResolver(hoveredItem)
            return label
        }
        this.setState(
            {
                text: text(),
                showOptions: false,
                hoveredItem: undefined,
                itemIndex: -1
            });
        this.hideTooltip();
    };

}
