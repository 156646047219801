import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import './style.css';
import {XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {HttpMethod} from "@intuitionrobotics/thunderstorm/shared/types";
import {Second} from "@intuitionrobotics/ts-common";
import {GetFirebaseToken} from "@app-sp/app-shared/api";
import {FirebaseModule} from "@intuitionrobotics/firebase/frontend";
import {RenderChat} from "./RenderChat";
import {PushMessagesModule} from "@modules/PushMessagesModule";
import {Elliq_ProductKey} from '@app/ir-q-app-common/shared/consts';
import {AppPackage_ElliQ} from '@app/ir-q-app-common/types/push-messages';
import {Database, onValue, ref} from 'firebase/database';
import firebase from "firebase/compat";
import {config} from "../../../config";
import Unsubscribe = firebase.Unsubscribe;

export type Message = {
    id: string;
    sender: Sender;
    text: string;
    timestamp: number;
    translatedText?: string;
    extraParams?: any;
    showFeedback?: boolean;
};

export enum Sender {
    User = 'User',
    ElliQ = 'ElliQ',
    System = 'System',
    Plan = 'Plan'
}

const getToken = async (unitId: string) => {
    const r = await XhrHttpModule
        .createRequest<GetFirebaseToken>(HttpMethod.GET, "get-firebase-token")
        .setUrlParams({projectId: "ir-q-state-dashboard-staging", unitId: unitId})
        .setRelativeUrl(`/v1/token/get`)
        .setTimeout(60 * Second)
        .setLabel(`Listening to collection`)
        .setOnError(`Failed to listen to collection`)
        .executeSync();
    return r.token
}

const translate = async (text: string) => {
    return XhrHttpModule
        .createRequest<any>(HttpMethod.GET, "translate")
        .setUrlParams({text, from: "ja-JP", to: "en-US"})
        .setRelativeUrl(`/v1/translate`)
        .setTimeout(60 * Second)
        .setLabel(`Translate`)
        .executeSync();
}

const sendPush = (unitId: string, type: string, data?: string) => PushMessagesModule.pushMessageToUnits([{unitId, product: Elliq_ProductKey}], type, data, ["som"], AppPackage_ElliQ, 10000);

function Conversation(props: { unitId: string, textEnabled?: boolean }) {
    const unitId = props?.unitId;
    if (!unitId)
        return;
    const [messages, setMessages] = useState<Message[]>([]);
    // Since I cant access state in the callback of the subscription, I use a ref to store the messages
    const messagesRef = useRef(messages);
    useEffect(() => {
        messagesRef.current = messages; // Update the ref whenever messages change
    }, [messages]);
    const handleUserInput = (interaction: any, dataParams: any, timestamp: number) => {
        let toAdd: any = dataParams;
        try {
            const {parameters: intentParams, ...rest} = toAdd;
            const p = JSON.parse(intentParams);
            Object.entries(p).forEach(([key, value]) => {
                rest[`param ${key}`] = value;
            })
            toAdd = rest;
        } catch (e) {
            console.error(e);
        }
        toAdd.intent = interaction.message;
        addMessage(interaction.id, Sender.User, interaction.params.text || interaction.message, timestamp, toAdd);
    };

    const handleAction = (interaction: any, dataParams: any, timestamp: number) => {
        switch (interaction.type) {
            case 'UI_ACTION':
                addMessage(interaction.id, Sender.System, `UI: ${interaction.message}`, timestamp, dataParams);
                break;
            case 'MOVE':
                addMessage(interaction.id, Sender.System, `G: ${interaction.params?.gestureName}`, timestamp, dataParams);
                break;
            default:
                console.log(interaction.type, JSON.stringify(interaction));
        }
    };

    const addDataToConversation = (data: any) => {
        if (!data)
            return;

        for (const interaction of data) {
            // The value under interaction.sessionId is the rootExecutionId
            const timestamp = interaction.timestamp;
            const dataParams = {rootExecutionId: interaction.sessionId, planName: interaction.planName, ...interaction.params};
            switch (interaction.provider) {
                case 'intent':
                    handleUserInput(interaction, dataParams, timestamp);
                    break;
                case 'wakeword':
                    addMessage(interaction.id, Sender.User, interaction.message, timestamp);
                    break;
                case 'tts':
                    const originalText = interaction.message;
                    const text = originalText.replace(/<[^>]*>?/gm, '');
                    if (text !== originalText)
                        dataParams.originalText = originalText;

                    const showFeedback = ['GPT', 'GENEQ'].includes(dataParams.service)
                    // Get the last message that is an intent and use that session id
                    const lastUserMessage = messagesRef.current?.find(m => m.sender === Sender.User);
                    if (lastUserMessage?.extraParams?.sessionId)
                        dataParams.sessionId = lastUserMessage.extraParams?.sessionId;
                    addMessage(interaction.id, Sender.ElliQ, text, timestamp, dataParams, showFeedback);
                    break;
                case 'action':
                    handleAction(interaction, dataParams, timestamp);
                    break;
                case 'plan_status':
                    addMessage(interaction.id, Sender.Plan, interaction.message, timestamp, dataParams);
                    break;
                default:
                    console.log(interaction.provider, JSON.stringify(interaction));
            }
        }
    };

    const subscribeToStateDashboard = async (_unitId: string): Promise<Unsubscribe | undefined> => {
        if (!_unitId)
            return;
        const token = await getToken(_unitId)
        const sd = config.FirebaseModule.sd;
        const s = (await FirebaseModule.createSession({id: "sd", ...sd}, token)).getDatabase();
        // @ts-ignore
        const db: Database = s.database;
        setMessages([]);
        return onValue(ref(db, `units/elliq/${_unitId}/interaction/data`), snapshot => {
            addDataToConversation(snapshot.val());
        }, (error: Error) => {
            throw new Error(`Error while getting value from path: units/elliq/${_unitId}/interaction/data` + JSON.stringify(error));
        }, {onlyOnce: false})
    };

    let unsubscribe: Unsubscribe | undefined;
    useEffect(() => {
        subscribeToStateDashboard(unitId).then(u => unsubscribe = u);
        return () => unsubscribe?.();
    }, [unitId])

    const translateAndAdd = async (text: string) => {
        const translatedText = await translate(text)
        setMessages(s => {
            const idx = s.findIndex(m => m.text === text);
            if (idx === -1)
                return s;
            return [
                ...s.slice(0, idx),
                {
                    ...s[idx],
                    translatedText
                },
                ...s.slice(idx + 1)
            ]
        });
    };

    const addMessage = (id: string, sender: Sender, text: string, timestamp: number, extraParams?: {}, showFeedback: boolean = false) => {
        // understand if the text is in japanese
        const isJapanese = text.match(/[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff]/);
        setMessages(s => [{id, sender, text, timestamp, extraParams, showFeedback}, ...s]);
        if (isJapanese)
            translateAndAdd(text).catch(console.error);

    };

    return <RenderChat
        messages={messages}
        onToggle={(toggled) => {
            sendPush(unitId, "toggle-text-speech-server", toggled.toString());
        }}
        onMessageSent={(message) => {
            const value: any = {transcript: message};
            const planMessages = messages.filter(m => m.sender === Sender.Plan);
            if (planMessages.length && planMessages[0].extraParams?.status?.toLowerCase() === "running")
                value.rootExecutionId = planMessages[0].extraParams?.rootExecutionId;

            const data = JSON.stringify(value);
            sendPush(unitId, "start-text-speech-server", data);
        }}
        isToggled={props.textEnabled}
    />
}

export default Conversation;