import * as React from "react";
import {BaseComponent, ToastModule} from "@intuitionrobotics/thunderstorm/frontend";
import {Unit} from "@app/ir-q-app-common/types/units";
import {Component_PushMessagesDialog} from "./Dialog_PushMessages";
import {PackageManagerModule} from "@modules/package-manager/PackageManagerModule";
import {checkPermissions} from "../../../routes";
import {PermissionAccessLevel_PmDeploy, PermissionKey_PmDeploy} from "@app/ir-q-app-common/types/legacy/sp-permissions";
import {addAllItemToArray} from "@intuitionrobotics/ts-common";
import {actionDialogGenerator, BatchActionsType, PushMessagesModule, PushParams,} from "@modules/PushMessagesModule";
import {PC_Reboot, PCS_RestartSom, PCT_RestartTablet} from "@app/ir-q-app-common/types/push-commands";
import {NewStyledDropDown} from "@components/NewStyledDropDown";

type BatchActionProps = {
    units: Unit[]
};

export class Dropdown_BatchActions
    extends BaseComponent<BatchActionProps> {

    render() {
        const units: Unit[] = this.props.units;
        const batchActionsTypes: BatchActionsType[] = [];
        if (checkPermissions(PermissionAccessLevel_PmDeploy, PermissionAccessLevel_PmDeploy.Installer, PermissionKey_PmDeploy)())
            addAllItemToArray(batchActionsTypes, [
                actionDialogGenerator.installNow("Install Now"),
                {
                    key: `GeneralPush-som`,
                    label: "Push message to som",
                    devices: ['som'],
                    params: {},
                    allowIndirectClosing: false,
                    action: (units: Unit[], params: PushParams) => {
                        PushMessagesModule.pushMessageToUnits(units, params.type, params.data, ['som'], params.packageName, params.ttl);
                    }
                },
                {
                    key: `GeneralPush-tablet`,
                    label: "Push message to tablet",
                    devices: ['tablet'],
                    params: {},
                    allowIndirectClosing: false,
                    action: (units: Unit[], params: PushParams) => {
                        PushMessagesModule.pushMessageToUnits(units, params.type, params.data, ['tablet'], params.packageName, params.ttl);
                    }
                },
                {
                    key: `GeneralPush-both`,
                    label: "Push message to both",
                    devices: ["som", "tablet"],
                    params: {},
                    allowIndirectClosing: false,
                    action: (units: Unit[], params: PushParams) => {
                        PushMessagesModule.pushMessageToUnits(units, params.type, params.data, ["som", "tablet"], params.packageName, params.ttl);
                    }
                }
            ]);

        addAllItemToArray(batchActionsTypes, [
            actionDialogGenerator.specificPush(["som"], PCS_RestartSom, `${PCS_RestartSom.key} - som`),
            actionDialogGenerator.specificPush(["tablet"], PCT_RestartTablet, `${PCT_RestartTablet.key} - tablet`),
            actionDialogGenerator.rebootDevice(["som"], PC_Reboot, `Reboot som`),
            actionDialogGenerator.rebootDevice(["tablet"], PC_Reboot, `Reboot tablet`),
            actionDialogGenerator.uploadLogs(["som", "tablet"]),
            actionDialogGenerator.cancelQueuedMessages(["som", "tablet"])
        ]);

        const options = batchActionsTypes.map(action => ({value: action.label, key: action.key}));
        const chosenOption = {
            key: "not-assigned",
            value: "Select Action"
        };
        options.unshift(chosenOption);
        return <NewStyledDropDown
            errorMargin={false}
            id={"batchActions"}
            options={options}
            chosenOption={chosenOption}
            showEmptyOption={false}
            selectStyle={{width: 170, color: '#1976d2'}}
            wrapperStyle={{width: 170, height: 30, marginBottom: 0, border: "1px solid rgba(25, 118, 210, 0.5)", borderRadius: 5, padding: "0 5px 0 0"}}
            onChange={(sel) => {
                const selected = batchActionsTypes.find(action => action.key === sel.target.value);
                if (!selected)
                    return;

                if (units.length === 0)
                    return ToastModule.toastError("Please select units before performing actions");

                if (selected.key === "Install" || selected.key.includes("GeneralPush"))
                    PackageManagerModule.fetchDeviceConfigFe(units);

                Component_PushMessagesDialog.show(units, selected);
            }}
        />
    }
}
