import * as React from 'react';
import * as emotion from 'emotion';
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";

const inputTextAreaContainer = emotion.css`
  align-items: center;
  label: input-textarea-container;
`;

const inputFieldLabel = emotion.css`
  margin: 5px;
  label: input-textarea-label;
  text-align: center;
`;

export const simpleLabel = emotion.css`
	margin-left: 10px;
	margin-bottom: -10px;
	color: #3B3B3B;
	font-size: 14px;
	font-weight: 400;
  	letter-spacing: -0.41px;
  	padding-bottom: 4px;
`;

const textArea = emotion.css`
  padding: 15px;
  margin: 10px 10px;
  border-radius: 40px !important;
  box-shadow: 0 0 0 1px rgba(41, 41, 41, 0.2) inset;
  font-size: 13px;
  font-family: inherit !important;
  color: #242424;
  border: none;
  resize: none;
`;

export interface InputTextAreaFieldProps {
	id: string;
	onValueChange: (value: string, id: string) => void;
	className?: string;
	value?: string;
	onFocusLost?: (value: string, id: string) => void;
	validateValue?: (value: string) => string | null;
	autoFocus?: boolean;
	label?: string;
	labelStyle?: string,
	style?: string;
	readOnly?: boolean;
	disabled?: boolean;
	cols?: number;
	rows?: number;
	trim?: boolean;
	maxLength?: number;
}

interface InputTextAreaState {
	error: any;
	value: string
}

export class InputTextAreaField
	extends BaseComponent<InputTextAreaFieldProps, InputTextAreaState> {
	constructor(props: InputTextAreaFieldProps) {
		super(props);

		this.onValueChanged = this.onValueChanged.bind(this);
		this.onFocusLost = this.onFocusLost.bind(this);

		this.state = {
			error: null,
			value: props.value || ""
		};
	}

	static getDerivedStateFromProps(newProps: InputTextAreaFieldProps, prevState: InputTextAreaState) {
		return {value: newProps.value};
	}

	onValueChanged(evt: React.ChangeEvent<HTMLTextAreaElement>): void {
		let value = evt.target.value;
		value = this.props.trim ? value.trim() : value;
		this.props.onValueChange && this.props.onValueChange(value, this.props.id);
		this.setState(() => {
			return {value: value}
		});
	}

	onFocusLost() {
		if (!this.props.onFocusLost)
			return;

		this.props.onFocusLost(this.state.value, this.props.id);
	}

	validateValue() {
		if (!this.props.validateValue)
			return true;
		const value = `${this.state.value}`;

		if (!this.props.validateValue)
			return true;

		const errorMessage = this.props.validateValue(value);

		this.setState(() => {
			return {error: errorMessage}
		});
		return !errorMessage;
	}


	render() {
		return (
			<div className={inputTextAreaContainer}>
				{this.props.label ? <div className={this.props.labelStyle || inputFieldLabel}>{this.props.label}</div> : ""}

				<textarea
					id={this.props.id}
					value={this.state.value}
					onChange={this.onValueChanged}
					className={this.props.style || textArea}
					autoFocus={this.props.autoFocus || false}
					onBlur={() => {
						if (this.validateValue())
							this.onFocusLost();
					}}
					readOnly={this.props.readOnly || false}
					cols={this.props.cols || 40}
					rows={this.props.rows || 20}
					disabled={this.props.disabled || false}
					maxLength={this.props.maxLength}
				/>

				<div className={this.state.error ? "form-error-message-on" : "form-error-message-off"}>
					{this.state.error}</div>
			</div>
		);
	}
}
