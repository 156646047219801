import * as React from "react";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {QueryParam_UnitId} from "@consts/common";
import {AppModule} from "@modules/AppModule";
import {CareCenterModule} from "@modules/CareCenterModule";

type Props = {
    unitId: string,
    gender: string
    // For the possible request to inject hte name from the contacts
    // name: string
}

export class Page_NewCareCenterPatient_iframe
    extends BaseComponent<Props> {

    render() {
        if (!this.props.unitId)
            return "You should not arrive here with no unitId. Please report tech team";

        const websiteUrl = CareCenterModule.getWebsiteUrl();
        const addPatientUrl = CareCenterModule.getAddPatientUrl()

        if (!websiteUrl || !addPatientUrl) {
            this.logError("Missing config for care-center page page");
            return "Missing config for care-center page";
        }

        // For the possible request to inject hte name from the contacts
        // const _src = `${websiteUrl}/${addPatientUrl}?${QueryParam_UnitId}=${this.props.unitId}&name=${this.props.name}&gender=${this.props.gender}`;
        const _src = `${websiteUrl}/${addPatientUrl}?${QueryParam_UnitId}=${this.props.unitId}&gender=${this.props.gender}`;
        const src = AppModule.composeUrlWithAuth(_src);
        return <iframe
                width={"100%"}
                height={"800px"}
                src={src}
                style={{borderRadius: "0.5em"}}/>;
    }

}
