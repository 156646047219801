import { AddressDetails } from "@app/ir-q-app-common/types/db-contact";
import * as React from "react";
import {generateHex} from "@intuitionrobotics/ts-common";

type Props = {
    onAutocomplete: (details: AddressDetails) => void
    id:string
    className?: string
};

export class Component_PlaceAutocomplete
    extends React.Component<Props>{

    private refAuto?: HTMLInputElement;

    render() {
        return <input
            id={this.props.id}
            ref={r => {
                if (!r || this.refAuto)
                    return;

                this.refAuto = r;
                this.forceUpdate();
            }}
            type={"search"}
            name={generateHex(4)}
            // style={{boxSizing: "content-box"}}
            className={this.props.className}
            placeholder={""}
        />;
    }

    private id = 'google-maps-element-id';
    componentDidMount() {
        const elementById = document.getElementById(this.id);
        if(elementById) {
            this.initAutocomplete()
            return;
        }

        const script: HTMLScriptElement = document.createElement("script");
        script.type = "text/javascript";
        script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDf4v9yF_ZmuO_F-zMhxdfdJVu2qlGk50M&libraries=places";
        script.async = true;
        script.id = this.id;
        script.onload = () => this.initAutocomplete();
        document.body.appendChild(script);
    }

    private autocomplete: any;
    private initAddress = () => ({country: "", city: ""});

    private initAutocomplete = () => {
        if (!this.refAuto)
            return;

        this.autocomplete = new google.maps.places.Autocomplete(this.refAuto, {types: ["geocode"]});
        this.autocomplete.setFields(["address_component"]);
        this.autocomplete.addListener("place_changed", this.fillInAddress);
    };

    private fillInAddress = () => {
        const address = this.autocomplete.getPlace();
        const addressState: AddressDetails = this.initAddress();
        if (address.address_components) {
            address.address_components.map((addProp: any) => {
                if (addProp.types.includes("street_number"))
                    addressState.streetAddress = addProp.long_name;
                if (addProp.types.includes("route"))
                    addressState.streetAddress = addressState.streetAddress ? addressState.streetAddress + ` ${addProp.long_name}` : addProp.long_name;
                if ((addProp.types.includes("locality")) || addProp.types.includes("postal_town"))
                    addressState.city = addProp.long_name;
                if (addProp.types.includes("administrative_area_level_1"))
                    addressState.state = addProp.long_name;
                if (addProp.types.includes("administrative_area_level_2"))
                    addressState.region = addProp.long_name;
                if (addProp.types.includes("country"))
                    addressState.country = addProp.long_name;
                if (addProp.types.includes("postal_code"))
                    addressState.postalCode = addProp.long_name;
            });
            this.props.onAutocomplete(addressState)
            // this.setState(oldState => {
            //     oldState.contact.address = addressState;
            //     return oldState;
            // });
        }
    };
}
