import * as React from "react";
import {BaseComponent, TS_TextArea} from "@intuitionrobotics/thunderstorm/frontend";
import {AutoComplete} from "@components/AutoComplete";
import {PushMessagesModule, RequestKey_FetchPushCommands} from "@modules/PushMessagesModule";
import {DeviceType} from "@app/ir-q-app-common/types/units";
import {PackageManagerModule} from "@modules/package-manager/PackageManagerModule";
import {Response_AppInfo} from "@app-sp/app-shared/package-manager";
import {PushCommand} from "@app/ir-q-app-common/types/push-commands";
import {sortArray} from "@intuitionrobotics/ts-common";
import {OnRequestListener} from "@intuitionrobotics/thunderstorm";

type Props = {
    devices: DeviceType[]
    type: string,
    packageName: string | undefined,
    data?: string,
    // ttl?: number,
    onTypeChange: (commandKey: string) => void,
    onPackageNameChange: (packageName?: string) => void,
    onDataChange: (data: string) => void
    // onTTLChange: (ttl: number) => void
}

// const inputStyle: React.CSSProperties = {margin: "8px 0 8px 8px", width: "94%", outline: "none", border: "solid 1px gray"};

export class Component_SendPushMessage
    extends BaseComponent<Props>
    implements OnRequestListener {

    __onRequestCompleted(key: string, success: boolean, data?: string) {
        if (key !== RequestKey_FetchPushCommands)
            return;

        this.forceUpdate()
    }

    onSelected = (push: PushCommand) => {
        this.props.onTypeChange(push.key);
        push.targetPackage && this.props.onPackageNameChange(push.targetPackage);
        this.props.onDataChange(push.data || "")
    };

    onAccept = (key: string) => {
        this.props.onTypeChange(key);
        // find the first match (just for enter!)
        const push = this.getPushMessages().find(p => p.key === key);
        const targetPackage = push?.targetPackage;
        if (targetPackage)
            this.props.onPackageNameChange(targetPackage);

        this.props.onDataChange(push?.data || "")
    };

    private getPushMessages = (): PushCommand[] => sortArray(PushMessagesModule.getPushMessages(this.props.devices), m => this.getLabel(m), true);

    render() {
        const appList: Response_AppInfo[] = PackageManagerModule.getApps()
        return <div>
            <div className="ll_h_c stacking" style={{justifyContent: "space-between"}}>
                <AutoComplete<PushCommand>
                    id={"push-type"}
                    placeholder={"type"}
                    labelResolver={(push: PushCommand) => this.getLabel(push)}
                    keyResolver={(push: PushCommand) => push.key + push.targetPackage}
                    tooltipResolver={(push: PushCommand) => push.label}
                    options={this.getPushMessages()}
                    onSelected={this.onSelected}
                    onAccept={this.onAccept}
                    showResult
                    width={"90%"}/>
            </div>
            <AutoComplete
                id={"push-package-name"}
                placeholder={"target package"}
                labelResolver={(app: Response_AppInfo) => `${app.name} (${app.packageName})`}
                options={appList}
                value={PackageManagerModule.getApp(this.props.packageName)}
                onSelected={(app?: Response_AppInfo) => this.props.onPackageNameChange(app?.packageName)}
                showResult
                width={"90%"}/>
            <TS_TextArea
                type={"text"}
                style={{height: "110px", margin: "8px", width: "90%", outline: "none"}}
                value={this.props.data}
                placeholder={"data"}
                onChange={(data: string) => this.props.onDataChange(data)}/>
        </div>;
    }

    private getLabel = (push: PushCommand) => {
        return push.key
    };
}
