import * as React from "react";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {
    QueryParam_Product,
    QueryParam_UnitId
} from "@consts/common";
import {AppModule} from "@modules/AppModule";
import {Unit} from "@app/ir-q-app-common/types/units";
import {ContactsModule} from "@modules/ContactsModule";

type Props = {
    unit: Unit
}

export class Iframe_EditContect
    extends BaseComponent<Props> {

    render() {
        if (!this.props.unit)
            return "You should not arrive here with no unitId. Please report tech team";

        const _src = `${ContactsModule.getUserAgentIframe()}?${QueryParam_UnitId}=${this.props.unit.unitId}&${QueryParam_Product}=${this.props.unit.product}`;
        const src = AppModule.composeUrlWithAuth(_src);
        return <iframe
                    width={"100%"}
                    height={"700px"}
                    src={src}
                    style={{borderRadius: "0.5em"}}
                />;
    }

}
