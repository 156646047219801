export const COLOR_lightGreen = "#CCF5CC";
export const COLOR_lightRed = "#F5CCCC";
export const COLOR_lightYellow = "#F5F5AA";
export const COLOR_lightOrange = "#fecc9a";
export const COLOR_lightGray = "#F5F5F5";
export const COLOR_lighterGray = "#b7bbc3";
export const COLOR_veryLightGray = "#76797f";
export const COLOR_charcoalGray = "#3a3c3d";
export const COLOR_slateGray = "#606364";
export const COLOR_anotherGray = "#d5d7dd";
export const COLOR_darkGray = "#333435";
export const COLOR_grayOnGray = "#8e8e93";
export const COLOR_placeholderGray = "#9fa2a3";
export const COLOR_DashedBorderGray = "#979797";
export const COLOR_disabledGray = "#cecec9";
export const COLOR_darkSkyBlue = "#4cb8d0";
export const COLOR_lightSkyBlue = "#5cd6f1";
export const COLOR_greenyBlue = "#3da2b8";
export const COLOR_lightBlue = "#44E3EA75";
export const COLOR_white = "#fff";
export const COLOR_iceBlue = "#edeff1";
export const COLOR_mediumPink = "#ee6187";
export const COLOR_orange = "#fb7d02";
export const COLOR_red = "#b40012";
export const COLOR_purple = "#cbcbf5";