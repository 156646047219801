import {Module, sha256} from "@intuitionrobotics/ts-common";
import {ThunderDispatcher, ToastModule, XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {DeviceWithSameSerialAlreadyInSystem, IllegalUnitID, PairedDevice, Request_Pair} from "@app-sp/app-shared/units";
import {ApiGetIdentity, ApiPairUnit, ApiUnitUnpair, ApiUpdateUserGroup} from "@app-sp/app-shared/api";
import {BarcodeV1, UnitId, UnitIdentity} from "@app/ir-q-app-common/types/units";
import {ApiDeleteDevice, ErrorDeviceInUse} from "@app/ir-q-app-common/types/api";
import {Product} from "@consts/common";
import {BaseHttpRequest, ErrorResponse, HttpMethod, OnRequestListener} from "@intuitionrobotics/thunderstorm";
import {UnitsModule} from "@modules/UnitsModule";
import {CONST_KS_SubUserGroup, CONST_KS_UserGroup} from "@app/ir-q-app-common/types/unit-config";
import {SerialNotAvailableError} from "@app/ir-q-app-common/shared/errors";

export interface OnUnitIdentityFetched {
    onUnitIdentityFetched(unit: UnitIdentity): void;
}

export interface OnDeviceWithSameSerialAlreadyInSystem {
    __onDeviceWithSameSerialAlreadyInSystem(body: DeviceWithSameSerialAlreadyInSystem): void;
}

export interface OnPairUnitFailed {
    __onDeviceAlreadyPaired(pairedDevice: PairedDevice): void;
    __onInvalidSerial(errorMessage: string): void;

    __onPairingError(message: string): void;
}

export interface OnPairUnitSuccess {
    __onPairingSuccess(): void;
}

export const RequestKey_RegisterDevice = "RegisterDevice";
export const RequestKey_PairUnit = "PairUnit";
export const RequestKey_DeleteDevice = "DeleteDevice";
export const RequestKey_UnpairUnit = "UnpairUnit";
export const RequestKey_UpdateEnv = "UpdateEnv";
export const RequestKey_UpdateUnitMetadataEnv = "UpdateUnitMetadataEnv";
export const RequestKey_GetUnitIdentity = "GetUnitIdentity";

export class KasperModule_Class
    extends Module {

    private dispatcher_onDeviceAlreadyPaired = new ThunderDispatcher<OnPairUnitFailed, "__onDeviceAlreadyPaired">("__onDeviceAlreadyPaired");
    private dispatcher_onInvalidSerial = new ThunderDispatcher<OnPairUnitFailed, "__onInvalidSerial">("__onInvalidSerial");
    private dispatcher_onPairingError = new ThunderDispatcher<OnPairUnitFailed, "__onPairingError">("__onPairingError");
    private dispatcher_onDeviceWithSameSerialAlreadyInSystem = new ThunderDispatcher<OnDeviceWithSameSerialAlreadyInSystem, "__onDeviceWithSameSerialAlreadyInSystem">("__onDeviceWithSameSerialAlreadyInSystem");
    private dispatcher_onPairingSuccess = new ThunderDispatcher<OnPairUnitSuccess, "__onPairingSuccess">("__onPairingSuccess");
    private dispatcher_onUnitIdentityFetched = new ThunderDispatcher<OnUnitIdentityFetched, "onUnitIdentityFetched">("onUnitIdentityFetched");

    deleteDevice = (deviceSha?: string) => {
        if (!deviceSha)
            return;

        XhrHttpModule
            .createRequest<ApiDeleteDevice>(HttpMethod.POST, RequestKey_DeleteDevice)
            .setRelativeUrl("/v1/unit/delete-device")
            .setJsonBody({deviceSha})
            .setLabel(`Delete device sha: ${deviceSha}`)
            .execute()
            .setOnSuccessMessage(`Device sha *${deviceSha}* deleted successfully!!`)
            .setOnError((): any => {
                ToastModule.toastError(`Failed to delete device sha: ${deviceSha}`);
            });
    }

    pairUnit = (requestObject: Request_Pair, repair: boolean) => {
        XhrHttpModule
            .createRequest<ApiPairUnit>(HttpMethod.POST, RequestKey_PairUnit)
            .setRelativeUrl("/v1/elliq/pair")
            .setJsonBody(requestObject)
            .setLabel(`Pairing unit: ${requestObject.unitId}`)
            .setOnSuccessMessage(`Unit *${requestObject.unitId}* ${repair ? "re" : ""}paired successfully!!`)
            .setOnError((request, resError?: ErrorResponse<DeviceWithSameSerialAlreadyInSystem | ErrorDeviceInUse | IllegalUnitID>): any => {
                const handleOtherError = (_request: BaseHttpRequest<any>) => this.dispatcher_onPairingError.dispatchUI(`Unknown error for RequestKey_PairUnit, ${resError?.debugMessage}`);

                if (request.getStatus() === 403)
                    return this.dispatcher_onPairingError.dispatchUI(`You don't have sufficient permissions to perform this action`);

                const error = resError?.error;
                const errorType = error?.type;
                if (!error || !errorType)
                    return handleOtherError(request);

                let errorBody: ErrorDeviceInUse | IllegalUnitID | DeviceWithSameSerialAlreadyInSystem | undefined;
                switch (errorType) {
                    case "ErrorDeviceInUse":
                        errorBody = error.body as ErrorDeviceInUse;
                        this.dispatcher_onDeviceAlreadyPaired.dispatchUI(errorBody);
                        break;

                    case SerialNotAvailableError:
                        errorBody = error.body as IllegalUnitID;
                        this.dispatcher_onInvalidSerial.dispatchUI(errorBody.message);
                        break;

                    case "DeviceWithSameSerialAlreadyInSystem":
                        errorBody = error.body as unknown as DeviceWithSameSerialAlreadyInSystem;
                        this.dispatcher_onDeviceWithSameSerialAlreadyInSystem.dispatchUI(errorBody);
                        break;

                    case "IllegalUnitID":
                        errorBody = error.body as IllegalUnitID;
                        this.dispatcher_onPairingError.dispatchUI(errorBody.message);
                        break;
                    default:
                        handleOtherError(request);
                }
            })
            .execute(() => {
                this.dispatcher_onPairingSuccess.dispatchUI();
                UnitsModule.fetchPairedUnitsImpl();
                this.logInfo(`Retrieved version '${requestObject.version}'`);
            });
    };

    unpairUnit = (unitId: UnitId, product: Product, callback?: () => void) => {
        XhrHttpModule
            .createRequest<ApiUnitUnpair>(HttpMethod.POST, RequestKey_UnpairUnit)
            .setRelativeUrl("/v1/elliq/unpair")
            .setJsonBody({unitId, product})
            .setLabel(`Unpairing unit: ${unitId}`)
            .setOnSuccessMessage(`Unit *${unitId}* unpaired successfully!!`)
            .setOnError(() => {
                this.logWarning(`Failed to unpair unit *${unitId}*`);
                new ThunderDispatcher<OnRequestListener, "__onRequestCompleted">("__onRequestCompleted")
                    .dispatchUI(RequestKey_UnpairUnit, false);
            })
            .execute(() => {
                new ThunderDispatcher<OnRequestListener, "__onRequestCompleted">("__onRequestCompleted")
                    .dispatchUI(RequestKey_UnpairUnit, true);

                callback?.();
            });
    };

    updateUserGroup = (unitId: string, userGroup: string, subGroup?: string) => {
        let prevUserGroup, prevSubGroup;
        UnitsModule.getUnitMetadata(unitId)?.forEach(meta => {
            if (meta.dataKey === CONST_KS_UserGroup) {
                prevUserGroup = meta.data;
            }
            if (meta.dataKey === CONST_KS_SubUserGroup) {
                prevSubGroup = meta.data;
            }
        });

        XhrHttpModule
            .createRequest<ApiUpdateUserGroup>(HttpMethod.POST, RequestKey_UpdateEnv)
            .setRelativeUrl("/v1/elliq/update-user-group")
            .setJsonBody({
                unitId,
                prevUserGroup,
                userGroup,
                subUserGroup: subGroup || prevSubGroup
            })
            .setLabel(`Updating unit ${unitId} to env ${userGroup}`)
            .setOnSuccessMessage(`Unit's env updated successfully to ${userGroup}`)
            .setOnError(`Failed to update unit's env`)
            .execute(r => UnitsModule.onMetadataUpdated(r));
    };

    fetchUnitIdentity = (product: string, unitId: UnitId) => {
        XhrHttpModule
            .createRequest<ApiGetIdentity>(HttpMethod.GET, RequestKey_GetUnitIdentity)
            .setUrlParams({unitId, product})
            .setRelativeUrl(`/v1/elliq/get-identity`)
            .setLabel(`fetching unit ${unitId} identity`)
            .setOnError(`Error getting unit ${unitId} pairing info`)
            .execute(async (response) => {
                this.dispatcher_onUnitIdentityFetched.dispatchUI(response);
            });
    };

    getFakeTabletBarcodeForSO = (objSomBarcode: BarcodeV1): BarcodeV1 => {
        const newPublicKey = objSomBarcode.publicKey + "_SO";
        const newSerial = objSomBarcode.serial + "_SO";
        return {
            ...objSomBarcode,
            type: "tablet",
            publicKey: newPublicKey,
            serial: newSerial,
            sha256: sha256(newPublicKey),
            fakeDevice: true
        };
    };
}

export const KasperModule = new KasperModule_Class("KasperModule");
