import {Module} from "@intuitionrobotics/ts-common";

type Config = {
	websiteUrl: string
}

export const Route_Contacts_Table = "contacts-table";
export const Route_Agent_User_Details = "Agent-user-details";

export class ContactsModule_Class
	extends Module<Config> {

	getMessagingUrl = () => this.config.websiteUrl;
	getContactsTableRoute = () => Route_Contacts_Table;
	getAgentUserDetailsRoute = () => Route_Agent_User_Details

	getContactsPage = () => `${this.getMessagingUrl()}/${this.getContactsTableRoute()}`;
	getUserAgentIframe = () => `${this.getMessagingUrl()}/${this.getAgentUserDetailsRoute()}`;
}

export const ContactsModule = new ContactsModule_Class("ContactsModule");
