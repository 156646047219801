import {BaseDB_ApiGeneratorCaller} from "@intuitionrobotics/db-api-generator/app-frontend/BaseDB_ApiGeneratorCaller";
import {ThunderDispatcher, ToastModule} from "@intuitionrobotics/thunderstorm/frontend";
import {DB_Contact} from "@app/ir-q-app-common/types/db-contact";
import {BaseHttpRequest} from "@intuitionrobotics/thunderstorm/shared/BaseHttpRequest";
import {ErrorResponse} from "@intuitionrobotics/thunderstorm/shared/types";
import {ApiBinder_DBCreate, DefaultApiDefs} from "@intuitionrobotics/db-api-generator/shared/types";

export interface OnContactsUpdated {
    __onContactsUpdated: () => void;
}

export const dispatch_onContactsUpdated = new ThunderDispatcher<OnContactsUpdated, "__onContactsUpdated">("__onContactsUpdated");

export class ContactListModule_Class
    extends BaseDB_ApiGeneratorCaller<DB_Contact<any>> {

    protected init() {
        super.init();
        this.setDefaultDispatcher(dispatch_onContactsUpdated);
        this.query({contactType: "agentUser"});
    }

    protected onError = (request: BaseHttpRequest<ApiBinder_DBCreate<DB_Contact<any>>>, resError?: ErrorResponse<{
        phoneNumber: string,
        contactId: string
    }>): boolean => {
        if (request.key === this.getRequestKey(DefaultApiDefs.Create)) {
            const error = resError?.error;
            if (error?.type === "refreshFeError") {
                ToastModule.toastError("Please refresh this page first and then do actions, contacts list was changed");
                return super.onError(request, resError);
            }

            if (error?.type === "duplicatePhoneNumber") {
                ToastModule.toastError(`Duplicate phone number - this phone number ${resError?.error?.body.phoneNumber} already exist in one of this unit contacts: ${resError?.error?.body.contactId}`);
                return super.onError(request, resError);
            }

            if (error?.type === "connectToMoreThanOneUnit") {
                ToastModule.toastError(`Can't edit phone number because this contact connect to more than 1 unit - please delete the contact and add a new one`);
                return super.onError(request, resError);
            }
        }

        return super.onError(request, resError);
    }
}

export const ContactListModule = new ContactListModule_Class({key: "contacts", relativeUrl: "/v2/contacts"}, "ContactListModule");
