import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {BrowserHistoryModule, RoutingModule} from "@intuitionrobotics/thunderstorm/frontend";
import {NavLink} from "react-router-dom";
import {
    Route_Activation,
    Route_ActivationsList,
    Route_Admin,
    Route_Convo,
    Route_DeviceManager,
    Route_Home,
    Route_Monitoring,
    Route_PM,
    Route_Preinstalls,
    Route_UnitMonitoring,
    Route_UnitPairing
} from "./routes";
import {LoginModule} from "@modules/LoginModule";
import {RenderSelectUnit} from "./renderers/Renderer_renderSelectUnit";

interface Props {
    children: React.ReactNode;
    showUnitSelection: boolean;
}

const drawerWidth = 240;
const PageLinkKeys = [
    Route_Home,
    Route_Monitoring,
    Route_Activation,
    Route_ActivationsList,
    Route_PM,
    Route_Convo,
    Route_DeviceManager,
    Route_Admin,
    Route_Preinstalls,
    Route_UnitPairing,
];

function DrawerAppBar(props: Props) {
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const activeRouteKey = [...PageLinkKeys].reverse().find((key) => {
        const route = RoutingModule.getRoute(key);
        if (!route.label)
            return;

        if (window.location.pathname === route.path)
            return key;
    })

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{textAlign: 'center'}}>
            <Typography variant="h6" sx={{my: 2}}>
                Menu
            </Typography>
            <Divider/>
            <List>
                <ListItem>
                    <ListItemButton>
                        <ListItemText primary={<Button
                            size={"medium"}
                            style={{textTransform: "none"}}
                            variant={"outlined"}
                            color={"info"}
                            onClick={() => BrowserHistoryModule.getHistory().goBack()}
                        >
                            Back
                        </Button>}/>
                    </ListItemButton>
                </ListItem>
                {PageLinkKeys.map((key) => {
                    const route = RoutingModule.getRoute(key);
                    if (!route.label)
                        return;

                    return <ListItem key={key}>
                        <ListItemButton>
                            <ListItemText primary={<Button
                                key={key}
                                size={"medium"}
                                style={{textTransform: "none"}}
                                variant={activeRouteKey === key ? "contained" : "text"}
                            >
                                {<NavLink className={"no-link"} key={route.key} to={route.path}>{route.label}</NavLink>}
                            </Button>}/>
                        </ListItemButton>
                    </ListItem>
                })}
                <ListItem>
                    <ListItemButton>
                        <ListItemText primary={<Button
                            size={"medium"}
                            style={{textTransform: "none"}}
                            color={"warning"}
                            variant={"outlined"}
                            onClick={LoginModule.logout}
                        >
                            Logout
                        </Button>}/>
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <Box sx={{display: 'flex'}} className={"match_height"}>
            <CssBaseline/>
            <AppBar sx={{height: '48px', boxShadow: "none"}} component="nav" color={"transparent"}>
                <Toolbar sx={{display: {sm: 'flex'}, padding: {sm: "0 15px"}, justifyContent: {sm: 'center'}}}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{mr: 2, display: {sm: 'none'}}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Toolbar sx={{display: {xs: "none", sm: 'flex'}, padding: {sm: 0}, width: '100%', justifyContent: {sm: 'space-between'}}}>
                        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                            <Box sx={{display: 'block'}}>
                                <Button
                                    size={"medium"}
                                    style={{textTransform: "none"}}
                                    variant={"outlined"}
                                    onClick={() => BrowserHistoryModule.getHistory().goBack()}
                                >
                                    Back
                                </Button>
                            </Box>
                            <Box sx={{display: 'block'}}>
                                <RenderSelectUnit
                                    product={'elliq'}
                                    unitId={"Go to unit"}
                                    onUnitSelected={(value: string) => {
                                        RoutingModule.goToRoute(Route_UnitMonitoring, {
                                            product: "elliq",
                                            unitId: value
                                        });
                                    }}
                                    style={{paddingLeft: 10}}/>
                            </Box>
                        </Box>

                        <Box sx={{display: 'block'}}>
                            {PageLinkKeys.map((key) => {
                                const route = RoutingModule.getRoute(key);
                                if (!route.label)
                                    return;

                                return <Button
                                    key={key}
                                    size={"medium"}
                                    style={{textTransform: "none"}}
                                    variant={activeRouteKey === key ? "contained" : "text"}
                                >
                                    {<NavLink className={"no-link"} key={route.key} to={route.path}>{route.label}</NavLink>}
                                </Button>
                            })}
                        </Box>
                        <Box sx={{display: 'block'}}>
                            <Button
                                size={"medium"}
                                style={{textTransform: "none"}}
                                color={"warning"}
                                variant={"outlined"}
                                onClick={LoginModule.logout}
                            >
                                Logout
                            </Button>
                        </Box>
                    </Toolbar>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: {xs: 'block', sm: 'none'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
            <Box component="main" className={"match_width match_height flex-column"} sx={{p: 0}}>
                <Toolbar/>
                {props.children}
            </Box>
        </Box>
    );
}

export default DrawerAppBar;
