import {Module} from "@intuitionrobotics/ts-common";
import {PermissionsModule} from "@modules/PermissionsModule";
import {StorageKey_JWT, StorageKey_UserEmail} from "@intuitionrobotics/user-account/frontend";
import {EnvironmentModule} from "@modules/EnvironmentModule";
import {QueryParam_RedirectUrl} from "@intuitionrobotics/user-account";
import {PermissionsFE} from "@intuitionrobotics/permissions/frontend";
import {UrlsToLoad} from "../app/routes";

type Config = {}

class LoginModule_Class
    extends Module<Config> {

    logout = () => {
        StorageKey_JWT.delete();
        StorageKey_UserEmail.delete();
        const encodedOrigin = encodeURIComponent(EnvironmentModule.getOrigin());
        window.location.href = `${EnvironmentModule.getKasperoFeUrl()}/logout?${QueryParam_RedirectUrl}=${encodedOrigin}`;
    };

    onLogin = () => {
        this.runAsync('Running post login api calls', async () => {
            PermissionsFE.loadUrls(UrlsToLoad);
            await PermissionsModule.fetchMyPermissions();

        });
    };
}

export const LoginModule = new LoginModule_Class("LoginModule");
