import * as React from "react";
import {BaseComponent, Dialog_Builder, RoutingModule, ToastModule, XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {closeButton, confirmationButtons} from "@components/AppDialog";
import {css} from "emotion";
import {DeviceType, Unit, UnitEnv} from "@app/ir-q-app-common/types/units";
import {OnRequestListener} from "@intuitionrobotics/thunderstorm/shared/request-types";
import {RequestKey_FetchDeviceConfigFe} from "@modules/package-manager/PackageManagerModule";
import {ApiRefurbishUnit} from "@app-sp/app-shared/api";
import {HttpMethod} from "@intuitionrobotics/thunderstorm";
import {Route_UnitMonitoring, Url_SupportRefurbishUnit} from "../../../routes";
import {OnPushListReturned, PushMessagesModule} from "@modules/PushMessagesModule";
import {ActionResult} from "@app/ir-q-app-common/types/push-messages";
import {getLastPolling} from "@renderers/unit-info";
import {DB_PushMessageAndEnv, Response_ListPushAndroid} from "@app/ir-q-app-common/types/fb-push-messages";
import {_clearInterval, _keys, _setInterval, Second} from "@intuitionrobotics/ts-common";
import {OnUnitInfoReturned, RequestKey_FetchUnitsFullList, UnitsModule} from "@modules/UnitsModule";
import {KasperModule} from "@modules/KasperModule";
import {Request_Pair} from "@app-sp/app-shared/units";
import {isAlive} from "@app/ir-q-app-common/types/runtime-status";
import {PC_RestartStrickland, PCS_RestartSom, PCT_RestartTablet} from "@app/ir-q-app-common/types/push-commands";
import {InputTextField} from "@components/InputTextField";
import {ProgressBar} from "../../../components/ProgressBar";
import {UnitsManagerModule} from "@modules/UnitsManagerModule";
import {ActivationsModule, RequestKey_ListActivations} from "@modules/ActivationsModule";
import {ActivationDefaultParameters, ActivationDefaultParametersWithAuthType} from "../../pairing/Page_Activation";
import {CareCenterModule} from "@modules/CareCenterModule";
import {ActivationFormComponent} from "../../pairing/ActivationFormComponent";

type Props = {
    unit: Unit
}

type State = {
    mid: string
    somRefurbishStatus: string
    tabletRefurbishStatus: string
    newUnitId: string
    stepName: RefurbishStep
    showProgressBar: boolean
}

const contentStyle = css({
    width: "max-content",
    height: "max-content",
    padding: "10px 40px",
});

const buttonsStyle = css({
    marginTop: "auto",
    justifyContent: "flex-end",
    padding: "12px 0"
})

const enum RefurbishStep {
    ARE_YOU_SURE = "ARE_YOU_SUR",
    REPAIR = "REPAIR",
    REPAIRING = "REPAIRING",
    RESTART = "RESTART",
    DELETE_CACHE = "DELETE_CACHE",
}

export class Component_RefurbishUnitDialog
    extends BaseComponent<Props, State>
    implements OnRequestListener, OnPushListReturned, OnUnitInfoReturned {
    private interval: number | undefined;
    private deviceType: DeviceType;
    private newUnitName = "";
    private activationForm: ActivationDefaultParametersWithAuthType = ActivationsModule.getDefaultValues(this.props.unit.unitId);
    private areYouSureBtnPressed = false;
    private repairBtnPressed = false;
    private finished = false;
    private refurbishCallsFinished = false;

    constructor(props: Props) {
        super(props);
        this.deviceType = "som";
        this.state = {
            mid: "",
            newUnitId: "",
            tabletRefurbishStatus: "",
            somRefurbishStatus: "",
            stepName: RefurbishStep.ARE_YOU_SURE,
            showProgressBar: false
        };
    }

    __onRequestCompleted(key: string, success: boolean) {
        if ([RequestKey_ListActivations, RequestKey_FetchUnitsFullList].includes(key) && success) {
            const isActivation = key === RequestKey_ListActivations;

            this.restartUnit(isActivation);
            UnitsModule.cancelFetching();

            const newUnitBasic = UnitsManagerModule.getUnitBasic(this.newUnitName);
            if (newUnitBasic)
                UnitsModule.fetchRuntimeStatus(20 * Second, {
                    unitId: this.newUnitName,
                    product: this.props.unit.product
                });

            this.setState({stepName: RefurbishStep.RESTART});
            return;
        }

        if (RequestKey_FetchDeviceConfigFe !== key || success)
            return;
    }

    __onUnitInfoReturned(unit: Unit) {
        if (this.newUnitName === unit.unitId) {
            const runtimeStatus = UnitsModule.getRuntimeStatus(this.newUnitName);
            if (runtimeStatus)
                if (!this.refurbishCallsFinished && isAlive(getLastPolling(runtimeStatus.tablet, "tablet")) && isAlive(getLastPolling(runtimeStatus.som, "som"))) {
                    this.refurbishCallsFinished = true;
                    this.refurbishUnit(this.newUnitName, "som");
                    this.setState({stepName: RefurbishStep.DELETE_CACHE});
                }
        }
    }

    __onPushListReturned(response: Response_ListPushAndroid): void {
        let refurbishPushMessage: DB_PushMessageAndEnv | undefined;
        _keys(response).forEach(device => response[device].forEach(pushMessage => {
            if (this.state.mid && pushMessage.mId === this.state.mid)
                refurbishPushMessage = pushMessage;
        }));

        const status = refurbishPushMessage?.pushResult?.result;
        if (!status)
            return;

        if (this.deviceType === "som") {
            this.setState({somRefurbishStatus: status});
            if (status === ActionResult.Success) {
                this.deviceType = "tablet";
                this.clearInterval();
                this.refurbishUnit(this.newUnitName, "tablet");
                return;
            }
        }

        if (this.deviceType === "tablet")
            this.setState({tabletRefurbishStatus: status});
    }

    public static show = (unit: Unit) => new Dialog_Builder(<Component_RefurbishUnitDialog
        unit={unit}/>)
        .setTitle(<div
            style={{background: "rgb(217, 217, 217)", fontWeight: 600, padding: 15}}>{"Remote-wipe Unit"}</div>)
        .setAllowIndirectClosing(false)
        .show();

    componentDidMount() {
        UnitsModule.cancelFetching();
    }

    clearInterval = () => {
        if (this.interval)
            _clearInterval(this.interval);
    }

    componentWillUnmount() {
        this.clearInterval();
        UnitsModule.cancelFetching();
    }

    renderRefurbishSomProgress = () => {
        return <div className={'ll_v_c'} style={{height: "100%"}}>
            <div style={{color: "gold", marginTop: "30px"}}>Remote-wipe Som...</div>
            <ProgressBar/>
        </div>;
    }

    renderRefurbishTabletProgress = () => {
        return <div className={'ll_v_c'} style={{height: "100%"}}>
            <div style={{color: "gainsboro", marginTop: "30px"}}>Remote-wipe Tablet...</div>
            <ProgressBar/>
        </div>;
    }

    renderBody = () => {
        switch (this.state.stepName) {
            case RefurbishStep.ARE_YOU_SURE:
                return this.renderAreYouSure();

            case RefurbishStep.REPAIR:
                return this.renderRepair();

            case RefurbishStep.REPAIRING:
                return this.renderRepairing();

            case RefurbishStep.RESTART:
                return this.renderRestart();

            case RefurbishStep.DELETE_CACHE:
                return this.renderRefurbish();

            default:
                return null;
        }
    }

    renderAreYouSure = () => {
        const buttons = confirmationButtons(this.onAreYouSureSubmit, "Go!", () => this.goToUnitPage(this.props.unit.unitId)).map(
            (button, idx) => <div key={idx}>
                <button
                    className={button.className}
                    style={button.style}
                    onClick={() => button.action()}
                >{button.content}</button>
            </div>);

        return <>
            <h3>Are you sure you want to remote-wipe unit {this.props.unit.unitId}?</h3>
            <div className={`ll_h_c ${buttonsStyle}`}>{buttons}</div>
        </>;
    }

    goToUnitPage = (unitId: string) => {
        RoutingModule.goToRoute(Route_UnitMonitoring, {
            product: this.props.unit.product,
            unitId
        });
    }

    renderRefurbish = () => {
        const close = [closeButton("Go to remote-wipe unit page")].map(
            (button, idx) => <div key={idx}>
                <button
                    className={button.className}
                    style={{...button.style, width: "150px"}}
                    onClick={() => {
                        button.action();
                        this.goToUnitPage(this.newUnitName);
                    }}>
                    {button.content}
                </button>
            </div>);

        if (this.state.tabletRefurbishStatus === "Error")
            return <>
                <h3>Error while tried remote-wipe Tablet</h3>
                <div className={`ll_h_c ${buttonsStyle}`}>{close}</div>
            </>

        if (this.state.tabletRefurbishStatus === "Success" || this.finished) {
            this.finished = true;
            return <>
                <h3>Remote-wipe Action Completed Successfully</h3>
                <div className={`ll_h_c ${buttonsStyle}`}>{close}</div>
            </>
        }

        if (this.state.somRefurbishStatus === "Error")
            return <>
                <h3>Error while tried remote-wipe Som</h3>
                <div className={`ll_h_c ${buttonsStyle}`}>{close}</div>
            </>

        if (this.state.somRefurbishStatus === "Success")
            return this.renderRefurbishTabletProgress()

        if (this.deviceType === "tablet")
            return this.renderRefurbishTabletProgress();

        // if (this.state.mid)
        return this.renderRefurbishSomProgress();
    }

    renderRepairing = () => {
        return <div className={'ll_v_c'} style={{height: "100%"}}>
            <div style={{color: "greenyellow", marginTop: "30px"}}>Repairing unit...</div>
            <ProgressBar/>
        </div>;
    }

    renderRepair = () => {
        const buttons = confirmationButtons(this.onRepairSubmit, "Repair", () => this.goToUnitPage(this.props.unit.unitId)).map(
            (button, idx) => <div key={idx}>
                <button
                    className={button.className}
                    style={button.style}
                    onClick={button.action}
                >{button.content}</button>
            </div>);

        const unit = UnitsManagerModule.getUnitBasic(this.props.unit.unitId);

        if (unit?.activation) {
            this.activationForm = {...this.activationForm, somSerial: unit.somSerial, tabletSerial: unit.tabletSerial}

            const patient = CareCenterModule.getUnitPatient(unit.unitId);
            if (patient) {
                this.activationForm.organizationId = patient.organizationId;
                if (patient.physicianId)
                    this.activationForm.physicianId = patient.physicianId;
                if (patient.careManagerId)
                    this.activationForm.careManagerId = patient.careManagerId;
            }
            return <>
                <ActivationFormComponent
                    isPopup={true}
                    defaultParams={this.activationForm}
                    hideUserOnlyToggle={true}
                    onChange={(activationForm: ActivationDefaultParameters) => this.activationForm = {...this.activationForm, ...activationForm}}
                />
                <div className={`ll_h_c ${buttonsStyle}`}>{buttons}</div>
            </>
        }

        return <>
            <span>We are going to repair the unit first. Please give a new name to unit {this.props.unit.unitId}:</span>
            <InputTextField maxLength={40} id="newUnitId" type="text" onValueChange={(value) => {
                this.newUnitName = value
            }}/>
            <div className={`ll_h_c ${buttonsStyle}`}>{buttons}</div>
        </>
    }

    renderRestart = () => {
        return <div className={'ll_v_c'} style={{height: "100%"}}>
            <div style={{color: "green", marginTop: "30px"}}>Restarting apps...</div>
            <ProgressBar/>
        </div>;
    }

    render() {
        return <div className={contentStyle}>
            {this.renderBody()}
        </div>
    };

    private onAreYouSureSubmit = () => {
        if (this.areYouSureBtnPressed)
            return;

        this.areYouSureBtnPressed = true;
        this.setState({stepName: RefurbishStep.REPAIR})
    }

    onRepairSubmit = () => {
        if (this.repairBtnPressed)
            return;

        const unitId = this.props.unit.unitId;
        const unitBasic = UnitsManagerModule.getUnitBasic(unitId);
        if (!unitBasic) {
            this.repairBtnPressed = false;
            return ToastModule.toastError("No unitBasic was set");
        }

        this.newUnitName = unitBasic.activation ? this.activationForm.unitId as string : this.newUnitName;
        if (!this.newUnitName)
            return ToastModule.toastError("Please fill a new unit name first");

        if (this.newUnitName === unitId || !!UnitsManagerModule.getUnitBasic(this.newUnitName))
            return ToastModule.toastError("Please change unit name to a new name (none exist user)");

        if (unitBasic.activation) {
            const unitPatient = CareCenterModule.getUnitPatient(unitId);
            ActivationsModule.unregister({
                    agent_id: unitBasic.unitId,
                    som_device_id: unitBasic.somSerial,
                    tablet_device_id: unitBasic.tabletSerial,
                    blame: ""
                },
                () => {
                    const authType = this.activationForm.authType
                    ActivationsModule.activate({
                        unitId: this.newUnitName,
                        somSerial: unitBasic.somSerial,
                        tabletSerial: unitBasic.tabletSerial,
                        firstName: this.activationForm.firstName as string,
                        lastName: this.activationForm.lastName,
                        phoneNumber: this.activationForm.phoneNumber as string,
                        country: this.activationForm.country as string,
                        city: this.activationForm.city as string,
                        state: this.activationForm.state as string,
                        region: this.activationForm.region as string,
                        authType,
                        userGroup: this.activationForm.userGroup as string,
                        subUserGroup: this.activationForm.subUserGroup,
                        alias: this.activationForm.alias as string,
                        areaCode: this.activationForm.state as string,
                        organizationId: unitPatient?.organizationId as string
                    })
                });
            return;
        }

        const unitIdentity = UnitsModule.getIdentity(unitId);

        if (!unitIdentity) {
            this.repairBtnPressed = false;
            return ToastModule.toastError("No unitIdentity was set");
        }

        const somBarcode = unitIdentity.identities.find(device => device.type === "som");
        if (!somBarcode) {
            this.repairBtnPressed = false;
            return ToastModule.toastError("The unit doesn't have som barcode, please check it!")
        }
        const tabletBarcode = unitIdentity.identities.find(device => device.type === "tablet");
        if (!tabletBarcode) {
            this.repairBtnPressed = false;
            return ToastModule.toastError("The unit doesn't have tablet barcode, please check it!")
        }

        this.repairBtnPressed = true;
        this.setState({stepName: RefurbishStep.REPAIRING});
        const requestObject: Request_Pair = {
            product: unitIdentity.product,
            unitId: this.newUnitName,
            somBarcode,
            tabletBarcode,
            version: unitIdentity.version,
            devUnit: !!unitIdentity.devUnit,
            softwareOnly: !!unitIdentity.softwareOnly,
            comment: unitIdentity.comment || UnitEnv.Refurbished
        };

        KasperModule.pairUnit(requestObject, true);
    }

    restartUnit = (isActivation = false) => {
        const newUnit = {unitId: this.newUnitName, product: this.props.unit.product};
        if (!newUnit.unitId)
            return;

        PushMessagesModule.pushMessageToUnits([newUnit], PCT_RestartTablet.key, PCT_RestartTablet.data, ["tablet"], PCT_RestartTablet.targetPackage);
        PushMessagesModule.pushMessageToUnits([newUnit], PCS_RestartSom.key, PCS_RestartSom.data, ["som"], PCS_RestartSom.targetPackage);

        if (isActivation)
            PushMessagesModule.pushMessageToUnits([newUnit], PC_RestartStrickland.key, PC_RestartStrickland.data, ["som", "tablet"], PC_RestartStrickland.targetPackage);
    }

    private refurbishUnit = (unitId: string, deviceType: DeviceType) => {
        XhrHttpModule
            .createRequest<ApiRefurbishUnit>(HttpMethod.POST, "refurbish-unit")
            .setRelativeUrl(Url_SupportRefurbishUnit)
            .setJsonBody({unit: {unitId, product: this.props.unit.product}, deviceType})
            .setOnSuccessMessage("Remote-wipe unit request sent successfully")
            .setOnError("Remote-wipe unit request failed")
            .execute((response) => {
                this.setState({mid: response.mid});
                this.interval = _setInterval(() => {
                    PushMessagesModule.fetchPushList({unitId: this.newUnitName, product: this.props.unit.product});
                }, 2000);
            });
    };
}


