import * as React from "react";
import {css} from "emotion";
import {BaseComponent, ToastModule, TS_Input} from "@intuitionrobotics/thunderstorm/frontend";
import {Product} from "@consts/common";
import {RenderSelectUnit} from "../../renderers/Renderer_renderSelectUnit";
import {RequestKey_FetchUnitsFullList} from "@modules/UnitsModule";
import {OnRequestListener} from "@intuitionrobotics/thunderstorm";
import Loader from "@components/Loader";
import {KasperModule, OnUnitIdentityFetched, RequestKey_GetUnitIdentity, RequestKey_PairUnit} from "@modules/KasperModule";
import {UnitIdentity} from "@app/ir-q-app-common/types/units";
import {inputStyle} from "@components/AutoComplete";
import {elliqIdRe} from "@app-sp/app-shared/rejexes";
import {Page_UnitToAlias_iframe} from "./Page_UnitToAlias_iframe";
import {Page_NewCareCenterPatient_iframe} from "./Page_NewCareCenterPatient_iframe";
import {RequestKey_CreateNewZendeskUser, ZendeskModule} from "@modules/ZendeskModule";
import {Request_Pair} from "@app-sp/app-shared/units";
import {Iframe_EditContect} from "./Iframe_EditContect";
import {DB_Contact} from "@app/ir-q-app-common/types/db-contact";
import {ContactsModule} from "@modules/ContactsModule";
import {SetUserGroup} from "../units/components/SetUserGroup";

export const fieldSetStyle = css({
    borderRadius: "0.5em",
    border: "0.1em solid #ccc",
    boxShadow: "0.08em 0.05em 0.08em #ccc"
})

type Props = {}

type State = {
    product: Product,
    unitId?: string,
    newUnitId?: string,
    comment?: string,
    contact?: Partial<DB_Contact<any>>,
    prodUnit: boolean,
    loading: boolean,
    renderPairingFields: boolean,
    renderIFrames: boolean,
    renderContacts: boolean,
    renderCareCenterAndZendesk: boolean
    zendeskId?: string
}

export class Page_Preinstalls
    extends BaseComponent<Props, State>
    implements OnRequestListener, OnUnitIdentityFetched {

    private unit?: UnitIdentity

    constructor(props: Props) {
        super(props);

        this.state = {
            product: "elliq",
            loading: true,
            renderPairingFields: false,
            renderIFrames: false,
            renderContacts: false,
            renderCareCenterAndZendesk: false,
            prodUnit: false
        }
    }

    __onRequestCompleted = (requestKey: string, success: boolean) => {
        switch (requestKey) {
            case RequestKey_FetchUnitsFullList:
                return this.setState({loading: false});

            case RequestKey_GetUnitIdentity:
                return this.setState({loading: false, renderPairingFields: true});

            case RequestKey_PairUnit:
                if (!success)
                    return this.setState({loading: false});

                const unitId = this.state.newUnitId ? this.state.newUnitId : this.state.unitId;
                return this.setState({renderIFrames: true, unitId, newUnitId: undefined});

            case RequestKey_CreateNewZendeskUser:
                return this.setState({loading: false})
        }
    }

    __onZendeskFinished(id: string) {
        this.setState({zendeskId: id})
    }

    onUnitIdentityFetched(unit: UnitIdentity) {
        this.unit = unit
        this.setState({comment: unit.comment})
    }

    componentDidMount() {
        window.addEventListener("message", this.handleSaveContact)
    }


    componentWillUnmount() {
        window.removeEventListener("message", this.handleSaveContact)
    }

    private handleSaveContact = (e: any) => {
        if (e.origin.startsWith(ContactsModule.getMessagingUrl()) && e.data.contact){
            this.setState({contact: e.data.contact, renderCareCenterAndZendesk: true})
        }
    }


    private onUnitSelected = async (unitId: string) => {
        KasperModule.fetchUnitIdentity(this.state.product.toLowerCase(), unitId.toLowerCase())
        this.setState({unitId: unitId, newUnitId: undefined, contact: undefined, renderIFrames: false, loading: true, zendeskId: undefined});
    };

    private pair = () => {
        if (!this.unit)
            return;

        if (this.state.newUnitId) {
            if (!elliqIdRe.test(this.state.newUnitId))
                return ToastModule.toastError("Elliq ID must follow convention! For details, please open tooltip");
            this.unit.unitId = this.state.newUnitId;
        }

        const somBarcode = this.unit.devices.find(device => device.type === "som");
        if (!somBarcode) {
            return ToastModule.toastError("The unit doesn't have som barcode, please check it!")
        }
        const tabletBarcode = this.unit.devices.find(device => device.type === "tablet");
        if (!tabletBarcode) {
            return ToastModule.toastError("The unit doesn't have tablet barcode, please check it!")
        }

        const requestObject: Request_Pair = {
            product: this.unit.product,
            unitId: this.unit.unitId,
            somBarcode,
            tabletBarcode,
            version: this.unit.version,
            devUnit: !!this.unit.devUnit,
            comment: this.state.comment
        };
        KasperModule.pairUnit(requestObject, true);
        this.setState({renderIFrames: true})
    }

    private renderPairingFields = () => {
        return <div className={'ll_h_c'} style={{justifyContent: "space-between", margin: 20, width: "90%"}}>
            <div className={'ll_h_c'} style={{justifyContent: "space-between", width: "48em"}}>
                <div className={'ll_h_c'} style={{justifyContent: "space-between"}}>
                    <RenderSelectUnit product={this.state.product} unitId={this.state.unitId} onUnitSelected={this.onUnitSelected} style={{marginRight: "7px", width: 288}} placeholder={"Select unit to pre-install"}/>
                    {this.state.renderPairingFields && <>
                        <form autoComplete={"off"}><TS_Input
                            style={{width: 222}}
                            value={this.state.newUnitId}
                            onChange={value => this.setState({newUnitId: value})}
                            type={"text"}
                            id={"newUnitId"}
                            placeholder={"New unit id?"}
                            className={inputStyle("94%")}
                        /></form>
                    </>}
                </div>
                {this.state.renderPairingFields && <SetUserGroup
                  userGroup={this.state.comment}
                  onUserGroupChange={env => this.setState({comment: env})}
                />}

            </div>
            {this.state.renderPairingFields && <button className={"clickable"} onClick={this.pair}>Repair</button>}
        </div>
    }

    private contactsPart = () =>
        <Iframe_EditContect unit={{unitId: this.state.unitId as string, product: this.state.product}}/>

    private configPart = () => {
        return <fieldset className={fieldSetStyle} style={{marginBottom: 16}}>
            <legend>Config stuff</legend>
            <Page_UnitToAlias_iframe unitId={this.state.unitId as string} product={this.state.product}/>
        </fieldset>
    }

    private resolveName = (contact: any) => `${contact.firstName} ${contact.middleName ? `${contact.middleName} ` : ""} ${contact.lastName}`;


    private careCenterPart = () => {
        const {contact} = this.state;
        if(!contact)
            return null;
        // For the possible request to inject hte name from the contacts
        // const contact = ContactsModule.getContact(`${this.state.contactId}`);
        const gender = (contact.gender?.toLowerCase()[0]);
        if(!gender)
            return "Fill in patient gender before adding patient to care center"
        return <Page_NewCareCenterPatient_iframe unitId={this.state.unitId as string} gender={gender}/* name={this.resolveName(contact)}*//>
    }

    private zendeskPart = () => {
        const {contact} = this.state;
        if(!contact)
            return null;

        const name = this.resolveName(contact);
        const body = {"user": {
                "identities": [
                    {
                        "type": "phone",
                        "value": ""
                    },
                    {
                        "type": "email",
                        "value": ""
                    }
                ],
                "name": name,
                "phone": "",
                "email": "",
                "user_fields": {
                    "elliq_id": this.state.unitId
                },
                "role": "end-user",
                "verified": true
            }
        };

        return <fieldset className={fieldSetStyle} style={{marginTop: 13}}>
            <legend>Create Zendesk account:</legend>
            {this.state.zendeskId ? <div style={{color: 'green'}}>Account created with id: {this.state.zendeskId}</div> : <div style={{height: 18.5}}/>}
            <div style={{width: "90%"}}>
                <div className={"ll_h_c"}>
                    Name: <TS_Input className={inputStyle("94%")} onChange={value => body.user.name = value} type={"text"} id={"name"} value={body.user.name} style={{width: 296, marginRight: 10}}/>
                    Phone: <TS_Input className={inputStyle("94%")} onChange={value => body.user.identities[0].value = value} type={"text"} id={"phone"} value={body.user.identities[0].value} style={{width: 190}}/>
                </div>
                <div className={"ll_h_c"} style={{justifyContent: "space-between"}}>
                    <div className={'ll_h_c'}>Email: <TS_Input className={inputStyle("94%")} onChange={value => body.user.identities[1].value = value} type={"text"} id={"email"} value={body.user.identities[1].value}/></div>
                    <button className={"clickable"} onClick={() => {
                        ZendeskModule.createNewUser(body);
                        this.setState({loading: true});
                    }}>Create</button>
                </div>
            </div>
        </fieldset>
    }

    private patientPart = () => {
        return <fieldset className={fieldSetStyle}>
            <legend>Patient stuff</legend>
            <div className={"ll_h_t"}>
                {this.contactsPart()}
                {this.state.renderCareCenterAndZendesk && <div style={{marginLeft: 30, width: "100%"}}>
                    {this.careCenterPart()}
                    {this.zendeskPart()}
                </div>}
            </div>
        </fieldset>
    }

    private renderIFrames = () => {
        return <div style={{height:"calc(100vh - 110px)", overflow: "auto", position: "relative"}}>
            {this.configPart()}
            {this.patientPart()}
        </div>
    }


    render() {
        if (this.state.loading)
            return <Loader/>

        return <>
            {this.renderPairingFields()}
            {(this.state.renderPairingFields || this.state.renderIFrames) && this.renderIFrames()}
        </>
    }
}
