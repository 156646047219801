import {
	Module
} from "@intuitionrobotics/ts-common";
import {HttpMethod} from "@intuitionrobotics/thunderstorm";


import {
	XhrHttpModule,
	ToastModule
} from "@intuitionrobotics/thunderstorm/frontend";
import {
	Client_PermissionsGroup,
	Request_PermissionsGroup,
	UserToGroup
} from "@app/ir-q-app-common/types/permissions";
import {
	ApiDeletePermissionsGroup,
	ApiListPermissions,
	ApiUpdateGroupPermissions,
	ApiUpdateUserPermissions,
	ApiUserPermissions
} from "@app-sp/app-shared/api";

export type AccessLevel = {
	readonly key: string;
	readonly levels: string[];
}

type Config = {
	remoteUrl: string
}

export const RequestKey_ChangedPermissions = "ChangedPermissions";

export class PermissionsModule_Class
	extends Module<Config> {

	private featuresAccessLevels!: AccessLevel[];
	private permissionsGroup: { [groupId: string]: Client_PermissionsGroup } = {};
	private usersToGroups: { [email: string]: UserToGroup } = {};
	private myPermissions!: Client_PermissionsGroup;

	getPermissionGroup = (groupId: string) => this.permissionsGroup[groupId];
	getUserGroups = () => this.usersToGroups;
	getPermissionGroups = () => this.permissionsGroup;
	getMyPermissions = () => this.myPermissions;
	getFeatureAccessLevels = () => this.featuresAccessLevels;

	/*
	 * APIs
	 */
	fetchPermissions = () => {
		XhrHttpModule
			.createRequest<ApiListPermissions>(HttpMethod.GET, RequestKey_ChangedPermissions)
			.setRelativeUrl("/v1/user/permissions/list")
			.setOnError((request) => ToastModule.toastError(
				request.getStatus() === 403 ? "You are not allowed to perform this action. Please check your permissions." : "Failed to get users. Please try again later."))
			.execute(async response => {
				for (const group of response.groups) {
					this.permissionsGroup[group.id] = group;
				}

				for (const user of response.users) {
					this.usersToGroups[user.email] = user;
				}

				this.featuresAccessLevels = response.features;
			});
	};

	fetchMyPermissions = () => {
		XhrHttpModule
			.createRequest<ApiUserPermissions>(HttpMethod.GET, RequestKey_ChangedPermissions)
			.setRelativeUrl("/v1/user/permissions/user/get")
			.setOnError("Error listing permissions")
			.execute(async response => {
				this.myPermissions = response
			});
	};

	updatePermissionsGroup(permissionGroup: Request_PermissionsGroup) {
		XhrHttpModule
			.createRequest<ApiUpdateGroupPermissions>(HttpMethod.POST, RequestKey_ChangedPermissions)
			.setRelativeUrl("/v1/user/permissions/group/update")
			.setJsonBody(permissionGroup)
			.setLabel(`update permissions group: ${permissionGroup.name}`)
			.setOnError("Error updating user permissions")
			.execute(async response => {
				this.permissionsGroup[response.id] = response
			});
	}

	updateUserGroup(user: UserToGroup) {
		XhrHttpModule
			.createRequest<ApiUpdateUserPermissions>(HttpMethod.POST, RequestKey_ChangedPermissions)
			.setRelativeUrl("/v1/user/permissions/user/update")
			.setJsonBody(user)
			.setLabel(`update permissions for user: ${user.email}`)
			.setOnError("Error updating user permissions")
			.execute(async response => {
				this.usersToGroups[response.email] = response
			});
	}

	deletePermissionGroup(id: string) {
		XhrHttpModule
			.createRequest<ApiDeletePermissionsGroup>(HttpMethod.POST, RequestKey_ChangedPermissions)
			.setRelativeUrl("/v1/user/permissions/group/delete")
			.setJsonBody({id})
			.setLabel(`Delete permission id ${id}`)
			.setOnError("Error deleting permission group")
			.execute(async () => {
				delete this.permissionsGroup[id]
			});
	}

}

export const PermissionsModule = new PermissionsModule_Class("PermissionsModule");
