import * as React from "react";
import {Hello} from "../Hello";
import {Dropdown_SelectUnit} from "@components/Dropdown_SelectUnit";
import {RenderRSDownloadLink} from "./units/Component_Btns_Home";

export class Page_Home
    extends React.Component {

    render() {
        return <div className="ll_v_c">
            <Hello/>
            <Dropdown_SelectUnit/>
            <RenderRSDownloadLink/>
        </div>;
    }
}
