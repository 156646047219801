import * as React from "react";
import {CSSProperties} from "react";
import * as emotion from "emotion";
import {RoutingModule} from "@intuitionrobotics/thunderstorm/frontend";
import {RenderSelectUnit} from "../../app/renderers/Renderer_renderSelectUnit";
import {Route_UnitMonitoring} from "../../app/routes";

type Props = {
    className?: string
    style?: CSSProperties
    placeHolder?: string
}


const basicCss = emotion.css({width: 200, zIndex: 9999})

export function Dropdown_SelectUnit(props: Props) {
    return <div
        className={`${basicCss} ${props.className}`}
        style={props.style}
    >
        <RenderSelectUnit
            product={"elliq"}
            onUnitSelected={(value: string) => {
                RoutingModule.goToRoute(Route_UnitMonitoring, {
                    product: "elliq",
                    unitId: value
                });
            }}
            placeholder={"Jump to unit"}/>
    </div>;
}
