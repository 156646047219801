import * as React from "react";
import {BaseComponent, Dialog_Builder, DialogModule, ToastModule, TS_CheckboxField} from "@intuitionrobotics/thunderstorm/frontend";
import {_keys, toggleElementInArray} from "@intuitionrobotics/ts-common";
import {confirmationButtons} from "@components/AppDialog";
import {css} from "emotion";
import {SetUserGroup} from "./SetUserGroup";
import {marginBottom, paddingBottom, paddingTop} from "@styles/dynamic-styles";
import {Component_SendPushMessage} from "./Component_SendPushMessage";
import {DeviceType, Unit} from "@app/ir-q-app-common/types/units";
import {Component_InstallNowConfirm} from "./Component_InstallNowConfirm";
import {BatchActionsType, PushMessagesModule} from "@modules/PushMessagesModule";
import {Component_SetTTL} from "../../push-message/Component_SetTTL";
import {OnRequestListener} from "@intuitionrobotics/thunderstorm/shared/request-types";
import {RequestKey_FetchDeviceConfigFe} from "@modules/package-manager/PackageManagerModule";
import {UnitsManagerModule} from "@modules/UnitsManagerModule";
import {PmInstallNow} from "@app/ir-q-app-common/types/push-messages";
import {Dialog_InstallConditions} from "./Dialog_InstallConditions";
import { ID_PUSH_DIALOG } from "../Page_Unit_Monitoring";

type Props = {
    action: BatchActionsType,
    units: Unit[],
}

type State = {
    finalUnits: Unit[],
    commandKey: string,
    data: string,
    type: string,
    packageName?: string,
    env?: string,
    timestamp?: string,
    ttl?: number,
    trigger__qPulseData: boolean,
    trigger__deployed_unit: boolean;
    failedToRetrieveConfig?: boolean
}

const contentStyle = css({
    padding: "10px 40px",
});

const buttonsStyle = css({
    marginTop: "auto",
    justifyContent: "flex-end",
    padding: "12px 0"
})


export class Component_PushMessagesDialog
    extends BaseComponent<Props, State>
    implements OnRequestListener {

    constructor(props: Props) {
        super(props);

        this.state = {
            finalUnits: [...this.props.units],
            commandKey: "",
            data: "",
            type: "",
            packageName: "",
            env: undefined,
            timestamp: "",
            trigger__qPulseData: false,
            trigger__deployed_unit: false,
            ttl: undefined,
        };
    }

    __onRequestCompleted(key: string, success: boolean) {
        if (RequestKey_FetchDeviceConfigFe !== key || success)
            return;

        return this.setState({failedToRetrieveConfig: true});
    }

    public static show = (units: Unit[], action: BatchActionsType) => new Dialog_Builder(<Component_PushMessagesDialog
        action={action}
        units={units}/>, ID_PUSH_DIALOG)
        .setTitle(<div style={{background: "rgb(217, 217, 217)", fontWeight: 600, padding: 15}}>{action.label}</div>)
        .setAllowIndirectClosing(action.allowIndirectClosing !== undefined ? action.allowIndirectClosing : true)
        // .setZIndex(9999)
        .show();

    componentDidMount() {
        if (this.props.action.params?.env)
            this.setState({env: this.props.action.params.env})
    }

    private renderParams = () => {
        const {data, type, packageName, env, timestamp, ttl} = this.state;

        switch (true) {
            case this.props.action.key === "SetEnv":
                return <SetUserGroup
                    userGroup={env}
                    onUserGroupChange={_env => {
                        this.props.action.params = {env: _env, timestamp};
                        this.setState({env: _env});
                    }}
                />

            case this.props.action.key.includes("GeneralPush"):
                return <Component_SendPushMessage
                    devices={this.props.action.devices || []}
                    type={type}
                    data={data}
                    packageName={packageName}
                    onTypeChange={(_type: string) => {
                        this.props.action.params = {type: _type, data, packageName, ttl};
                        this.setState({type: _type});
                    }}
                    onPackageNameChange={(_packageName?: string) => {
                        this.props.action.params = {type, data, packageName: _packageName, ttl};
                        this.setState({packageName: _packageName});
                    }}
                    onDataChange={(_data: string) => {
                        this.props.action.params = {type, data: _data, packageName, ttl};
                        this.setState({data: _data});
                    }}
                />;

            case this.props.action.key.includes("Install"):
                let unit = this.state.finalUnits.find(item => !UnitsManagerModule.is3Point0Unit(item.unitId));
                if (!unit)
                    unit = this.state.finalUnits[0];

                // TODO when we have a UI to show multiple configs we put it here
                return <div>
                    {this.props.units.length > 1 && <span>This is the config for the first unit: {unit.unitId}</span>}
                    <Component_InstallNowConfirm
                        unit={unit}
                        onDevicesSelected={(_devices: DeviceType[]) => {
                            this.props.action.params = {devices: _devices, ttl};
                        }}
                    />
                </div>;

            default:
                return null;
        }
    };

    renderUnitsCheckboxs = () => {
        const amount = this.state.finalUnits.length;
        return <>
            {amount ? <div className={paddingBottom(4)}>on {amount} unit{amount > 1 ? "s" : ""}:</div> :
                <div>All units unchecked</div>
            }
            <TS_CheckboxField
                options={this.props.units.map(value => ({value}))}
                value={this.state.finalUnits}
                label={unit => unit.value.unitId}
                gridColumns={2}
                // checkboxWidth={"10px"}
                fieldContainerClass={css({maxHeight: 150, overflowY: "auto"})}
                onCheck={unit => {
                    this.setState(prev => {
                        toggleElementInArray(prev.finalUnits, unit);
                        return prev;
                    });
                }}
            />
        </>;
    }

    render() {
        const buttons = confirmationButtons(this.onSubmit, "Go!", undefined, ID_PUSH_DIALOG).map(
            (button, idx) => <div key={idx}>
                <button
                    className={button.className}
                    style={button.style}
                    onClick={button.action}>{button.content}</button>
            </div>);

        if (this.state.failedToRetrieveConfig)
            return <>
                <h3 style={{padding: 15}}>Failed to retrieve config, please check unit's environment</h3>
                <div className={`ll_h_c ${buttonsStyle}`}>{buttons[0]}</div>
            </>

        return <div className={contentStyle}>
            {this.props.units.length > 1 ? this.renderUnitsCheckboxs() : `Push to ${this.props.units[0].unitId}`}
            <div className={`${paddingTop(10)} ${marginBottom(15)}`}>{this.renderParams()}</div>
            {this.props.action.key !== "SetEnv" && this.props.action.key !== "CancelQueuedMessages" && <Component_SetTTL
                ttl={this.state.ttl}
                onTTLChange={(_ttl) => {
                    this.props.action.params = {...this.props.action.params, ttl: _ttl};
                    this.setState({ttl: _ttl})
                }}
            />}
            <div className={`ll_h_c ${buttonsStyle}`}>{buttons}</div>
        </div>;
    };

    private onSubmit = () => {
        const _units = this.state.finalUnits;
        if (_units.length === 0)
            return ToastModule.toastError('Please select some units first');
        if (this.props.action.key === "GeneralPush") {
            if (!this.state.type)
                return ToastModule.toastError('Please set the push type');
            if (!this.state.packageName)
                return ToastModule.toastError('Please set target package');
            const push = PushMessagesModule.getPushMessages(this.props.action.devices || []).find(p => p.key === this.state.type);
            if (!this.state.data && push?.mustHaveData)
                return ToastModule.toastError('This push message MUST have data!');
        }
        DialogModule.close();

        if (this.props.action.key.includes("Install") || (this.props.action.key.includes("GeneralPush") && this.state.type === PmInstallNow)) {
            const blockInstallation = Dialog_InstallConditions.show(_units, this.props.action.params.devices || this.props.action.devices || []);
            if (blockInstallation)
                return;
        }

        const objectA = {...this.props.action.params};
        const objectB: State = {...this.state};

        // delete all empty strings before combine the objects - otherwise it overrides values by empty string in '...' operator
        _keys(objectA).forEach(key => {
            if (!objectA[key])
                delete objectA[key];
        });

        _keys(objectB).forEach((key: keyof State) => {
            if (!objectB[key])
                delete objectB[key];
        });

        this.props.action.action(_units, {...objectA, ...objectB});
    };
}


