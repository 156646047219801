import {
	css,
	keyframes
} from "emotion";
import {_marginLeft} from "@styles/styles";
import * as React from "react";

const blink = keyframes`50% {
                          opacity: 0;
                        }`;

export const blinking = css(`
			animation: ${blink} 2s linear infinite;
		`);
const refreshClass = css(`
			${_marginLeft(10)}
			border: 2px solid red;
			font-size: 23px;
			background: red;
			color: white;
			border-radius: 10px;
		`);

export type BlinkingProps = {
	text: string
	onClick?: () => void
	className?: string
}

export const BlinkingButton = ({text, onClick, className}: BlinkingProps) => {
	return <button className={`${refreshClass} ${onClick ? "clickable" : ""} ${className}`} onClick={onClick}>
		<span className={blinking}>{text}</span>
	</button>;
};
