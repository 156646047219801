import {DeviceType, Unit} from "@app/ir-q-app-common/types/units";
import {PackageManagerModule, RequestKey_FetchDeviceConfigFe} from "@modules/package-manager/PackageManagerModule";
import {BaseComponent, CheckboxOption, TS_CheckboxField} from "@intuitionrobotics/thunderstorm/frontend";
import * as React from "react";
import {SimpleLoader} from "@components/SimpleLoader";
import {OnRequestListener} from "@intuitionrobotics/thunderstorm";
import {_keys, _values, toggleElementInArray} from "@intuitionrobotics/ts-common";
import {devices} from "../Page_Unit_Monitoring";
import {PackageConfigFe} from "@app-sp/app-shared/package-manager";
import {css} from "emotion";
import {UnitsManagerModule} from "@modules/UnitsManagerModule";

const icon__warning = require('@res/images/icon__warning.svg');
type Props = {
    unit: Unit
    onDevicesSelected: (devices: DeviceType[]) => void
};

type State = {
    failed: boolean
    devices: DeviceType[]
};

function splitPackageName(packageName: string) {
    const appName = PackageManagerModule.getApp(packageName)?.name
    if (appName)
        return appName;

    const name = packageName.split(".");
    return name[name.length - 1];
}

export class Component_InstallNowConfirm
    extends BaseComponent<Props, State>
    implements OnRequestListener {
    private devicesOptions: CheckboxOption<DeviceType>[] = _values(devices).map((value: DeviceType) => ({value}));

    constructor(props: Props) {
        super(props);
        this.state = {
            failed: false,
            devices: _keys(devices).filter(item => item === devices.tablet)
        };
        this.props.onDevicesSelected(this.state.devices);
    }

    __onRequestCompleted(key: string, success: boolean) {
        if (RequestKey_FetchDeviceConfigFe !== key)
            return;

        if (!success)
            return this.setState({failed: true});
        this.forceUpdate();
    }

    render() {
        if (this.state.failed)
            return <div>Failed to retrieve config, please check unit's environment</div>;

        const configToInstall = PackageManagerModule.getConfigFe(this.props.unit.product, this.props.unit.unitId);
        if (!configToInstall)
            return <SimpleLoader/>;

        return <div>
            {this.renderText(configToInstall)}
        </div>;
    }

    private renderText = (configToInstall: PackageConfigFe) => {
        if (!configToInstall || configToInstall.deviceConfig.length < 2) {
            return <div className={`ll_v_c match_width`} style={{justifyContent: "space-evenly", paddingRight: 10}}>
                <img src={icon__warning} alt="warning" style={{padding: 10}}/>
                <div>No config assigned</div>
            </div>;
        }

        return <div className={`ll_v_c match_width`} style={{justifyContent: "space-evenly", paddingRight: 10}}>
            <img src={icon__warning} alt="warning" style={{padding: 10}}/>
            {!UnitsManagerModule.is3Point0Unit(this.props.unit.unitId) && <TS_CheckboxField
                options={this.devicesOptions}
                value={this.state.devices}
                label={option => option.value}
                gridColumns={2}
                // checkboxWidth={"10px"}
                fieldContainerClass={css({maxHeight: 150, overflowY: "auto"})}
                onCheck={(unit: DeviceType) => {
                    this.setState(state => {
                        const stateDevices = this.state.devices;
                        toggleElementInArray(stateDevices, unit);

                        this.props.onDevicesSelected(stateDevices);
                        return {...state, devices: stateDevices};
                    });
                }}
            />}
            {UnitsManagerModule.is3Point0Unit(this.props.unit.unitId) && <div><b>ElliQ 3.0</b></div>}
            <div><b>About to install Config:</b></div>
            <div>{configToInstall.configName}</div>
            <br/>
            {this.renderApksList(configToInstall)}
            <br/>
            <div><b>Install Now?</b></div>
        </div>;
    };

    renderApksList = (configToInstall: PackageConfigFe) => {
        if (UnitsManagerModule.is3Point0Unit(this.props.unit.unitId)) {
            const tabletConfig = configToInstall.deviceConfig.find(item => item.deviceType === "tablet");
            return <>
                <div><b>Apps</b></div>
                {tabletConfig?.artifactList?.map((artifact) =>
                    <div key={artifact.packageName}>
                        {splitPackageName(artifact.packageName)} {artifact.versionName}
                    </div>
                )}
            </>
        }
        ;

        return <>
            <div><b>{configToInstall.deviceConfig[0].deviceType}</b></div>
            {configToInstall.deviceConfig[0]?.artifactList?.map((artifact) =>
                <div key={artifact.packageName}>
                    {splitPackageName(artifact.packageName)} {artifact.versionName}
                </div>
            )}
            <br/>
            <div><b>{configToInstall.deviceConfig[1].deviceType}</b></div>
            {configToInstall.deviceConfig[1]?.artifactList?.map((artifact) =>
                <div key={artifact.packageName}>
                    {splitPackageName(artifact.packageName)} {artifact.versionName}
                </div>
            )}
        </>
    }
}

// export class Component_InstallNowConfirmWithButtons
// 	extends React.Component<{ unit: Unit }, { devices: DeviceType[], ttl?: number }> {
//
// 	constructor(props: { unit: Unit }) {
// 		super(props);
// 		this.state = {
// 			devices: _keys(devices)
// 		};
// 	}
//
// 	render() {
// 		return <div>
// 			<div><Component_InstallNowConfirm
// 				unit={this.props.unit}
// 				onDevicesSelected={_devices => this.setState({devices: _devices})}
// 			/></div>
// 			<Component_SetTTL
// 				ttl={this.state.ttl}
// 				onTTLChange={ttl => {
// 					console.log(ttl)
// 					this.setState({ttl})
// 				}}
// 			/>
// 			<div className={`ll_h_c`} style={{
// 				marginTop: "auto",
// 				justifyContent: "flex-end",
// 				padding: "12px 0"
// 			}}>{this.getDialogButtonBuilders().map(
// 				(button, idx) => <div key={idx}>
// 					<button
// 						className={button.className}
// 						style={button.style}
// 						onClick={button.action}>{button.content}</button>
// 				</div>)}</div>
// 		</div>;
// 	}
//
// 	private getDialogButtonBuilders = () => {
// 		const configToInstall = PackageManagerModule.getConfigFe(this.props.unit.product, this.props.unit.unitId);
// 		if (!configToInstall || configToInstall.deviceConfig.length < 2)
// 			return confirmationButtonsNoConfig();
//
// 		return confirmationButtons(() => {
// 			// this.setState({loading: true});
// 			PushMessagesModule.pushMessageToDevice([this.props.unit], PackageManager_Install.type, undefined, this.state.devices, undefined, this.state.ttl);
// 		}, "Install");
// 	};
// }
