import {BaseComponent, DialogModule, RoutingModule} from "@intuitionrobotics/thunderstorm/frontend";
import {Component_RefurbishUnitDialog} from "./Dialog_RefurbishUnit";
import {LoadableComponent} from "@components/LoadableComponent";
import {QueryParam_Product, QueryParam_UnitId} from "@consts/common";
import {Route_Home} from "../../../routes";
import * as React from "react";
import {Elliq_ProductKey} from "@app/ir-q-app-common/shared/consts";

export class Component_RefurbishBackground extends BaseComponent {
    private unitId: string | undefined;
    private product: string | undefined;

    constructor(props: {}) {
        super(props);
        // this.unitId = LoadableComponent.getQueryParameter(QueryParam_UnitId);
        // this.product = LoadableComponent.getQueryParameter(QueryParam_Product);
        // if (!this.product || !this.unitId)
        // 	RoutingModule.goToRoute(Route_Home);
    }

    componentDidMount() {
        this.unitId = LoadableComponent.getQueryParameter(QueryParam_UnitId);
        this.product = LoadableComponent.getQueryParameter(QueryParam_Product) || Elliq_ProductKey;
        if (!this.product || !this.unitId)
            RoutingModule.goToRoute(Route_Home);
        if (!this.product || !this.unitId)
            return;

        DialogModule.close();
        Component_RefurbishUnitDialog.show({unitId: this.unitId, product: this.product});
    }

    render() {
        return <div className={"full_screen"} style={{backgroundColor: "bisque"}}></div>
    }

}
