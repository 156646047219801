import * as React from "react";
import {BaseComponent, Dialog_Builder} from "@intuitionrobotics/thunderstorm/frontend";
import {css} from "emotion";
import {marginBottom, paddingTop} from "@styles/dynamic-styles";
import {DeviceType, Unit} from "@app/ir-q-app-common/types/units";
import {PackageManagerModule} from "@modules/package-manager/PackageManagerModule";
import {compareVersions} from "@intuitionrobotics/ts-common";
import {UnitsModule} from "@modules/UnitsModule";
import {AppPackage_Strickland} from "@app/ir-q-app-common/types/push-messages";
import {config} from "../../../../config";

type Props = {
    theBlockerRule: InstallBlockerCondition
    configVersion: string
    currentVersion: string
}

type State = {}

const contentStyle = css({
    padding: "10px 40px",
    minWidth: "550px"
});

type InstallBlockerCondition = {
    currentVersionLowerThan: string,
    packageVersionIsEqualOrBiggerThan: string,
    mustInstallFirst: string
}

export class Dialog_InstallConditions
    extends BaseComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    public static show = (units: Unit[], devices: DeviceType[]) => {
        const installBlockerConditions: InstallBlockerCondition[] = config.installBlockerConditions;
        let currentStricklandVersion: string | undefined = "";
        let theBlockerRule: InstallBlockerCondition = installBlockerConditions[0];
        let configVersion: string | undefined = "";
        const foundUnit = units.find(unit => {
            return devices.find(deviceType => {
                return installBlockerConditions.find(blockerCondition => {
                    const pmStatus = UnitsModule.getPmStatus(unit.unitId);
                    currentStricklandVersion = pmStatus.find(item => item.deviceType === deviceType)?.status.artifact_data?.find(app => app.packageName === AppPackage_Strickland)?.versionName;
                    if (!currentStricklandVersion)
                        return false;

                    const separator = currentStricklandVersion.indexOf("-") || currentStricklandVersion.length || 0;
                    currentStricklandVersion = currentStricklandVersion.substring(0, separator);
                    const config = PackageManagerModule.getConfigFe(unit.product, unit.unitId);
                    const deviceConfig = config?.deviceConfig.find(item => item.deviceType === deviceType);
                    return deviceConfig?.artifactList.find(artifact => {
                            const separator = artifact.versionName?.indexOf("-");
                            const artifactVersion = artifact.versionName?.substring(0, separator !== -1 ? separator : artifact.versionName.length);
                            const isBlock = compareVersions(currentStricklandVersion as string, blockerCondition.currentVersionLowerThan) === 1
                                && compareVersions(blockerCondition.packageVersionIsEqualOrBiggerThan, artifactVersion) > 0;
                            if (isBlock) {
                                theBlockerRule = blockerCondition;
                                configVersion = artifactVersion;
                            }

                            return isBlock;
                        }
                    )
                });
            });
        })

        if (!foundUnit)
            return false;

        new Dialog_Builder(<Dialog_InstallConditions
            theBlockerRule={theBlockerRule}
            configVersion={configVersion}
            currentVersion={currentStricklandVersion}
        />)
            .setTitle(<div style={{background: "red", fontWeight: 600, padding: 15}}>Install Alert - Unit: {foundUnit.unitId}</div>)
            .setAllowIndirectClosing(true)
            .show();

        return true;
    }

    render() {
        return <div className={contentStyle}>
            <div className={`${paddingTop(10)} ${marginBottom(15)}`}>
                Installing version {this.props.configVersion} over version {this.props.currentVersion} is not supported, go via version {this.props.theBlockerRule.mustInstallFirst}
            </div>
        </div>;
    };
}


