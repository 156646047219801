import * as React from "react";
import {
    QueryParam_Product,
    QueryParam_UnitId
} from "@consts/common";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {AppModule} from "@modules/AppModule";
import {PackageManagerModule} from "@modules/package-manager/PackageManagerModule";

type Props = {
    unitId: string,
    product: string
}

export class Page_UnitToAlias_iframe
    extends BaseComponent<Props> {

    render() {
        if (!this.props.unitId || !this.props.product)
            return "You should not arrive here with no unitId or product. Please report tech team";

        const websiteUrl = PackageManagerModule.getPMWebsite();
        const unitToAliasUrl = PackageManagerModule.getUnitToAliasUrl();

        if (!websiteUrl || !unitToAliasUrl) {
            this.logError("Missing config for contacts page");
            return "Missing config for contacts page";
        }

        const _src = `${websiteUrl}/${unitToAliasUrl}?${QueryParam_UnitId}=${this.props.unitId}&${QueryParam_Product}=${this.props.product}`;
        const src = AppModule.composeUrlWithAuth(_src);
        return <iframe
                width={"100%"}
                height={300}
                src={src}
                style={{borderRadius: "0.5em"}}/>;
    }
}

