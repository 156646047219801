import {TS_Table} from "@intuitionrobotics/thunderstorm/frontend";
import {icn_v_purple, MyTableRow, StatusMap} from "./utils";
import * as React from "react";
import {UnitsModule} from "@modules/UnitsModule";
import {handleUnitsTypeFilter, prepareStatusValues} from "./Page_Units";
import {UnitContext} from "../../App";

type Props = {
    statusFilters: { [key: string]: boolean };
    userGroupFilters: { [key: string]: boolean };
    onStatusClick: (column: keyof MyTableRow) => void;
    onUserGroupClick: (env: string) => void;
    onCellClick: (env: string, monitoringKey: string) => void;
    consideredEnvs: string[];
}
type State = {
    showMore?: boolean;
}
export class Numbers_Table extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    private headerStyle = (title: string, column: keyof MyTableRow, clickable = true) => {
        return <div className={`top-table-header-class--${column}`} onClick={() => {
            if (!clickable)
                return;

            this.props.onStatusClick(column);
        }
        }>
            {this.props.statusFilters[column] &&
                <img alt={"clickable"} src={icn_v_purple} width={20} style={{marginRight: 8}}/>}
            {title}
        </div>
    };

    private prepareTableData = (allUnits: string[]) => {
        const consideredEnvs = this.props.consideredEnvs;

        const statusMap = {
            live: 0,
            error: 0,
            tabletOff: 0,
            somOff: 0,
            down: 0,
            manuallyOff: 0,
            preInstall: 0,
            total: 0
        }

        const totalMap: StatusMap = {
            ...statusMap,
            category: "Total"
        };

        let total = 0;
        if (!this.state.showMore) {
            allUnits.forEach(unitId => {
                if (!handleUnitsTypeFilter(unitId, consideredEnvs))
                    return;

                const unitView = UnitsModule.getUnitView(unitId);
                const status = prepareStatusValues(unitId, unitView);
                totalMap[status]++
                total++
            })

            totalMap.total = total;
            return [totalMap];
        }

        // cs team asked ny and support will be displayed last
        const devEnv = consideredEnvs.findIndex(env => env.toLowerCase() === "dev");
        if (devEnv !== -1)
            consideredEnvs.push(consideredEnvs.splice(devEnv, 1)[0]);

        const supportEnv = consideredEnvs.findIndex(env => env.toLowerCase() === "support");
        if (supportEnv !== -1)
            consideredEnvs.push(consideredEnvs.splice(supportEnv, 1)[0]);

        const categories: { [key: string]: any } = {}
        consideredEnvs.forEach(env => {
            categories[env] = {...statusMap, category: env};
        })

        allUnits.forEach(unitId => {
            const pairComment = UnitsModule.getUnitUserGroup(unitId);
            if (!pairComment || !handleUnitsTypeFilter(unitId, consideredEnvs))
                return;

            categories[pairComment].total += 1;
            const unitView = UnitsModule.getUnitView(unitId);
            const status = prepareStatusValues(unitId, unitView);

            totalMap[status]++
            total++

            categories[pairComment][status]++
        })

        categories.total = totalMap
        categories.total.total = total
        return Object.values(categories);
    };

    private renderFilters(allUnits: string[]) {
        const tableData: MyTableRow[] = this.prepareTableData(allUnits);
        return <TS_Table<MyTableRow>
            id={"monitoring units"}
            className={"top-table"}
            tr={() => ({
                style: {}
            })}
            td={{}}
            header={["category",
                "live",
                "error",
                "tabletOff",
                "somOff",
                "down",
                "manuallyOff",
                "preInstall",
                "total"]}
            headerRenderer={{
                category: () => <div className={"show-more"}
                                     onClick={() => this.setState({showMore: !this.state.showMore})}>
                    {this.state.showMore ? "Show less" : "Show more"}
                </div>,
                live: () => this.headerStyle("Live", "live"),
                error: () => this.headerStyle("Error", "error"),
                tabletOff: () => this.headerStyle("Tablet Off", "tabletOff"),
                somOff: () => this.headerStyle("SOM Off", "somOff"),
                down: () => this.headerStyle("Down", "down"),
                manuallyOff: () => this.headerStyle("Manual Off", "manuallyOff"),
                preInstall: () => this.headerStyle("Preinstall", "preInstall"),
                total: () => this.headerStyle("Total", "total", false)
            }}
            cellRenderer={(cellValue: number | string, rowIndex: number, columnKey: keyof MyTableRow, rowObject: MyTableRow) => {
                if (typeof cellValue === "string") {
                    return <div
                        className={"top-table-cell top-table-cell-text"}
                        onClick={() => {
                            if (columnKey.toLowerCase() === "total")
                                return;

                            this.props.onUserGroupClick(rowObject.category);
                        }}>
                        {this.props.userGroupFilters[rowObject.category] &&
                            <img alt={"clickable"} src={icn_v_purple} width={20} style={{marginRight: 8}}/>}
                        {cellValue}
                    </div>
                }

                return <div
                    className={"top-table-cell top-table-cell-number"}
                    onClick={() => {
                        this.props.onCellClick(rowObject.category, columnKey);
                    }}>
                    {this.props.statusFilters[columnKey] && this.props.userGroupFilters[rowObject.category] &&
                        <img alt={"clickable"} src={icn_v_purple} width={20} style={{marginRight: 8}}/>}
                    {cellValue}
                </div>

            }}
            rows={tableData}
        />
    }

    render() {
        return <UnitContext.Consumer>
            {units => {
                const {activatedUnits, pairedUnits} = units;
                const allUnits = [...Array.from(pairedUnits), ...Array.from(activatedUnits)]

                return this.renderFilters(allUnits);
            }}
        </UnitContext.Consumer>
    }
}