import * as React from 'react';
import * as emotion from 'emotion';
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";

const inputFieldContainer = emotion.css`
  align-items: center;
  label: input-field-container;
`;

const inputFieldLabel = emotion.css`
  margin: 5px;
  label: input-field-label;
  text-align: center;
`;

const input = emotion.css`
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 20px !important;
  box-shadow: 0 0 0 1px rgba(41, 41, 41, 0.2) inset;
  font-size: 20px;
  color: #242424;
  border: none;
  maxlength
`;

export interface InputTextFieldProps {
	id: string;
	onValueChange: (value: string, id: string) => void;
	maxLength?: number;
	value?: string;
	className?: string;
	label?: string;
	style?: string;
	type?: string;
	validateValue?: (value: string) => string | null;
	placeholder?: string;
	autoComplete?: string;
	onKeyPress?: (evt: React.KeyboardEvent) => void;
	onFocusLost?: (value: string, id: string) => void;
	readOnly?: boolean
}

interface InputTextState {
	error: any;
	value: string
}

export class InputTextField
	extends BaseComponent<InputTextFieldProps, InputTextState> {
	constructor(props: InputTextFieldProps) {
		super(props);

		this.onValueChanged = this.onValueChanged.bind(this);
		this.onFocusLost = this.onFocusLost.bind(this);

		this.state = {
			error: null,
			value: props.value || ""
		};
	}

	onValueChanged(evt: React.ChangeEvent<HTMLInputElement>): void {
		const value = evt.target.value;
		this.props.onValueChange && this.props.onValueChange(value, this.props.id);
		this.setState(() => {
			return {value: value}
		});
	}

	validateValue() {
		if (!this.props.validateValue)
			return true;
		const value = `${this.state.value}`;

		if (!this.props.validateValue)
			return true;

		const errorMessage = this.props.validateValue(value);

		this.setState(() => {
			return {error: errorMessage}
		});
		return !errorMessage;
	}

	onFocusLost() {
		if (!this.props.onFocusLost)
			return;

		this.props.onFocusLost(this.state.value, this.props.id);
	}

	render() {
		return (
			<div className={inputFieldContainer}>
				{this.props.label ? <div className={inputFieldLabel}>{this.props.label}</div> : ""}

				<input
					value={this.props.value}
					className={this.props.style || input}
					onChange={this.onValueChanged}
					id={this.props.id}
					type={this.props.type}
					placeholder={this.props.placeholder}
					onKeyPress={this.props.onKeyPress}
					autoComplete={this.props.autoComplete}
					onBlur={() => {
						if (this.validateValue())
							this.onFocusLost();
					}}
					readOnly={this.props.readOnly || false}
					maxLength={this.props.maxLength || 23}
				/>

				<div className={this.state.error ? "form-error-message-on" : "form-error-message-off"}>
					{this.state.error}</div>
			</div>
		);
	}
}
