import * as React from 'react';
import {CircularProgress} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

const Loader = ({text = 'Loading'}: { text?: string }) => {

	return (
		<div>
			<Dialog
				open={true}
				aria-labelledby="loading-dialog"
				aria-describedby="loading-dialog"
				disableRestoreFocus
				disableEscapeKeyDown
			>
				<DialogContent className="ll_v_c">
					<div style={{marginBottom: "20px"}}>{text}</div>
					<CircularProgress/>
				</DialogContent>
			</Dialog>
		</div>
	);
}

export default Loader;
