import * as React from "react";
import {CSSProperties, useContext} from "react";
import {Product} from "@consts/common";
import {UnitContext} from "../App";
import {sortArray} from "@intuitionrobotics/ts-common";
import Select from "react-select";

type Props = {
    product?: Product
    unitId?: string
    onUnitSelected: (value: string) => void,
    style?: CSSProperties,
    placeholder?: string
}
export const RenderSelectUnit = ({product, unitId, onUnitSelected, style, placeholder = "Select unit"}: Props) => {
    if (!product)
        return;

    const {activatedUnits, pairedUnits} = useContext(UnitContext);

    const units = [...Array.from(pairedUnits), ...Array.from(activatedUnits)];
    if (!units.length)
        return;

    const options = sortArray(units.map(unitId => ({value: unitId, label: unitId})), unit => unit.value, true)
    return <div style={{minWidth: 150, ...style}}><Select

        value={unitId ? {value: unitId, label: unitId} : undefined}
        options={options}
        placeholder={placeholder}
        onChange={(v) => v && onUnitSelected(v.value)}
    /></div>
};
