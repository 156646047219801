import * as React from 'react';
import CustomSnackBar, {CustomSnackBarProps} from "@components/CustomSnackBar";

const Renderer_Toaster = (node?: CustomSnackBarProps) => {
	if (node) {
		return <CustomSnackBar {...node}/>
	}
	return;
};

export default Renderer_Toaster;