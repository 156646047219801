import * as React from "react";
import {BaseComponent, Dialog_Builder, DialogModule} from "@intuitionrobotics/thunderstorm/frontend";
import {confirmationButtons} from "@components/AppDialog";
import {css} from "emotion";
import {SetUserGroup} from "./SetUserGroup";
import {marginBottom, paddingTop} from "@styles/dynamic-styles";
import {KasperModule} from "@modules/KasperModule";

type Props = {
    unitId: string,
    userGroup: string
    subGroup?: string
}

type State = {
    userGroup: string,
    subGroup?: string
}

const contentStyle = css({
    padding: "10px 40px"
});

const buttonsStyle = css({
    marginTop: "auto",
    justifyContent: "flex-end",
    padding: "12px 0"
})


export class Dialog_SetUserGroup
    extends BaseComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            userGroup: props.userGroup,
            subGroup: props.subGroup
        };
    }

    public static show = (
        unitId: string,
        userGroup: string
    ) => new Dialog_Builder(<Dialog_SetUserGroup
        unitId={unitId}
        userGroup={userGroup}
    />)
        .setTitle(<div style={{background: "rgb(217, 217, 217)", fontWeight: 600, padding: 15}}>Set User Group</div>)
        .setAllowIndirectClosing(true)
        .show();

    render() {
        const buttons = confirmationButtons(this.onSubmit, "Go!").map(
            (button, idx) => <div key={idx}>
                <button
                    className={button.className}
                    style={button.style}
                    onClick={button.action}>{button.content}</button>
            </div>);

        return <div className={contentStyle}>
            <div className={`${paddingTop(10)} ${marginBottom(15)}`}>
                <SetUserGroup
                    userGroup={this.state.userGroup}
                    onUserGroupChange={userGroup => {
                        this.setState({userGroup: userGroup});
                    }}
                    subGroup={this.state.subGroup}
                    onSubGroupChange={subGroup => {
                        this.setState({subGroup: subGroup});
                    }}
                />
            </div>
            <div className={`ll_h_c ${buttonsStyle}`}>{buttons}</div>
        </div>;
    };

    private onSubmit = () => {
        DialogModule.close();
        KasperModule.updateUserGroup(this.props.unitId, this.state.userGroup, this.state.subGroup);
    };
}


