import * as emotion from 'emotion';

export const margin = (px: number) => emotion.css`
margin: ${px.toString()}px;
`;

export const marginTop = (px: number) => emotion.css`
margin-top: ${px.toString()}px;
`;

export const marginBottom = (px: number) => emotion.css`
margin-bottom: ${px.toString()}px;
`;

export const marginLeft = (px: number) => emotion.css`
margin-left: ${px.toString()}px;
`;

export const marginRight = (px: number) => emotion.css`
margin-right: ${px.toString()}px;
`;

export const padding = (px: number) => emotion.css`
padding: ${px.toString()}px;
`;

export const paddingLeft = (px: number) => emotion.css`
padding-left: ${px.toString()}px;
`;

export const paddingRight = (px: number) => emotion.css`
padding-right: ${px.toString()}px;
`;

export const paddingTop = (px: number) => emotion.css`
padding-top: ${px.toString()}px;
`;

export const paddingBottom = (px: number) => emotion.css`
padding-bottom: ${px.toString()}px;
`;

export const width = (px: number) => emotion.css`
width: ${px.toString()}%;
`;

export const backgroundColor = (color: string) => emotion.css`
background-color: ${color};
`;
